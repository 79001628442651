<style scoped>
    .dialogueList{background-color: #F7F6F9}
    .dialogueNumber{width: 7.5rem;height: 1.12rem;background: #FEFEFE;line-height: 1.12rem}
    .newOrder{width: 1.68rem;height: 0.52rem;background-color: #0C83E4;border-radius: 0.26rem;text-align: left;font-size: 0.22rem;line-height: 0.52rem;color: #FFF;display: inline-block;float: right;vertical-align: top;margin: 0.3rem 0.3rem 0 0;}
    /*.addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;}*/
    .addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;font-size:0.3rem;}

    .headPortrait{position: relative;height:100%;display: inline-block;}
    .informationList{width: 6.9rem;height: 1.5rem;background-color: #FFF;margin-top: 0.3rem;display: inline-block;text-align: left}
    .tips{width: 0.30rem;height: 0.30rem;background: #EB1818;border-radius: 50%;position: absolute;font-size: 0.24rem;color: #FFF;text-align: center;top: 0.32rem;right: 0}
    .lawName{font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.32rem;vertical-align: top;margin: 0.37rem 0 0.2rem 0rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .content{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .time{font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;float: right;margin: 0.43rem 0.3rem 0 0}
</style>

<template>
    <div class="dialogueList">
        <div class="dialogueNumber">
            <div style="color: #666666;font-size: 0.24rem;margin-left: 0.3rem;display: inline-block;vertical-align: top;">
                您目前有<span style="color: #0C83E4;font-size: 0.24rem">{{Count}}</span>条咨询
            </div>
            <router-link :to="{name:'newOrder'}"><div class="newOrder">
                <!--<img src="/img/icon-arrow-right2.png" class="addImg">-->
                <van-icon name="add-o" class="addImg"/>
                <div class="inline-block">新建订单</div>
            </div></router-link>
        </div>
        <div class="center font-size">
            <div class="informationList" v-for="(value,index) in dialogueList" @click="onDialogue(value.ContactId)">
                <div class="headPortrait">
                    <img v-if="value.LawyerImg" :src="value.LawyerImg" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem">
                    <img v-else="" src="/img/enterprise.png" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem">
                    <!--<div class="tips">3</div>-->
                </div>
                <div class="inline-block" style="max-width: 3.88rem;margin-left: 0.3rem;vertical-align: top">
                    <div class="lawName">{{value.LawyerName}}</div>
                    <div class="content" v-if="value.Content">{{value.Content[value.Content.length-1].content}}</div>
                </div>
                <div class="inline-block time">{{value.OrderCreatedTime}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "dialogueList",
        data() {
            return {
                page : '',
                pageSize : '',
                Count : '',
                dialogueList : [],
            };
        },
        methods :{
            onDialogue : function(ContactId){
                var _this = this
                _this.$router.push({name: 'dialogue', query: {ContactId:ContactId}})
            },
            historyContactList : function () {
                var _this = this
                _this.send({
                    url: '/HistoryContactList',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        UserId : window.sessionStorage.UserId,
                        LawyerId : window.sessionStorage.User_LawyerId,
                        page : _this.page,
                        pageSize : _this.pageSize
                    },
                    success: function (res) {
                        _this.dialogueList = res.List
                        _this.Count = res.Count
                    }
                })
            }
        },
        mounted () {
            this.historyContactList()
        }
    }
</script>

