<style scoped>
    .caseDetails{background-color: #F7F6F9}
    .caseDetails >>>.van-field__control{text-align: right}
    .caseDetails >>>.van-cell{font-size: 0.26rem;}
    .list{width: 7.5rem;height: 2.04rem;background-color: #FFF;display: inline-block;text-align: left;margin-top: 0.3rem;border-radius: 0.04rem;}
    .listType{height: 1.08rem;border-bottom: 0.01rem solid #D6DDE2;line-height: 1.09rem;}
    .headPortrait{width: 0.54rem;height: 0.54rem;border-radius: 0.54rem;margin: 0.2rem 0.2rem 0 0.3rem}
    .lawName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #6B757D;line-height: 0.94rem;display: inline-block;vertical-align: top;}
    .state{font-size: 0.224rem;font-family: PingFang SC;font-weight: 400;line-height: 0.94rem;display: inline-block;float: right;margin-right: 0.3rem}
    .typeListTrue{width: 1.14rem;height: 0.6rem;background: #0C83E4;border-radius: 0.04rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.6rem;vertical-align: top;margin: 0.3rem 0 0 0.3rem;}
    .typeListFalse{width: 1.14rem;height: 0.6rem;background: #B6BCD1;border-radius: 0.04rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.6rem;vertical-align: top;margin: 0.3rem 0 0 0.3rem;}
    .RelatedFile{font-size: 0.26rem;font-family: PingFang SC;font-weight: bold;color: #333333;margin-left:0.3rem;line-height: 0.93rem;text-align: left}
    .content{font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #666666;line-height: 0.4rem;margin: 0 0.3rem 0.2rem 0.3rem;text-indent: 2em}
    .file{text-align: left;width: 6.9rem;height: 0.76rem;background: #F8FDFF;font-size: 0.26rem;font-family: PingFang SC;font-weight: 400;color: #6B757D;line-height: 0.76rem;display: inline-block;margin-bottom: 0.3rem;}
    .previewImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.23rem 0.21rem 0 0}
    .previewWord{font-size: 0.26rem;font-family: PingFang SC;font-weight: 400;color: #0C83E4;line-height: 0.76rem;display: inline-block;vertical-align: top;}
    .bid{width: 5rem;height: 1.08rem;
        color: rgb(231, 23, 23);
        font-size: 0.26rem;
        float: right;
        margin-right: 0.3rem;text-align: right}
    .payment{width: 7.5rem;text-align: center;height: 1rem;background: #0C83E4;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #F7F6F9;line-height: 1rem;position: fixed;bottom: 0;}

    .head-title{font-size: .36rem;color: #333;line-height: .88rem;display: inline-block;vertical-align: top;}
    .return{width: .16rem;height: .3rem;display: inline-block;vertical-align: top;float: left;margin-top: .29rem;margin-left: .3rem;}

    .upload{font-weight: bold;color: #666666;font-size: 0.26rem;text-align: left;background-color: #FFF;margin-top: 0.38rem}
    .fileList{width: 6.9rem;height: 0.86rem;border: 0.01rem solid #D6DDE2;display: inline-block;text-align: left;margin-bottom: 0.3rem;}
    .addfileName{font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;color: #BFC4D6;line-height: 0.85rem;display: inline-block;vertical-align: top;margin-right: 0.3rem;}
    .fileName{white-space: nowrap;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #0C83E4;line-height: 0.86rem;margin: 0 0.3rem 0 0.21rem;display: inline-block;max-width: 4.5rem;overflow: hidden;}
    .preview{width: 0.86rem;height: 0.46rem;background: #FFFFFF;border: 0.01rem solid #D6DDE2;border-radius: 0.23rem;font-size: 0.22rem;color: #BFC4D6;line-height: 0.46rem;text-align: center;display: inline-block;vertical-align: top;margin-top: 0.2rem}
    .close{width: 0.3rem;height: 0.3rem;float: right;margin: 0.28rem 0.31rem 0 0}
    .addfile{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.26rem 0.19rem 0 0;}

    .leavingaMessage{font-size: 0.26rem;border-bottom: 0.01rem solid #ccc;padding: 0 0.3rem;}
</style>

<template>
    <div class="caseDetails">
        <div style="background-color: rgb(255, 255, 255); height: 0.88rem; text-align: center;position: fixed;top: 0;width: 100%;right: 0;z-index: 9;" v-if="equipment == 1">
            <img src="/img/return.png" class="return" @click="$router.go(-1)">
            <p class="head-title">律蛙法管家</p>
        </div>
        <div style="padding: 0.44rem"></div>
        <div class="center font-size" >
            <div class="list">
                <div class="listType">
                    <div style="color:#6B757D;font-size: 0.30rem;vertical-align: top;margin-left: 0.3rem;font-weight: bold;" class="inline-block">{{orderDeailsData.OrderName}}</div>
                    <div style="color:#999;font-size: 0.21rem;float: right;margin-right: 0.3rem" class="inline-block">{{orderDeailsData.OrderCreatedTime}}</div>
                </div>
                <div style="height: 0.94rem;vertical-align: top;">
                    <img v-if="orderDeailsData.LawyerImg" :src="orderDeailsData.LawyerImg" class="headPortrait">
                    <img v-else src="/img/enterprise.png" class="headPortrait">
                    <div class="lawName">{{orderDeailsData.LawyerName}}<span v-if="orderDeailsData.UrgentFinishTime">({{orderDeailsData.UrgentFinishTime}})完成</span></div>
                    <div class="state" style="color: #019C93;" v-if="orderDeailsData.OrderEndTime">已完成</div>
                    <!--<div class="state" style="color: #D01616;">未支付</div>-->
                    <div class="state" style="color: #0C83E4;" v-else>处理中</div>
                </div>
            </div>
            <div class="list" style="height: 1rem;line-height: 1rem" v-if="orderDeailsData.OrderTypeId == 6">
                <div class="listType" style="border-bottom:0;font-weight: bold;">
                    <div style="color:#6B757D;font-size: 0.30rem;vertical-align: top;margin-left: 0.3rem;" class="inline-block">标的金额</div>
                    <div style="color:#E71717;font-size: 0.36rem;float: right;margin-right: 0.3rem" class="inline-block">{{orderDeailsData.OrderCost}}</div>
                </div>
            </div>
            <div class="list" style="height: 1rem;line-height: 1rem" v-if="orderDeailsData.OrderTypeId == 6 && IsUser == 2">
                <div class="listType" style="border-bottom:0;font-weight: bold;">
                    <div style="color:#6B757D;font-size: 0.26rem;vertical-align: top;margin-left: 0.3rem;" class="inline-block">收费金额</div>
                    <div style="color:#E71717;font-size: 0.36rem;float: right;margin-right: 0.3rem" class="inline-block">{{orderDeailsData.OrderMoney}}</div>
                </div>
            </div>
            <!--<div v-if="orderDeailsData.OrderTypeId == 6">-->
                <!--<div style="width: 7.5rem;background-color: #FFF;display: inline-block;text-align: left;margin-top: 0.3rem;border-radius: 0.04rem;text-align: center">-->
                    <!--<div class="RelatedFile">相关文件</div>-->
                    <!--<div class="file" v-for="(value,index) in OrderText">-->
                        <!--<div style="margin-left: 0.2rem;display: inline-block;overflow: hidden;max-width: 5.2rem;white-space:nowrap;">{{value.name}}</div>-->
                        <!--<div style="float: right;display: inline-block;margin-right: 0.2rem" @click="previewImg(value.img)">-->
                            <!--<img class="previewImg" src="/img/yulan.png">-->
                            <!--<div class="previewWord">预览</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div style="width: 7.5rem;background-color: #FFF;display: inline-block;text-align: left;margin-top: 0.3rem;border-radius: 0.04rem;">-->
                    <!--<div class="RelatedFile">详情描述</div>-->
                    <!--<div class="content">{{orderDeailsData.OrderDetails}}</div>-->
                <!--</div>-->
            <!--</div>-->
            <div class="center upload" >
                <div class="list" style="height: 1rem;line-height: 1rem;margin-top:0;" v-if="orderDeailsData.OrderTypeId == 6 && IsUser == 1">
                    <div class="listType" style="border-bottom:0;font-weight: bold;">
                        <div style="color:#6B757D;font-size: 0.30rem;vertical-align: top;margin-left: 0.3rem;" class="inline-block">收费金额</div>
                        <input class="bid" placeholder="请输入收费金额" v-model="orderDeailsData.OrderMoney" :readonly="orderDeailsData.PayStatus == 1"/>
                    </div>
                </div>
                <div  v-if="orderDeailsData.PayStatus == 1&&orderDeailsData.OrderStatus != 1">
                    <van-field
                            v-model="OrderMsg"
                            rows="1"
                            autosize=""
                            label="留言"
                            type="textarea"
                            placeholder="请输入留言"
                    />
                    <div class="inline-block" style="width: 50%"><div style="margin-left: 0.3rem;line-height: 0.85rem;">上传文件</div></div>
                    <van-uploader :after-read="afterRead" accept="" delete="deletefile" style="float: right;" class="inline-block">
                        <img src="/img/addfile.png" class="addfile">
                        <div class="addfileName">添加文件</div>
                    </van-uploader>
                    <div class="center">
                        <div class="fileList" v-for="(value,index) in orderData.fileData">
                            <div class="fileName">{{value.name}}</div>
                            <div class="preview" @click="previewImg(value.img)">预览</div>
                            <img src="/img/close.png" class="close" @click="deleteArray(index)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="center upload" v-if="remarkVoice">
                <!--<div class="category"><div style="margin-left: 0.3rem">语音描述</div></div>-->
                <div class="inline-block" style="width: 50%"><div style="margin-left: 0.3rem;line-height: 0.85rem;">语音描述</div></div>
                <div class="center">
                    <div class="fileList" v-for="(value,index) in remarkVoice">
                        <audio controls style="margin: 0.14rem 0 0 0.2rem;width: 4.5rem;height: 0.6rem" >
                            <source :src="value.mp3" type="audio/mpeg">
                            您的浏览器不支持 audio 元素。
                        </audio>
                    </div>
                </div>
            </div>
            <div style="width: 7.5rem;background-color: #FFF;display: inline-block;text-align: left;margin-top: 0.3rem;border-radius: 0.04rem;">
                <div class="RelatedFile">留言列表</div>
                <div class="leavingaMessage" v-for="(value,index) in orderdetail">
                    <div style="margin: 0.2rem 0">
                        <!--<div>名称：{{value.UserName||value.LawyerName}}</div>-->
                        <div>{{value.UserName||value.LawyerName}}<span v-if="value.OrderMsg">：{{value.OrderMsg}}</span></div>
                        <div>留言时间：{{value.OrederTime}}</div>
                    </div>
                    <div class="file" v-for="(value,index) in value.OrderFile">
                        <div style="margin-left: 0.2rem;display: inline-block;overflow: hidden;max-width: 5.2rem;white-space:nowrap;">{{value.name}}</div>
                        <div style="float: right;display: inline-block;margin-right: 0.2rem" @click="previewImg(value.img)">
                            <img class="previewImg" src="/img/yulan.png">
                            <div class="previewWord">预览</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 0.6rem"></div>
        <div  v-if="orderDeailsData.OrderStatus == 1">
            <div class="payment">订单已结束</div>
        </div>
        <div v-else="">
            <div class="payment" @click="stopOrder()" v-if="IsUser == 1&&orderDeailsData.PayStatus == 1" style="width: 50%;background-color: #E8A912;">结束订单</div>
            <div class="payment" @click="submit()" v-if="IsUser == 1" :style="{'width': (orderDeailsData.PayStatus==1 ? '50%':'100%')}" style="right: 0">
                <span v-if="orderDeailsData.PayStatus == 3">提交</span>
                <span v-if="orderDeailsData.PayStatus == 1">留言</span>
            </div>
            <div class="payment" @click="payment()" v-if="IsUser == 2&&orderDeailsData.PayStatus == 3 && orderDeailsData.OrderTypeId == 6&&orderDeailsData.OrderMoney">支付</div>
            <!--<div class="payment" @click="urgent()" v-if="IsUser == 2&&orderDeailsData.PayStatus == 3 && orderDeailsData.OrderTypeId == 6&&!orderDeailsData.OrderMoney" style="background-color: #E8A912">-->
                <!--<span v-if="orderDeailsData.IsUrgentName">{{orderDeailsData.IsUrgentName}}</span>-->
                <!--<span v-else>加急处理</span>-->
            <!--</div>-->
            <div class="payment" @click="urgent()" v-if="IsUser == 2&&orderDeailsData.PayStatus == 1" style="width: 50%;background-color: #E8A912">
                <span v-if="orderDeailsData.IsUrgentName">{{orderDeailsData.IsUrgentName}}</span>
                <span v-else>加急处理</span>
            </div>
            <div class="payment" @click="userOrderUpdate()" v-if="IsUser == 2&&orderDeailsData.PayStatus == 1" style="width: 50%;right: 0">留言</div>
        </div>
        <div style="position: fixed;width: 100%;height: 100%;top:0;right: 0;background-color: rgba(0,0,0,0.4);" v-if="load">
            <van-loading type="spinner" color="#1989fa" size="50px" style="text-align: center;position: absolute;top: 50%;left: 45%"></van-loading>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { ImagePreview } from 'vant';
    import { Dialog } from 'vant';
    export default {
        name: "caseDetails",
        data() {
            return {
                IsUser : '',
                orderDeailsData : [],
                OrderText : [],
                remarkVoice : [],
                equipment : 0,
                OrderMsg : '',
                orderData :{
                    OrderTypeId : '',
                    fileData : [
                        // {img: "https://manager.lvwa18.com:8002/AllImg/1616724285.jpg", name: "93b6740661268f36e1c612b1f5b41ef5.jpg"},
                    ],
                    OrderDetails : '',
                    OrderCost : '',
                },
                orderdetail : [],
                load : false,
                trade_type : '',
                paymentQuery : null,
            }
        },
        methods :{
            stopOrder : function(){
                var _this = this
                Dialog.confirm({
                    message: '是否需要结束此订单',
                })
                    .then(() => {
                        _this.send({
                            loading: false,
                            url: '/StopOrder',
                            data: {
                                OrderId: _this.orderDeailsData.OrderId,
                            },
                            success: function (res) {
                                _this.$router.go(-1)
                                _this.$toast.success('订单已结束');
                            }
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            userOrderUpdate : function(){
                var _this = this
                if(_this.OrderMsg || _this.orderData.fileData != ''){
                    _this.send({
                        loading: false,
                        url: '/UserOrderUpdate',
                        data: {
                            UserId: window.sessionStorage.UserId,
                            UserLogin: window.sessionStorage.UserLogin,
                            UserToken: window.sessionStorage.UserToken,
                            IsUser : _this.IsUser,
                            OrderTypeId: _this.orderDeailsData.OrderTypeId,
                            OrderId: _this.orderDeailsData.OrderId,
                            OrderFile : _this.orderData.fileData,
                            OrderMsg : _this.OrderMsg,
                        },
                        success: function (res) {
                            _this.orderDeails()
                            _this.OrderMsg = ''
                            _this.orderData.fileData = []
                            _this.$toast.success('提交成功');
                        }
                    })
                }else {
                    _this.$toast('请输入留言内容')
                }
            },
            //删除图片
            deleteArray : function(index){
                var _this = this
                delete _this.orderData.fileData.splice(index, 1)
                console.log(_this.orderData.fileData)
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                var _this = this
                _this.load = true
                var param = new FormData(); //创建form对象
                param.append('file', file.file);//通过append向form对象添加数据
                // param.append('MenuId', _this.menuId);
                var config = {}
                axios.post('https://manager.lvwa18.com:8002/upload_file_H5', param, config)
                    .then(response => {
                        _this.load = false
                        console.log(response)
                        _this.orderData.fileData.push({img:response.data.Data.path,name:response.data.Data.name})
                        console.log(_this.orderData.fileData)
                    })
            },
            urgent : function(){
                var _this = this
                if(_this.orderDeailsData.IsUrgentName){
                    _this.$toast('订单加急中')
                }else {
                    Dialog.confirm({
                        confirmButtonText : '加急',
                        title: '加急处理',
                        message: '加急成功后，律师将在6个小时内完成审核拟定文件工作',
                    })
                        .then(() => {
                            _this.payment('10')
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            },
            is_labour_pay : function(OrderId,OrderTypeId){
                var _this = this
                axios({
                    // 默认请求方式为get
                    method: 'post',
                    url: 'https://manager.lvwa18.com:8002/is_labour_pay',
                    params: {OrderId:OrderId,OrderTypeId:OrderTypeId},
                    responseType: 'json'
                }).then(response => {
                    if(response.data == 1){
                        _this.$toast.success('支付成功');
                        _this.orderDeails()
                        clearInterval(_this.paymentQuery)
                        _this.paymentQuery = null;
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            //提交
            payment : function(Urgent){
                var _this = this
                if(Urgent == 10){
                    _this.send({
                        loading: false,
                        url: '/OrderUrgent',
                        data: {
                            UserId: window.sessionStorage.UserId,
                            OrderId: _this.orderDeailsData.OrderId,
                        },
                        success: function (res) {
                            console.log(res)
                        },
                    })
                }
                if(Urgent){var OrderTypeId = Urgent}else {var OrderTypeId = _this.orderDeailsData.OrderTypeId}
                _this.load = true
                _this.send({
                    loading : false,
                    url: '/labour_wx_pay',
                    data: {
                        UserId : window.sessionStorage.UserId,
                        openid: window.sessionStorage.openid||'',
                        trade_type : _this.trade_type,
                        PayMember : '',
                        OrderTypeId : OrderTypeId,
                        OrderId  : _this.orderDeailsData.OrderId,
                    },
                    success: function (res) {
                        _this.load = false
                        if(_this.trade_type == 'MWEB'){
                            window.location.href= res
                            _this.paymentQuery = setInterval(function () {
                                _this.is_labour_pay(_this.orderDeailsData.OrderId,OrderTypeId)
                            }, 1000)
                        }
                        if(_this.trade_type == 'JSAPI'){
                            _this.order_num = res.order_num
                            WeixinJSBridge.invoke(
                                // 'getBrandWCPayRequest',response.data.result,
                                'getBrandWCPayRequest', {
                                    "appId" : res.result.appId,
                                    "timeStamp": res.result.timeStamp,
                                    "nonceStr" :res.result.nonceStr ,
                                    "package" : res.result.package,
                                    "signType" :res.result.signType,
                                    "paySign" :res.result.paySign,
                                },
                                function(res){
                                    WeixinJSBridge.log(res.err_msg);
                                    if(res.err_msg == 'get_brand_wcpay_request:ok'){
                                        _this.callBackPre(_this.order_num,'SUCCESS',OrderTypeId)
                                        _this.$toast.success('支付成功');
                                        _this.modal1 = true
                                    }else {
                                        _this.callBackPre(_this.order_num,'FAIL',OrderTypeId)
                                        _this.$toast.fail('支付失败');
                                    }
                                }
                            );
                        }
                    }
                })
            },
            //支付回调
            callBackPre : function(PayNum,PayStatus,Urgent){
                console.log(PayNum,PayStatus)
                var _this = this
                if(Urgent){var OrderTypeId = Urgent}else {var OrderTypeId = _this.orderDeailsData.OrderTypeId}
                _this.load = true
                _this.send({
                    url: '/CallBackPre',
                    data: {
                        UserId : window.sessionStorage.UserId,
                        openid: window.sessionStorage.openid,
                        PayNum : PayNum,
                        PayStatus : PayStatus,
                        OrderTypeId : OrderTypeId,
                        OrderId  : _this.orderDeailsData.OrderId,
                        PayMember : '',
                    },
                    success: function (res) {
                        _this.load = false
                        if(PayStatus == 'SUCCESS'){
                            if(OrderTypeId == 10){
                                _this.$toast('加急成功')
                                // Dialog.confirm({
                                //     showCancelButton : false,
                                //     message: '加急成功',
                                // })
                                //     .then(() => {
                                //         // on confirm
                                //     })
                                //     .catch(() => {
                                //         // on cancel
                                //     });
                            }
                            _this.orderDeails()
                        }
                    }
                })
            },
            submit : function(){
                var _this = this
                if(_this.orderDeailsData.PayStatus == 1){
                    var data = {OrderId :_this.$route.query.OrderId,
                        // OrderMoney : _this.orderDeailsData.OrderMoney,
                        LawyerId : window.sessionStorage.LawyerId||'',
                        OrderFile : _this.orderData.fileData,
                        OrderMsg : _this.OrderMsg,}
                    if(_this.OrderMsg || _this.orderData.fileData != ''){
                        _this.send({
                            url: '/LawyerOrderUpdate',
                            data: data,
                            success: function (res) {
                                _this.orderDeails()
                                // _this.$router.go(-1)
                                _this.orderData.fileData = []
                                _this.OrderMsg = ''
                                _this.$toast.success('提交成功');
                            }
                        })
                    }else {
                        _this.$toast('请输入留言内容')
                    }
                }if(_this.orderDeailsData.PayStatus == 3){
                    var data = {OrderId :_this.$route.query.OrderId,
                        OrderMoney : _this.orderDeailsData.OrderMoney,
                        LawyerId : window.sessionStorage.LawyerId||'',
                        OrderFile : _this.orderData.fileData,
                        OrderMsg : _this.OrderMsg,}
                    if(_this.orderDeailsData.OrderMoney){
                        _this.send({
                            url: '/LawyerOrderUpdate',
                            data: data,
                            success: function (res) {
                                _this.orderDeails()
                                // _this.$router.go(-1)
                                _this.orderData.fileData = []
                                _this.OrderMsg = ''
                                _this.$toast.success('提交成功');
                            }
                        })
                    }else {
                        _this.$toast('请输入收费金额')
                    }
                }

            },
            previewImg : function(img){
                console.log(img)
                var Ttype="";
                let suffix = '';
                let result = '';
                const flieArr = img.split('.');
                suffix = flieArr[flieArr.length - 1];
                if(suffix!=""){
                    suffix = suffix.toLocaleLowerCase();
                    const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
                    result = imglist.find(item => item === suffix);
                    if (result) {
                        ImagePreview({
                            images: [img],
                            startPosition: 0,
                            closeable: true,
                        });
                    }else {
                        window.location.href = img
                    }
                }
            },
            orderDeails : function(){
                var _this = this
                _this.send({
                    url: '/OrderDeails',
                    data: {
                        OrderId :_this.$route.query.OrderId,
                        UserId : window.sessionStorage.UserId||'',
                        LawyerId : window.sessionStorage.LawyerId||'1',
                    },
                    success: function (res) {
                        _this.orderDeailsData = res.row
                        _this.orderdetail = res.orderdetail
                        _this.OrderText = res.row.OrderText
                        _this.remarkVoice = res.row.remarkVoice
                    }
                })
            },
            //    支付
            getCode(){
                var _this = this
                var local = 'https://manager.lvwa18.com/user/newOrder?type='+_this.$route.query.type+''
                var ua = navigator.userAgent.toLowerCase();
                console.log(this.$route.query.code)
                if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        _this.trade_type = 'JSAPI'
                        if(window.sessionStorage.openid){

                        }else {
                            _this.load = true
                            if(_this.$route.query.code == null || _this.$route.query.code == ''){
                                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx61856270fcce1981&redirect_uri='+
                                    encodeURIComponent(local)+ '&response_type=code&scope=snsapi_base&state=statetwechat_redirect'
                            }else{
                                _this.send({
                                    url: '/getOpenID',
                                    data: {
                                        code : _this.$route.query.code,
                                        appid : 'wx61856270fcce1981',
                                        appsecret : 'c1f80515f1139086dd10c90d3dbc0acb',
                                    },
                                    success: function (res) {
                                        console.log(res)
                                        _this.load = false
                                        window.sessionStorage.openid = res
                                    }
                                })
                            }
                        }
                    }else {
                        _this.trade_type = 'MWEB'
                        console.log('非微信浏览器')
                    }

                }else{
                    _this.trade_type = 'NATIVE'
                    return false; // PC端
                }
            },
        },
        mounted  () {
            var _this = this
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(isAndroid){
                console.log('是安卓');
                _this.equipment = 1
            }
            if(isiOS){
                _this.equipment = 2
                console.log('是ios');
            }
            this.IsUser = window.sessionStorage.IsUser
            this.orderDeails()
            if(window.sessionStorage.IsUser == 2){
                this.getCode()
            }
        }
    }
</script>
