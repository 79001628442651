<style scoped>
    .dialogue{position: absolute;background-color: #F7F6F9;height: 100%;width: 100%;}
    .function{width: 2.1rem;height: 1.28rem;background: #FFFFFF;box-shadow: 0px 0.02rem 0.08rem 0px rgba(0, 0, 0, 0.1);border-radius: 0.06rem;color: #333333;font-size: 0.28rem;text-align: center;margin: 0.3rem 0 0 0.3rem;display: inline-block;}
    .functionImg{width: 0.6rem;height: 0.6rem;vertical-align: top;margin: 0.16rem 0 0.1rem 0;}
    .headPortrait{width: 0.68rem;height: 0.68rem;border-radius: 50%;margin: 0.15rem 0.26rem 0 0.26rem;vertical-align: top}
    .chat{margin-top: 0.2rem;vertical-align: top;word-wrap:break-word;max-width: 4.5rem;background: #0C83E4;border-radius: 0.5rem;display: inline-block;font-size: 0.26rem;color: #FFFFFF;line-height: 0.42rem;padding: 0.21rem 0.3rem 0.2rem 0.3rem;margin-bottom: 0.49rem;text-align: left;}
    .rightChat{background-color: rgb(247, 246, 249);}
    .leftChat{text-align: left}
    .chatTime{font-size: 0.26rem;font-family: PingFang SC;font-weight: bold;color: #CCCCCC;line-height: 0.26rem;text-align: center;}
    .inputFunction{width: 7.5rem;height: 1rem;position: fixed;bottom: 0;background: #F6F6F6;border-top: 0.01rem solid #DDDDDD}
    .inputFunction >>> .van-cell{width: 5.3rem;vertical-align:top;margin-top: 0.1rem;background: #FFFFFF;border-radius: 0.38rem;font-size: 0.36rem;padding: 0.1rem 0.2rem;display: inline-block;min-height: 0.76rem;}
    .inputFunction >>> .van-field__control{max-height:0.8rem;margin-top: 0.15rem}
    .voice{width: 0.5rem;height: 0.5rem;vertical-align: top;margin: 0.25rem 0.2rem}
    .textareaInput{width: 5.3rem;vertical-align:top;margin-top: 0.1rem;height: 0.56rem;background: #FFFFFF;border-radius: 0.38rem;font-size: 0.36rem;padding: 0.1rem 0.2rem;line-height: 0.56rem;}
    .holdAll{width: 1.06rem;height: 1.06rem;background-color: #FFF;border-radius: 0.2rem;}
    .holdAll img{width: 0.52rem;height: 0.52rem;vertical-align: top;margin: 0.27rem}
    .sendout{width: 0.7rem;height: 0.55rem;background-color: #598df6;border-radius: 0.08rem;color: #ffffff;
        font-size: 0.28rem;text-align: center;line-height: 0.55rem;display: inline-block;vertical-align: top;margin:0.22rem 0 0 0.1rem }

    .pf_bg{width: 5.20rem;height: 1.86rem;}
    .nameConsultation{color: #11bd76;font-size: 0.30rem;}
    .pingfen{width: 2.21rem;height: 0.33rem;margin-top: 0.18rem;}
    .opinion{background-color: #f8f8fa;border: 0;width: 4.20rem;height: 1.22rem;margin-top: 0.20rem;color: #353535;padding: 0.20rem;resize: none;overflow: auto;font-size: 0.26rem;border-radius: 0.04rem;display: inline-block;text-align: left;margin-bottom: 0.30rem;}
    .lateron{width: 2rem;height: 0.80rem;border: 0.01rem solid #009edb;font-size: 0.28rem;color:#009edb;text-align: center;line-height: 0.80rem;border-radius: 0.40rem;display: inline-block;margin-right: 0.30rem;margin-bottom: 0.30rem;}
    .submission{width: 2rem;height: 0.80rem;font-size: 0.28rem;color: #fff;text-align: center;line-height: 0.80rem;border-radius: 0.40rem;background-color:#009edb;display: inline-block;margin-bottom: 0.30rem;}

</style>

<template>
    <div class="dialogue" id="chatRecord">
        <div style="">
            <div @click="eject = false">
                <!--<div style="position: fixed;top: 0;background-color: #F7F6F9;width: 100%;">-->
                <!--<div class="function">-->
                <!--<img src="/img/lt_icon01.png" class="functionImg">-->
                <!--<div style="line-height: 0.28rem;">结束聊天</div>-->
                <!--</div>-->
                <!--<div class="function">-->
                <!--<img src="/img/lt_icon02.png" class="functionImg">-->
                <!--<div style="line-height: 0.28rem;">评价打分</div>-->
                <!--</div>-->
                <!--<div class="function">-->
                <!--<img src="/img/lt_icon03.png" class="functionImg">-->
                <!--<div style="line-height: 0.28rem;">电话咨询</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div style="padding: 1.03rem"></div>-->
                <div style="padding: 0.44rem"></div>
                <div style="text-align: right;margin-top: 0.2rem">
                    <div class="leftChat" >
                        <div style="display: inline-block">
                            <img class="headPortrait" src="/img/logo.png">
                            <div style="font-size: 0.2rem;vertical-align: top;margin-left: 0.1rem;">律蛙法管家</div>
                        </div>
                        <div class="chat" @click="previewImg(value.content)">
                            <div style="display: inline-block;vertical-align: top;margin-right: 0.2rem">
                                <p>我们能为您提供的专业服务：</p>
                                <p>1、法律咨询；</p>
                                <p>2、合同审核编写；</p>
                                <p>3、律师函；</p>
                                <p>4、案件分析代理等。</p>
                            </div>

                            <!--<img src="/img/wenjian.png" style="width: 0.52rem;height: 0.52rem;">-->
                        </div>

                    </div>
                    <div v-for="(value,index) in dialogueData">
                        <!--左侧-->
                        <div class="leftChat" v-if="UserData.IsUser != value.IsUser">
                            <div class="chatTime">{{value.sendTime}}</div>
                            <img class="headPortrait" :src="LawyerImg||'/img/logo.png'">
                            <div class="chat" v-if="value.type == 'word'">{{value.content}}</div>
                            <div class="chat" @click="previewImg(value.content)"
                                 v-else-if="value.type == 'png'||value.type == 'jpg'||value.type == 'jpeg'||value.type == 'bmp'||value.type == 'gif'">
                                <img :src="value.content" style="width: 4rem;height: 100%">
                            </div>
                            <div class="chat" v-else @click="previewImg(value.content)">
                                <div style="display: inline-block;vertical-align: top;margin-right: 0.2rem">{{value.name}}</div>
                                <!--<img src="/img/wenjian.png" style="width: 0.52rem;height: 0.52rem;">-->
                            </div>
                        </div>
                        <!--右侧-->
                        <div class="rightChat" v-if="UserData.IsUser == value.IsUser">
                            <div class="chatTime">{{value.sendTime}}</div>
                            <div class="chat" style="background: #FFFFFF;border-radius: 0.06rem;color: #000000;margin-top: 0.3rem;" v-if="value.type == 'word'">{{value.content}}</div>
                            <div class="chat" style="background: #FFFFFF;border-radius: 0.06rem;color: #000000;margin-top: 0.3rem;" @click="previewImg(value.content)"
                                 v-else-if="value.type == 'png'||value.type == 'jpg'||value.type == 'jpeg'||value.type == 'bmp'||value.type == 'gif'">
                                <img :src="value.content" style="width: 4rem;height: 100%">
                            </div>
                            <div class="chat" style="background: #FFFFFF;border-radius: 0.06rem;color: #000000;margin-top: 0.3rem;line-height: 0.52rem;" v-else @click="previewImg(value.content)">
                                <div style="display: inline-block;vertical-align: top;margin-right: 0.2rem">{{value.name}}</div>
                                <img src="/img/wenjian.png" style="width: 0.52rem;height: 0.52rem;">
                            </div>
                            <img v-if="UserData.IsUser == 1" class="headPortrait" :src="LawyerImg||'/img/logo.png'">
                            <img v-if="UserData.IsUser == 2" class="headPortrait" :src="UserImg||'/img/enterprise.png'">
                        </div>
                    </div>
                </div>
                <!--评价-->
                <div v-if="OrderEvaluate" style="text-align: center">
                    <van-rate v-model="OrderGrade" :size="25" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
                    <div style="font-size: 0.26rem;">{{OrderEvaluate}}</div>
                </div>
                <div style="padding: 0.5rem"></div>
            </div>
            <div class="inputFunction" :style="{'height': (eject&&sendoutData==''?'2.79rem':'1.15rem')}">
                <!--<img class="voice" src="/img/yuyin.png">-->
                <van-field
                        style="margin-left: 0.2rem;width: 6.2rem"
                        v-model="sendoutData"
                        rows="1"
                        autosize
                        type="textarea"
                        placeholder=""
                        :readonly="OrderStatus"
                        :placeholder="OrderStatusWord"
                        class=""
                />
                <!--<textarea class="textareaInput" placeholder="" v-model="sendoutData"></textarea>-->
                <div class="sendout" @click="onSendout()" v-if="sendoutData != ''">发送</div>
                <img src="/img/zengjia.png" class="voice" @click="onTool()" v-else>
                <van-uploader :after-read="afterRead" accept="" delete="deletefile" style="text-align: center" class="inline-block">
                    <div style="display: inline-block;text-align: center;margin-left: 0.5rem">
                        <div class="holdAll">
                            <img src="/img/album.png">
                        </div>
                        <div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #666666;line-height: 0.42rem;">上传</div>
                    </div>
                </van-uploader>
                <!--<div style="display: inline-block;text-align: center;margin-left: 0.5rem">-->
                    <!--<div class="holdAll">-->
                        <!--<img src="/img/wenjian.png">-->
                    <!--</div>-->
                    <!--<div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #666666;line-height: 0.42rem;">文件</div>-->
                <!--</div>-->
                <!--<div style="display: inline-block;text-align: center;margin-left: 0.5rem">-->
                    <!--<div class="holdAll">-->
                        <!--<img src="/img/telephone.png">-->
                    <!--</div>-->
                    <!--<div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #666666;line-height: 0.42rem;">虚拟电话</div>-->
                <!--</div>-->
            </div>
        </div>
        <notLoggedIn ref="notLoggedIn" v-if="notLoggedIn"/>
        <div style="position: fixed;width: 100%;height: 100%;top:0;right: 0;background-color: rgba(0,0,0,0.4);" v-if="load">
            <van-loading type="spinner" color="#1989fa" size="50px" style="text-align: center;position: absolute;top: 50%;left: 45%"></van-loading>
        </div>
        <!--打分-->
        <van-popup v-model="scoring">
            <div class="center">
                <img class="pf_bg" src="https://ai-cdn.lvwa18.com/img/rusticate/pf_bg.png"/>
                <div class="nameConsultation">本次咨询还满意吗？</div>
                <van-rate v-model="OrderGrade" :size="25" color="#ffd21e" void-icon="star" void-color="#eee"/>
                <textarea  placeholder="请输入您的评价" class="opinion" v-model='OrderEvaluate'></textarea>
                <!--<textarea show-confirm-bar="{{showConfirmBar}}" bindinput="setIntroduce" placeholder="请输入您的评价" class="opinion" data-feedback = "feedback" bindinput="feedback"  value="{{feedback=='null'?'无':feedback}}" wx:else=""  disabled="disabled"></textarea>-->
                <!--<div class="lateron" @click="onClose" v-if="evaluateData">稍后再评</div>-->
                <div class="lateron" @click="onClose">关闭</div>
                <div class="submission" @click="evaluate()" v-if="!OrderGrade">提交</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import axios from 'axios'
    import { ImagePreview } from 'vant';
    import notLoggedIn from '../../components/notLoggedIn.vue'
    export default {
        name: "dialogue",
        components: {notLoggedIn},
        data() {
            return {
                eject : false,
                scoring : false,
                Content : {},
                sendoutData : '',
                dialogueData : [],
                UserData : [],
                ContactId : '',
                UserImg : '',
                LawyerImg : '',
                notLoggedIn : false,
                load : false,
                OrderStatus : false,
                OrderStatusWord : '',
                OrderGrade : 0,
                OrderId : '',
                OrderEvaluate : '',
            };
        },
        methods : {
            onClose : function(){
                var _this = this
                _this.scoring = false
            },
            evaluate : function(){
                var _this = this
                if(_this.OrderGrade&&_this.OrderEvaluate){
                    _this.send({
                        url: '/Evaluate',
                        data: {
                            UserLogin : window.sessionStorage.UserLogin,
                            UserToken : window.sessionStorage.UserToken,
                            IsUser  : window.sessionStorage.IsUser,
                            UserId : window.sessionStorage.UserId,
                            OrderId : _this.OrderId,
                            OrderEvaluate : _this.OrderEvaluate,
                            OrderGrade : _this.OrderGrade,
                        },
                        success: function (res) {
                            _this.$toast('感谢您的评价')
                        }
                    })
                }else {
                    _this.$toast('输入完成后提交')
                }
            },
            previewImg : function(img){
                var Ttype="";
                let suffix = '';
                let result = '';
                const flieArr = img.split('.');
                suffix = flieArr[flieArr.length - 1];
                if(suffix!=""){
                    suffix = suffix.toLocaleLowerCase();
                    const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
                    result = imglist.find(item => item === suffix);
                    if (result) {
                        ImagePreview({
                            images: [img],
                            startPosition: 0,
                            closeable: true,
                        });
                    }else {
                        window.location.href = img
                    }
                }
            },
             isAssetTypeAnImage(ext) {
                return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].
                indexOf(ext.toLowerCase()) !== -1;
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                var _this = this
                _this.load = true
                var param = new FormData(); //创建form对象
                var myDate = new Date();
                var sendTime = myDate.getFullYear()+'年'+parseInt(myDate.getMonth()+1)+'月'+myDate.getDate()+'日 '+myDate.getHours()+':'+myDate.getMinutes()+':'+myDate.getSeconds()
                param.append('file', file.file);//通过append向form对象添加数据
                // param.append('MenuId', _this.menuId);
                var config = {}
                axios.post('https://manager.lvwa18.com:8002/upload_file_H5', param, config)
                    .then(response => {
                        var fileExtension = (response.data.Data.path).match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
                        if(_this.isAssetTypeAnImage(fileExtension[1])){
                            var type = 'jpg'
                        }else {
                            var type = 'file'
                        }
                        if(_this.dialogueData){}else {_this.dialogueData = []}
                        _this.Content = {content:response.data.Data.path,sendTime:sendTime,IsUser:window.sessionStorage.IsUser,type:type,name:response.data.Data.name}
                        _this.dialogueData.push({content:response.data.Data.path,sendTime:sendTime,IsUser:window.sessionStorage.IsUser,type:type,name:response.data.Data.name})
                        _this.addContact()
                    })
            },
            onTool : function () {
                var _this = this
                _this.eject = !_this.eject
            },
            onSendout : function () {
                var _this = this
                var myDate = new Date();
                var sendTime = myDate.getFullYear()+'年'+parseInt(myDate.getMonth()+1)+'月'+myDate.getDate()+'日 '+myDate.getHours()+':'+myDate.getMinutes()+':'+myDate.getSeconds()
                if(_this.sendoutData == ''){

                }else {
                    _this.Content = {content:_this.sendoutData,sendTime:sendTime,IsUser:window.sessionStorage.IsUser,type:'word'}
                    if(_this.dialogueData){}else {_this.dialogueData = []}
                    _this.dialogueData.push({
                        content:_this.sendoutData,
                        sendTime:sendTime,
                        IsUser:window.sessionStorage.IsUser,
                        type:'word'})
                }
                console.log(_this.Content)
                _this.addContact()
            },
            addContact : function () {
                var _this = this
                _this.send({
                    url: '/AddContact',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        UserId : window.sessionStorage.UserId,
                        LawyerId : window.sessionStorage.User_LawyerId,
                        Content : _this.Content,
                        ContactId :_this.ContactId,
                        Type : window.sessionStorage.IsUser,
                    },
                    success: function (res) {
                        _this.load = false
                        _this.eject = false
                        _this.sendoutData = ''
                        console.log(res)
                        window.scrollTo({top:document.getElementById('chatRecord').scrollHeight})
                    }
                })
            }
        },
        mounted () {
            var _this = this
            if(window.sessionStorage.UserId == ""){
                _this.notLoggedIn = true
            }
            if(_this.$route.query.ContactId){
                _this.ContactId = _this.$route.query.ContactId
            }
            _this.UserData = window.sessionStorage
            if(_this.$route.query.ContactId){
                _this.send({
                    url: '/FindOrder',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        UserId : window.sessionStorage.UserId,
                        LawyerId : window.sessionStorage.User_LawyerId,
                        Type : window.sessionStorage.UserId,
                        ContactId :_this.$route.query.ContactId,
                    },
                    success: function (res) {
                        _this.dialogueData = res.Content
                        if(res.OrderStatus == 1){
                            _this.OrderStatus = true
                            _this.OrderStatusWord = '订单已结束'
                            if(res.OrderEvaluate == ''){
                                _this.scoring = true
                            }
                        }
                        _this.UserImg = res.UserImg
                        _this.LawyerImg = res.LawyerImg
                        //评价
                        _this.OrderId = res.OrderId
                        _this.OrderEvaluate = res.OrderEvaluate
                        _this.OrderGrade = res.OrderGrade
                        setTimeout(function(){
                            window.scrollTo(0,document.getElementById('chatRecord').scrollHeight)
                        },200)
                    }
                })
            }else {
                _this.send({
                    consulting : true,
                    url: '/AddOrder',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        UserId : window.sessionStorage.UserId,
                        LawyerId : window.sessionStorage.User_LawyerId,
                        Type : window.sessionStorage.IsUser,
                        ContactId :_this.ContactId,
                        OrderTypeId : 1,
                    },
                    success: function (res) {
                        if(res == '暂无会员'){
                            _this.$router.push({name: 'recharge'})
                            _this.$toast('开通会员后咨询');
                        }else {
                            _this.dialogueData = res.Content
                            _this.UserImg = res.UserImg
                            _this.LawyerImg = res.LawyerImg
                            _this.ContactId = res.ContactId
                            setTimeout(function(){
                                window.scrollTo(0,document.getElementById('chatRecord').scrollHeight)
                            },200)
                        }
                    }
                })
            }
        },
    }
</script>
