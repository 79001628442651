<style scoped>
    .tips{font-size: 0.36rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.36rem;margin: 0.87rem 0 0 0.55rem;display: inline-block;}
    .signinFillIn{width: 6.4rem;height: 1.1rem;border-bottom: 0.01rem solid #DEDEE0;position: relative;}
    .title{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 1.1rem;display: inline-block;vertical-align: top;position: absolute;left: 0;}
    .input{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 1.16rem;width:calc(100% - 1.16rem);position: absolute;left: 0}
    .obtainCode{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #0A76CA;line-height: 0.32rem;position: absolute;right: 0;top:0.42rem;}
    .signin{width: 6.40rem;height: 0.90rem;background: #CCEBFE;border-radius: 0.04rem;font-size: 0.30rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.9rem;display: inline-block;margin: 0.4rem 0 0.75rem;}
</style>

<template>
    <div class="register">
        <div>
            <div class="tips">您好，请注册！</div>
        </div>
        <div style="margin: 0.6rem 0.55rem 0">
            <!--<div class="signinFillIn">-->
                <!--<div class="title">注册码</div>-->
                <!--<input class="input" placeholder="请输入注册码"/>-->
            <!--</div>-->
            <!--<div class="signinFillIn">-->
                <!--<div class="title">企   业</div>-->
                <!--<input class="input" placeholder="请输入企业"/>-->
            <!--</div>-->
            <!--<div class="signinFillIn">-->
                <!--<div class="title">联系人</div>-->
                <!--<input class="input" placeholder="请输入联系人"/>-->
            <!--</div>-->
            <div class="signinFillIn">
                <div class="title">手机号</div>
                <input class="input" placeholder="请输入手机号" v-model="UserTel"/>
            </div>
            <div class="signinFillIn">
                <div class="title">密   码</div>
                <input class="input" placeholder="请输入密码" v-model="PassWord" type="password"/>
            </div>
            <div class="signinFillIn">
                <div class="title">验证码</div>
                <input class="input" placeholder="请输入验证码" v-model="Code"/>
                <div class="obtainCode" @click="getCode" v-if="messageTimer.fun==null">获取验证码</div>
                <div class="obtainCode" v-else>{{messageTimer.count+"s后重新获取"}}</div>
            </div>
        </div>
        <div class="center font-size">
            <div class="signin" v-if="UserTel != ''&&PassWord != ''&&Code!=''" style="background-color: #0A76CA;" @click="registerUser">注册</div>
            <div class="signin" v-else>注册</div>
        </div>
        <div  class="center font-size">
            <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #A2A4A6;line-height: 0.32rem;">
                —— 已有账号，<router-link :to="{name:'login'}"><span style="color: #0A76CA;">立即登录</span></router-link>——
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "register",
        data() {
            return {
                messageTimer: {
                    fun: null,
                    count: '',
                },
                UserTel : '',
                PassWord : '',
                Code : '',
                IsUser : '2',
            };
        },
        methods :{
            registerUser : function(){
                var _this = this
                _this.send({
                    url : '/RegisterUser',
                    data: {UserTel : _this.UserTel,PassWord:_this.PassWord,Code:_this.Code},
                    success: function (res) {
                        _this.login()
                        _this.$toast.success('注册成功');
                    }
                })
            },
            login : function () {
                var _this = this
                _this.send({
                    url : '/Login',
                    data: {UserTel : _this.UserTel,PassWord:_this.PassWord,IsUser:_this.IsUser},
                    success: function (res) {
                        // _this.login()
                        window.sessionStorage.UserId = res.UserId
                        window.sessionStorage.UserToken = res.UserToken
                        SYSCONFIG.server.default.data.UserId   = res.UserId
                        SYSCONFIG.server.default.data.UserToken   = res.UserToken
                        _this.setCookie(res.UserId,res.UserToken,_this.UserTel,_this.PassWord,res.UserLogin)
                        _this.$router.push({name:'home'});
                    }
                })
            },
            setCookie(UserId,UserToken,UserTel,PassWord,UserLogin) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 7); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "UserId" + "=" + UserId + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserToken" + "=" + UserToken + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserTel" + "=" + UserTel + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "PassWord" + "=" + PassWord + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserLogin" + "=" + UserLogin + ";path=/;expires=" + exdate.toGMTString();
            },
            getCode : function(){
                var _this = this
                _this.send({
                    url : '/GetCode',
                    data: {Tel : _this.UserTel},
                    success: function (res) {
                        _this.setReciprocal()
                    }
                })
            },
            setReciprocal: function () {
                var _this = this
                var TIME_COUNT = 60
                if (!_this.messageTimer.fun) {
                    _this.messageTimer.count = TIME_COUNT
                    _this.messageTimer.fun = setInterval(function () {
                        if (_this.messageTimer.count > 0 && _this.messageTimer.count <= TIME_COUNT) {
                            _this.messageTimer.count--
                        } else {
                            clearInterval(_this.messageTimer.fun)
                            _this.messageTimer.fun = null;
                        }
                    }, 1000)
                }
            },
        },
    }
</script>


