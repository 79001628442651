<template>
    <div style="font-family: PingFang SC;min-height: 12rem;font-size:0">
        <img src="/img/legalAppointment/banner1.png" style="width: 100%;">
        <div style="background: #FFFFFF;" class="vanTabs">
            <div style="margin: 0 0.3rem;font-size: 0;border-bottom: 0.01rem solid #DDD">
                <div style="font-size: 0.28rem;color: #333;font-weight: bold;line-height: 0.89rem;height: 0.89rem">您所服务的企业 <pang style="color:red;" v-if="count">{{count}}条</pang></div>
            </div>
            <van-tabs v-model="active" @click="onClick" style="height: 0.86rem">
                <van-tab >
                    <div slot="title">未预约</div>
                </van-tab>
                <van-tab>
                    <div slot="title">预约成功</div>
                </van-tab>
            </van-tabs>
        </div>
        <div style="text-align: center;margin-top: 0.3rem">
            <div style="display: inline-block;width: 6.9rem;background-color: #FFF;box-shadow: 0px 0.03rem 0.04rem 0 rgba(0, 0, 0, 0.12);border-radius: 0.04rem;margin-bottom: 0.3rem" v-for="(value,index) in userListByData">
                <div style="text-align: left;border-bottom: 1px solid #DDDDDD;">
                    <div style="width: 0.72rem;height: 0.72rem;background: #D3EBFF;border-radius: 50%;display: inline-block;text-align: center;line-height: 0.72rem;vertical-align: top;
                font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #1186E1;margin: 0.3rem 0.2rem 0.3rem 0.2rem">
                        <span v-if="String(index+1).length <= 1">0{{index+1}}</span>
                        <span v-else="">{{index}}</span>
                    </div>
                    <div style="line-height: 1.32rem;font-size: 0.28rem;display: inline-block;">{{value.CompanyName}}</div>
                </div>
                <div style="font-size: 0.24rem;text-align: left;padding-left: 0.22rem;color: #999999;border-bottom: 0.01rem dashed #DDDDDD;">
                    <div style="padding: 0.15rem"></div>
                    <div style="position: relative">
                        <div style="width: 5.10rem">
                            <div style="margin-bottom: 0.2rem">
                                <img style="vertical-align: top;margin-right: 0.05rem;width: 0.3rem;height: 0.26rem;" src="/img/category.png">
                                <div style="display: inline-block;line-height: 0.24rem">体检类别 | <span style="color: #1186E1">{{value.typeName}}</span></div>
                            </div>
                            <div style="margin-bottom: 0.2rem">
                                <img style="vertical-align: top;margin-right: 0.05rem;width: 0.3rem;height: 0.26rem;" src="/img/time1.png">
                                <div style="display: inline-block;line-height: 0.24rem">申请时间 | <span>{{value.RegisterTime}}</span></div>
                            </div>
                            <div style="margin-bottom: 0.2rem">
                                <img style="vertical-align: top;margin-right: 0.05rem;width: 0.3rem;height: 0.26rem;" src="/img/dianhua1.png">
                                <div style="display: inline-block;line-height: 0.24rem">{{value.UserName}} | <span>{{value.UserTel}}</span></div>
                                <div style="border: 0.01rem solid #1186E1;border-radius: 0.28rem;vertical-align: top;height: 0.32rem;line-height: 0.32rem;
font-size: 0.18rem;font-weight: 500;color: #1186E1;text-align: center;display: inline-block;margin-left: 0.08rem" @click="callPhone(value.UserTel)">
                                    <div style="font-size: 0.12rem;padding: 0 0.1rem">拨打电话</div>
                                </div>
                            </div>
                        </div>
                        <div style="display:inline-block;vertical-align: top;position: absolute;top: 50%;transform: translateY(-50%);right: 0.2rem">
                            <div style="width: 1.4rem;height: 0.56rem;background: #1186E1;border: 0.01rem solid #1186E1;border-radius: 0.28rem;vertical-align: top;
font-size: 0.24rem;font-weight: 500;color: #FFF;line-height: 0.56rem;text-align: center;" @click="lawyerStatus(value.UserId)" v-if="value.IsStatus == 0">确认预约</div>
                            <div style="width: 1.4rem;height: 0.56rem;background: #1186E1;border: 0.01rem solid #1186E1;border-radius: 0.28rem;vertical-align: top;
font-size: 0.24rem;font-weight: 500;color: #FFF;line-height: 0.56rem;text-align: center;" v-else="">预约成功</div>
                        </div>
                    </div>
                    <div style="margin-bottom: 0.2rem">
                        <img style="vertical-align: top;margin-right: 0.05rem;width: 0.3rem;height: 0.26rem;" src="/img/address.png">
                        <div style="display: inline-block;line-height: 0.24rem"><span>{{value.Address}}</span></div>
                    </div>
                    <!---->
                    <div v-if="value.LName" style="width: 6.3rem;height: 0.64rem;background: #F5F5F5;border-radius: 0.04rem;font-size: 0.24rem;font-weight: 500;color: #999999;line-height: 0.64rem;margin-bottom: 0.3rem">
                        <div style="margin-left: 0.2rem;display: inline-block">律师姓名|电话:{{value.LName}}{{value.LTel}}</div>
                        <div style="font-size: 0.24rem;font-weight: 500;color: #1186E1;line-height: 0.64rem;display: inline-block;float: right;margin-right: 0.2rem" @click="onModify(value.UserId,value.LName,value.LTel)" >修改</div>
                    </div>
                </div>
                <div style="text-align: left" v-if="value.context">
                    <div style="margin: 0 0.3rem 0.3rem">
                        <div style="font-size: 0.24rem;color: #333333;line-height: 0.72rem;">情况说明</div>
                        <div style="font-size: 0.24rem;font-weight: 500;color: #999;line-height: 0.34rem;">{{value.context}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div style="text-align: center">-->
            <!--<div style="width:6.9rem;background: #F7F7F7;border-radius: 0.04rem;display: inline-block;text-align: left;margin-bottom: 0.3rem;" v-for="(value,index) in userListByData">-->
                <!--<div style="width:6.9rem;position: relative">-->
                    <!--<div style="width: 0.72rem;height: 0.72rem;background: #D3EBFF;border-radius: 50%;display: inline-block;text-align: center;line-height: 0.72rem;vertical-align: top;-->
                <!--font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #1186E1;position: absolute;top: 50%;transform: translateY(-50%);left: 0.2rem;">-->
                        <!--<span v-if="String(index+1).length <= 1">0{{index+1}}</span>-->
                        <!--<span v-else="">{{index}}</span>-->
                    <!--</div>-->
                    <!--<div style="width: 4.2rem;display: inline-block;margin-left: 0.9rem">-->
                        <!--<div style="font-size: 0.24rem;font-weight: bold;color: #666;line-height: 0.24rem;padding: 0.3rem 0 0.20rem 0.19rem">申请企业：{{value.CompanyName}}</div>-->
                        <!--<div style="font-size: 0.24rem;font-weight: bold;color: #666;line-height: 0.24rem;padding: 0rem 0 0.20rem 0.19rem">企业地址：{{value.Address}}</div>-->
                        <!--<div style="font-size: 0.24rem;font-weight: bold;color: #666;line-height: 0.24rem;padding: 0rem 0 0.20rem 0.19rem">体检类别：{{value.typeName}}</div>-->
                        <!--<div style="font-size: 0.24rem;font-weight: bold;color: #666;line-height: 0.24rem;padding: 0rem 0 0.20rem 0.19rem">联系人：{{value.UserName}}</div>-->
                        <!--<div style="font-size: 0.24rem;font-weight: bold;color: #666;line-height: 0.24rem;padding-left: 0.19rem">申请时间：{{value.RegisterTime}}</div>-->
                        <!--<div style="font-size: 0;font-weight: 500;color: #999;line-height: 0.32rem;padding: 0.2rem 0 0.2rem 0.2rem;vertical-align:top;height: 0.32rem;"  @click="callPhone(value.UserTel)">-->
                            <!--<img src="/img/dianhua.png" style="width: 0.22rem;height: 0.26rem;vertical-align: top">-->
                            <!--<div style="display: inline-block;vertical-align: top;margin-left: 0.1rem;margin-top: 0.01rem;font-size: 0.24rem">{{value.UserTel}}</div>-->
                            <!--<div style="background: #F7F7F7;border: 0.01rem solid #1186E1;border-radius: 0.28rem;vertical-align: top;-->
<!--font-size: 0.18rem;font-weight: 500;color: #1186E1;text-align: center;display: inline-block;margin-left: 0.08rem" @click="callPhone(value.UserTel)">-->
                                <!--<div style="font-size: 0.12rem;padding: 0 0.1rem">拨打电话</div>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div style="display:inline-block;vertical-align: top;position: absolute;top: 50%;transform: translateY(-50%);">-->
                        <!--<div style="width: 1.4rem;height: 0.56rem;background: #1186E1;border: 0.01rem solid #1186E1;border-radius: 0.28rem;vertical-align: top;margin-top: 0.2rem;-->
<!--font-size: 0.24rem;font-weight: 500;color: #FFF;line-height: 0.56rem;text-align: center;" @click="lawyerStatus(value.UserId)" v-if="value.IsStatus == 0">确认预约</div>-->
                        <!--<div style="width: 1.4rem;height: 0.56rem;background: #1186E1;border: 0.01rem solid #1186E1;border-radius: 0.28rem;vertical-align: top;margin-top: 0.2rem;-->
<!--font-size: 0.24rem;font-weight: 500;color: #FFF;line-height: 0.56rem;text-align: center;" v-else="">预约成功</div>-->
                        <!--<div style="margin: 0.2rem 0 0 -0.4rem" v-if="value.LName">-->
                            <!--<div style="font-size: 0.18rem">律师姓名：{{value.LName}}</div>-->
                            <!--<div style="font-size: 0.18rem">联系电话：{{value.LTel}}</div>-->
                            <!--<van-button type="info" @click="onModify(value.UserId,value.LName,value.LTel)" style="float: right">修改</van-button>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div>-->
                    <!--<div style="font-size: 0.24rem;font-weight: bold;color: #666;line-height: 0.24rem;padding: 0.2rem 0 0rem 0.2rem;margin-bottom: 0.3rem">情况说明：{{value.context}}</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <!--弹出-->
        <van-dialog v-model="show" title="法治体检预约" show-cancel-button :width="availWidth" @confirm="onConfirm" @close="onClose">
            <div style="margin: 0.25rem 0">
                <van-cell-group>
                    <van-field v-model="LawyerName" label="律师姓名" placeholder="请输入律师姓名" />
                    <van-field v-model="LawyerTel" label="联系电话" placeholder="请输入联系电话" />
                </van-cell-group>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    export default {
        name: "serviceUser",
        data() {
            return {
                userListByData: [],
                count : '',
                UserId : '',
                active : 0,
                show: false,
                LawyerName : '',
                LawyerTel : '',
                availWidth : '',
            }
        },
        methods :{
            onClose : function(){
                this.LawyerName = ''
                this.LawyerTel = ''
            },
            onModify :function(UserId,LName,LTel){
                var _this = this
                _this.LawyerName = LName
                _this.LawyerTel = LTel
                _this.UserId = UserId
                _this.show = true
                console.log(LName,LTel)
            },
            onClick : function(e){
                this.active = e
                this.count = ''
                this.userListByData = []
                this.userListById()
            },
            callPhone (phoneNumber) {
                window.location.href = 'tel:' + phoneNumber
            },
            userListById : function(){
                var _this = this
                _this.send({
                    url: '/userListById',
                    data: {LawyerId:window.sessionStorage.LawyerId,type:_this.active},
                    success: function (res) {
                        _this.count = res.count
                        _this.userListByData = res.list
                    }
                })
            },
            lawyerStatus : function(UserId){
                var _this = this
                _this.UserId = UserId
                _this.show = true
            },
            onConfirm : function () {
                var _this = this
                if(_this.LawyerName != ''){
                    if (_this.LawyerTel!='') {
                        _this.send({
                            url: '/lawyerStatus',
                            data: {LawyerId:window.sessionStorage.LawyerId,UserId:_this.UserId,LawyerName:_this.LawyerName,LawyerTel:_this.LawyerTel},
                            success: function (res) {
                                _this.$toast('操作成功')
                                _this.userListById()
                            }
                        })
                    }else{
                        _this.$toast('请输入联系电话')
                    }
                }else{
                    _this.$toast('请输入律师姓名')
                }
            }
        },
        mounted () {
            this.userListById()
            this.availWidth = window.screen.availWidth*0.86
            console.log(this.availWidth*0.86)
        }
    }
</script>

<style scoped>
    .vanTabs >>> .van-tabs--line .van-tabs__wrap{height: 0.86rem}
    .vanTabs >>> .van-tabs__line{background-color: #1186E1;height: 0.06rem;width: 1.4rem}
    .vanTabs >>> .van-tab{color: #ccc;font-weight: bold;font-size: 0.28rem;}
    .vanTabs >>> .van-tab--active{color: #1186E1;font-weight: bold;font-size: 0.28rem;}
</style>
