<template>
    <div>
        <router-view/>
        <lawyerBottom :msg="OrderCount"/>
    </div>
</template>

<script>
    import lawyerBottom from '../../components/lawyerBottom.vue'
    export default {
        name: "lawyerNavigation",
        data () {
            return {
                path : '',
                OrderCount : '',
            }
        },
        components: {lawyerBottom},
        mounted () {
            this.path = window.location.hash.split('/')[1]
            var _this = this
            _this.path = _this.$route.path;
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'LawyerId') {
                        window.sessionStorage.LawyerId = arr2[1]; //保存到保存数据的地方
                    }
                }
            }
            _this.send({
                url: '/InitUser',
                consulting : true,
                data: {
                    LawyerId : window.sessionStorage.LawyerId||'LawyerId',
                    UserToken : window.sessionStorage.UserToken||'',
                    IsUser : '1',
                    UserId : '',
                    UserLogin : '',
                },
                success: function (res) {
                    window.sessionStorage.IsUser = res.IsUser || ''
                    window.sessionStorage.AreaId = res.List.AreaId || ''
                    window.sessionStorage.CityId = res.List.CityId || ''
                    window.sessionStorage.CreatTime = res.List.CreatTime || ''
                    window.sessionStorage.LawyerId = res.List.LawyerId || ''
                    window.sessionStorage.LawyerImg = res.List.LawyerImg || ''
                    window.sessionStorage.LawyerIntroduc = res.List.LawyerIntroduce || ''
                    window.sessionStorage.LawyerName = res.List.LawyerName || ''
                    window.sessionStorage.LawyerPassword = res.List.LawyerPassword || ''
                    window.sessionStorage.LawyerStatus = res.List.LawyerStatus || ''
                    window.sessionStorage.LawyerTel = res.List.LawyerTel || ''
                    window.sessionStorage.PracticeNumber = res.List.PracticeNumber || ''
                    window.sessionStorage.ProvinceId = res.List.ProvinceId || ''
                    window.sessionStorage.OrderCount = res.OrderCount || ''
                    _this.OrderCount = res.OrderCount || ''
                    console.log(_this.OrderCount)
                }
            })
            console.log(window.sessionStorage)
        },
        watch:{
            $route(to,from){
                this.path = to.path
                // console.log(SYSCONFIG.server.subscript)
            }
        },
    }
</script>

<style scoped>

</style>
