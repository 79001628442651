<template>
    <div class="center" style="margin: 0.3rem">
        <div style="font-size:0.46rem;font-weight: bold;">用户协议</div>
        <div style="text-align: left;margin: 0.2rem 0">
            <div style="font-size: 0.26rem;text-indent:0.55rem;margin-bottom: 0.1rem;">尊敬的用户，欢迎使用由成都律蛙科技有限公司（下列简称“律蛙”）提供的服务。在使用前请您阅读如下服务协议，使用本应用即表示您同意接受本协议，本协议产生法律效力，特别涉及免除或者限制律蛙责任的条款，请仔细阅读。如有任何问题，可向律蛙咨询。</div>
            <div style="font-size:0.36rem;font-weight: bold;">1. 律蛙服务</div>
            <div style="font-size: 0.26rem;text-indent:0.55rem;margin-bottom: 0.1rem;">作为服务平台，为客户和法律服务提供者提供服务，服务内容和质量由提供服务者负责。</div>
            <div style="font-size:0.36rem;font-weight: bold;">2. 服务条款的变更和修改</div>
            <div style="font-size: 0.26rem;text-indent:0.55rem;margin-bottom: 0.1rem;">律蛙有权在必要时修改服务条款，服务条款一旦发生变更，将会在页面上显示已修改的内容。如果不同意律蛙修改的内容，用户可以放弃获得的本应用信息服务。如果用户继续享用本应用的信息服务，则视为接受服务条款的变更。本应用保留随时修改或中断服务而不需要通知用户的权利。本应用行使修改或中断服务的权利，不需对用户或第三方负责。</div>
            <div style="font-size:0.36rem;font-weight: bold;">3. 用户行为</div>
            <div style="font-size:0.36rem;font-weight: bold;">3.1 用户账号、密码和安全</div>
            <div style="font-size: 0.26rem;text-indent:0.55rem;margin-bottom: 0.1rem;">用户一旦注册成功，便成为律蛙法管家的合法用户，将得到一个密码和帐号。同时，此账号密码可登律蛙的所有网页和APP。因此用户应采取合理措施维护其密码和帐号的安全，对利用该密码和帐号所进行的一切活动负全部责任。</div>
        </div>
        <div style="font-size:0.46rem;font-weight: bold;">隐私条款</div>
        <div style="text-align: left;margin: 0.2rem 0">
            <div style="font-size:0.36rem;font-weight: bold;">1.用户信息公开情况说明</div>
            <div style="font-size: 0.26rem;text-indent:0.55rem;margin-bottom: 0.1rem;">尊重用户个人隐私是律蛙的一项基本政策。所以，律蛙不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本应用中的非公开内容，除非有下列情况：</div>
            <div style="font-size: 0.26rem;margin-bottom: 0.1rem;">（1）有关法律规定或律蛙合法服务程序规定；</div>
            <div style="font-size: 0.26rem;margin-bottom: 0.1rem;">（2）在紧急情况下，为维护用户及公众的权益；</div>
            <div style="font-size: 0.26rem;margin-bottom: 0.1rem;">（3）为维护律蛙的商标权、专利权及其他任何合法权益；</div>
            <div style="font-size: 0.26rem;margin-bottom: 0.1rem;">（4）其他需要公开、编辑或透露个人信息的情况；</div>
            <div style="font-size: 0.26rem;text-indent:0.55rem;margin-bottom: 0.1rem;">在以下（包括但不限于）几种情况下，律蛙有权使用用户的个人信息：</div>
            <div style="font-size: 0.26rem;margin-bottom: 0.1rem;">（1）律蛙可以将用户信息与提供服务者相匹配；</div>
            <div style="font-size: 0.26rem;margin-bottom: 0.1rem;">（2）在客户发布不良回馈时，与客户联系沟通；</div>
            <div style="font-size: 0.26rem;margin-bottom: 0.1rem;">（3）律蛙会通过透露合计用户统计数据，向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述律蛙的服务。</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "agreement"
    }
</script>

<style scoped>

</style>
