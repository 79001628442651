import { render, staticRenderFns } from "./templateDetails.vue?vue&type=template&id=a1058e02&scoped=true"
import script from "./templateDetails.vue?vue&type=script&lang=js"
export * from "./templateDetails.vue?vue&type=script&lang=js"
import style0 from "./templateDetails.vue?vue&type=style&index=0&id=a1058e02&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1058e02",
  null
  
)

export default component.exports