<template>
    <div class="home" style="margin:1vw;">
        <!--<Button type="success" @click="getPermission()" style="margin:1vw;">获取麦克风权限</Button>-->
        <!--<br/>-->
        {{orderData}}
        <Button type="success" @touchstart="getTouchStart($event)" @touchend="getTouchEnd">长按</Button>
        <div @click="sss">播放</div>
        <audio controls style="margin: 0.06rem 0 0 0.2rem" v-if="orderData">
            <source :src="orderData" type="audio/mpeg">
            您的浏览器不支持 audio 元素。
        </audio>
        <MRecorder @handleStop="handelEndRecord" />
    </div>
</template>

<script>
    import MRecorder from '../../components/mRecorder.vue'
    import axios from 'axios'
    import wx from 'weixin-js-sdk'
    export default {
        name: 'home',
        components: {MRecorder},
        data () {
            return {
                sufu : null,
                url : '',
                localId : '',
                msource : '',
                orderData : '',
            }
        },
        mounted(){
            var _this =this
            _this.send({
                consulting: true,
                url: '/getConfig',
                data: {
                    url:window.location.href
                },
                success: function (res) {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: res.appid, // 必填，公众号的唯一标识
                        timestamp: res.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.nonceStr, // 必填，生成签名的随机串
                        signature: res.signature, // 必填，签名
                        jsApiList: ['startRecord', 'stopRecord', 'uploadVoice', 'onVoiceRecordEnd','downloadVoice','playVoice'] // 必填，需要使用的JS接口列表
                    });
                }
            })
        },
        methods: {
            dataURLtoFile :function (dataurl, filename = 'file') {
                let arr = dataurl.split(',')
                let mime = arr[0].match(/:(.*?);/)[1]
                let suffix = mime.split('/')[1]
                let bstr = atob(arr[1])
                let n = bstr.length
                let u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], `${filename}.${suffix}`, {
                    type: mime
                })
            },
            voice : function(url){
                var _this = this
                fetch(url).then(data=>{
                    const blob = data.blob();
                    return blob;
                }).then(blob=>{
                    let reader = new window.FileReader();
                    reader.onloadend = function() {
                        const data = reader.result;
//                      console.log(_this.dataURLtoFile(data))
                        var param = new FormData(); //创建form对象
                        param.append('file', _this.dataURLtoFile(data));//通过append向form对象添加数据
                        // param.append('MenuId', _this.menuId);
                        var config = {}
                        axios.post('https://manager.lvwa18.com:8002/upload_file_H5', param, config)
                            .then(response => {
                                _this.orderData = response.data.Data.path
                            })
                    };
                    reader.readAsDataURL(blob);
                })
            },
            // 录音处理结束事件
            handelEndRecord(param) {
                console.log(param)
                this.msource = param;
                if(param.url){
                    this.voice(this.msource.url)
                }
            },
            sss : function(){
                wx.playVoice({
                    localId: this.localId // 需要播放的音频的本地ID，由stopRecord接口获得
                });
            },
            getTouchStart(event){
                event.preventDefault();
                clearTimeout(this.sufu);
                this.sufu=setTimeout(function(){
                    wx.startRecord({
                        success: function(e){
                            console.log(e)
                            // 开始录音的代码处理
                        },
                        cancel: function (e) {
                            console.log(e)
                        }
                    })
                },500);
            },
            getTouchEnd(){
                console.log(1)
                clearTimeout(this.sufu);
                let that = this
                // clearInterval(this.timer)
                wx.stopRecord({
                    success: function (res) {
                        // 结束后的代码处理
                        that.localId = res.localId
                        that.uploadVoice(res.localId);
                    }
                })
            },
            uploadVoice(_localId) {
                let that = this
                // 上传到微信为临时素材
                wx.uploadVoice({
                    localId: _localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: function (res) {
                        // 获得微信服务端音频ID
                        var serverId = res.serverId; // 返回音频的服务器端ID

                        axios({
                            // 默认请求方式为get
                            method: 'post',
                            url: 'https://manager.lvwa18.com:8002/UrlTest',
                            params: {url : res.serverId,},
                            responseType: 'json'
                        }).then(response => {
                            alert('成功')
                        }).catch(error => {
                            alert('失败')
                        });
                        axios({
                            // 默认请求方式为get
                            method: 'get',
                            url: 'https://api.weixin.qq.com/cgi-bin/media/get',
                            params: {media_id:serverId,access_token:'45_1rFWxvu6cbRmCAqvk1lagBaTrxJ8gQPakEXZVea02TR9sNM4_aAqJwC7eNZPwsLUO3mbRQPO3sOp5vsrERQ_-W1W0sL1XYe9MOL3-ulzrRPyTaa17v-DcvH1qpNdNfrKuaPQ4nm0L7s2CtXBIZJdACANEO'},
                            responseType: 'json'
                        }).then(response => {
                            alert('成功')
                            that.url = response
                        }).catch(error => {
                            alert('失败')
                        });
                    }
                });
            }
        },

    }
</script>

<style lang='less' scoped>

</style>
