<template>
    <div>
        <div class="filescontent">
            <div style="font-weight:bold;font-size: 0.26rem;text-align: center;">{{ExternalArticleTitle}}</div>
            <div v-html="ExternalArticleContent"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "caseBaseDetails",
        data() {
            return {
                ExternalArticleContent : '',
                ExternalArticleTitle : ''
            }
        },
        methods :{
            caseArticleById : function(){
                var _this = this
                _this.send({
                    url: '/CaseArticleById',
                    data: {ExternalArticleId:_this.$route.query.type},
                    success: function (res) {
                        _this.ExternalArticleContent = res.ExternalArticleContent
                        _this.ExternalArticleTitle = res.ExternalArticleTitle
                    }
                })
            },
        },
        mounted () {
            var _this = this
            _this.caseArticleById()
        }
    }
</script>

<style scoped>
    .filescontent{color: #000000;font-size: 0.22rem;  margin: 0 0.60rem 1.00rem;line-height: 0.60rem;overflow: hidden;}
    /* font-weight:bold;   */
</style>
