<style scoped>
    .orderRecords{background-color: #F7F6F9}
    .dialogueNumber{width: 7.5rem;height: 1.12rem;background: #FEFEFE;line-height: 1.12rem}
    .newOrder{width: 1.68rem;height: 0.52rem;background-color: #0C83E4;border-radius: 0.26rem;text-align: left;font-size: 0.22rem;line-height: 0.52rem;color: #FFF;display: inline-block;float: right;vertical-align: top;margin: 0.3rem 0.3rem 0 0;}
    /*.addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;}*/
    .addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;font-size:0.3rem;}

    .headPortrait{position: relative;height:100%;display: inline-block;}
    .informationList{width: 6.9rem;height: 1.5rem;background-color: #FFF;margin-top: 0.3rem;display: inline-block;text-align: left}
    .tips{width: 0.30rem;height: 0.30rem;background: #EB1818;border-radius: 50%;position: absolute;font-size: 0.24rem;color: #FFF;text-align: center;top: 0.32rem;right: 0}
    /*.lawName{font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.32rem;vertical-align: top;margin: 0.37rem 0 0.2rem 0rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}*/
    .content{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .time{font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;float: right;margin: 0.43rem 0.3rem 0 0}
    .list{width: 6.9rem;height: 2.04rem;background-color: #FFF;display: inline-block;text-align: left;margin-top: 0.3rem;border-radius: 0.04rem;}
    .listType{height: 1.08rem;border-bottom: 0.01rem solid #D6DDE2;line-height: 1.09rem;}
    .headPortrait{width: 0.54rem;height: 0.54rem;border-radius: 0.54rem;margin: 0.2rem 0.2rem 0 0.3rem}
    .lawName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #6B757D;line-height: 0.94rem;display: inline-block;vertical-align: top;}
    .state{font-size: 0.224rem;font-family: PingFang SC;font-weight: 400;line-height: 0.94rem;display: inline-block;float: right;margin-right: 0.3rem}
    .typeListTrue{width: 1.14rem;height: 0.6rem;background: #0C83E4;border-radius: 0.04rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.6rem;vertical-align: top;margin: 0.3rem 0 0 0.3rem;}
    .typeListFalse{width: 1.14rem;height: 0.6rem;background: #B6BCD1;border-radius: 0.04rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.6rem;vertical-align: top;margin: 0.3rem 0 0 0.3rem;}
    .choice{padding:0.2rem 0;width:100%;font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.22rem;text-align: center;display: inline-block;vertical-align: top;}
    .triangle{ width: 0;height: 0;border-left: 0.06rem solid #FFF;border-right: 0.06rem solid #FFF;border-top: 0.11rem solid #666;display: inline-block;margin-left: 0.2rem}
    .screenList{width: 100%;height: 1rem;border-bottom: 0.01rem solid #DEDEE0;font-size: 0.3rem;line-height: 1rem;text-align: center}

</style>

<template>
    <div class="orderRecords">
        <div class="dialogueNumber">
            <div style="color: #666666;font-size: 0.24rem;margin-left: 0.3rem;display: inline-block;vertical-align: top;">
                您目前有<span style="color: #0C83E4;font-size: 0.24rem">{{Count}}</span>条咨询
            </div>
        </div>
        <div class="font-size center">
            <div class="choice" @click="payment = true">{{stateWord}}<div class="triangle"></div></div>
            <!--<div style="width: 0.02rem;height: 0.3rem;background: #D6DDE2;margin-top: -0.03rem;" class="inline-block"></div>-->
            <!--<div class="choice" @click="screenType = true">{{OrderContent}}<div class="triangle"></div></div>-->
        </div>
        <div class="center font-size" >
            <div class="list" @click="onRecord(value.OrderId,value.UserId)" v-for="(value,index) in recordList">
                <div class="listType">
                    <div style="color:#6B757D;font-size: 0.30rem;vertical-align: top;margin-left: 0.3rem;font-weight: bold;" class="inline-block">{{value.OrderName}}<span v-if="value.IsUrgentName" style="color: red">({{value.IsUrgentName}})</span></div>
                    <div style="color:#999;font-size: 0.21rem;float: right;margin-right: 0.3rem" class="inline-block">{{value.OrderCreatedTime}}</div>
                </div>
                <div style="height: 0.94rem;vertical-align: top;">
                    <img v-if="value.UserImg" :src="value.UserImg" class="headPortrait">
                    <img v-else src="/img/enterprise.png" class="headPortrait">
                    <div class="lawName">{{value.UserName}}</div>
                    <div class="state" style="color: #019C93;">{{value.PayStatusName}}</div>
                </div>
            </div>
        </div>

        <van-popup v-model="payment" position="bottom" close-icon="close" closeable>
            <div style="padding: 0.3rem"></div>
            <div class="screenList" @click="onState(2,'已完成')">已完成</div>
            <div class="screenList" @click="onState(1,'处理中')">处理中</div>
            <div class="screenList" @click="onState(3,'已支付')">已支付</div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "orderRecords",
        data() {
            return {
                page : '1',
                pageSize : '20',
                Count : 0,
                recordList : [],
                OrderTypeId :'',
                StartTime : '',
                EndTime : '',
                stateWord : '状态',
                payment : false,
                OrderStatus : ''
            };
        },
        methods :{
            onState : function(OrderStatus,stateWord){
                this.OrderStatus = OrderStatus
                this.stateWord = stateWord
                this.payment = false
                this.lawyerOrderRow()
            },
            onRecord : function(OrderId,UserId){
                var _this = this
                window.sessionStorage.UserId = UserId
                _this.$router.push({name: 'caseDetails', query: {OrderId:OrderId}})
            },
            lawyerOrderRow  : function () {
                var _this = this
                _this.send({
                    url: '/LawyerOrderRow',
                    data: {
                        LawyerId : window.sessionStorage.LawyerId,
                        page : _this.page,
                        pageSize : _this.pageSize,
                        OrderTypeId : _this.OrderTypeId,
                        StartTime : _this.StartTime,
                        EndTime : _this.EndTime,
                        OrderStatus : _this.OrderStatus
                    },
                    success: function (res) {
                        _this.recordList = res.row
                        _this.Count = res.count
                    }
                })
            }
        },
        mounted () {
            this.lawyerOrderRow()
        }
    }
</script>

