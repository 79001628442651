<template>
    <div>
        <router-view/>
        <!--<userBottom msg=""/>-->
    </div>
</template>

<script>
    import userBottom from '../../components/userBottom.vue'
    export default {
        name: "userNavigation",
        data () {
            return {
                path : ''
            }
        },
        components: {userBottom},
        mounted () {
            this.path = window.location.hash.split('/')[1]
            var _this = this
            _this.path = _this.$route.path;
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'UserId') {
                        window.sessionStorage.UserId = arr2[1];
                    } else if(arr2[0] == 'UserToken') {
                        window.sessionStorage.UserToken = arr2[1];
                    }else if(arr2[0] == 'UserLogin') {
                        window.sessionStorage.UserLogin = arr2[1];
                    }else if(arr2[0] == 'openid') {
                        window.sessionStorage.openid = arr2[1];
                    }
                }
            }
            _this.send({
                url: '/InitUser',
                consulting : true,
                data: {
                    UserId : window.sessionStorage.UserId||'UserId',
                    UserToken : window.sessionStorage.UserToken||'',
                    IsUser : '2',
                    UserLogin : window.sessionStorage.UserLogin||'',
                    LawyerId : '',
                },
                success: function (res) {
                    if(res){
                        window.sessionStorage.IsUser = res.IsUser|| ''
                        window.sessionStorage.memberDay = res.memberDay|| ''
                        window.sessionStorage.LawyerLinkman = res.LawyerLinkman|| ''
                        window.sessionStorage.LawyerName = res.LawyerName|| ''
                        window.sessionStorage.streetName = res.streetName|| ''
                        window.sessionStorage.User_LawyerId = res.User_LawyerId|| ''
                        window.sessionStorage.date = res.date|| ''
                        window.sessionStorage.Introduce = res.List.Introduce|| ''
                        window.sessionStorage.CompanyName = res.List.CompanyName|| ''
                        window.sessionStorage.LastLogin = res.List.LastLogin|| ''
                        window.sessionStorage.Password = res.List.Password|| ''
                        window.sessionStorage.RegisterCode = res.List.RegisterCode|| ''
                        window.sessionStorage.RegisterTime = res.List.RegisterTime|| ''
                        window.sessionStorage.UserId = res.List.UserId|| ''
                        window.sessionStorage.UserImg = res.List.UserImg || ''
                        window.sessionStorage.UserLogin = res.List.UserLogin|| ''
                        window.sessionStorage.UserName = res.List.UserName || ''
                        window.sessionStorage.UserTel = res.List.UserTel|| ''
                        window.sessionStorage.status = res.List.status|| ''
                        window.sessionStorage.IsStatus = res.List.IsStatus|| ''
                        window.sessionStorage.LTel = res.List.LTel|| ''
                        window.sessionStorage.LName = res.List.LName|| ''
                        window.sessionStorage.LawyerTel = res.LawyerTel|| ''
                    }else {
                        window.sessionStorage.UserId = ''
                    }

                }
            })
        },
        watch:{
            $route(to,from){
                this.path = to.path
                console.log(window.sessionStorage.status)
            }
        },
    }
</script>

<style scoped>

</style>
