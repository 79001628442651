<template>
    <div class="userBottom">
        <div style="padding-bottom: 1.2rem;"></div>
        <van-tabbar v-model="active" @change="onChange">
            <van-tabbar-item v-for="(value,index) in icon" :key="index" :to="value.url">
                <span>{{value.name}}</span>
                <img
                        slot="icon"
                        slot-scope="props"
                        :src="props.active ? value.active : value.inactive"
                >
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "userBottom",
        props: {
            msg: String
        },
        data() {
            return {
                active: 0,
                icon: [
                    {
                        active: '/img/bottom_01.png',
                        inactive: '/img/bottom_1.png',
                        name: '首页',
                        url: '/user/home'
                    },
                    {
                        active: '/img/bottom_02.png',
                        inactive: '/img/bottom_2.png',
                        name: '服务记录',
                        url: '/user/serviceRecord'
                    },
                    {
                        active: '/img/bottom_04.png',
                        inactive: '/img/bottom_4.png',
                        name: '咨询',
                        url: '/user/service'
                    },
                    {
                        active: '/img/bottom_03.png',
                        inactive: '/img/bottom_3.png',
                        name: '个人中心',
                        url: '/user/my'
                    },
                ],
                path : '',
            }
        },
        methods: {
            onChange(index) {
                window.sessionStorage.active = index
            }
        },
        mounted () {
            var _this = this
            if(_this.$route.path == '/user/home'){
                _this.active = 0
            }else if (_this.$route.path == '/user/my') {
                _this.active = 3
            }else if (_this.$route.path == '/user/serviceRecord') {
                _this.active = 1
            }else if (_this.$route.path == '/user/service') {
                _this.active = 2
            }else {
                _this.active = parseInt(window.sessionStorage.active)
            }
        },
        watch: {
            // 监听路由变化
            "$route.path": function(newVal, oldVal) {
                var _this = this
                if (newVal == '/user/my') {
                    _this.active = 3
                } else if (newVal == '/user/serviceRecord') {
                    _this.active = 1
                } else if (newVal == '/user/service') {
                    _this.active = 2
                }
                if (newVal == '/user/home') {
                    _this.active = 0
                }
            }
        }
    }
</script>

<style scoped>
    .userBottom >>> .van-tabbar{height: 1rem;font-size: 0.2rem;}
    .userBottom >>> .van-tabbar-item__icon img{width: 0.48rem;height: 0.46rem}
</style>
