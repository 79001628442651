<template>
    <div>
        <div class="font-size center" style="padding-top: 0.5rem;">
            <p style="font-size: 0.24rem; font-weight: 600; margin-left: 0.4rem; text-align: left;">您的意见或建议：</p>
            <textarea placeholder="输入个人意见反馈，字数在200字以内" class="opinion" v-model="Msg"></textarea>
            <button class="van-button van-button--default van-button--normal" @click="modify">
                <span class="van-button__text">提交建议</span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "opinion",
        data() {
            return {
                Msg : '',
            }
        },
        methods :{
            modify : function(){
                var _this = this
                if(_this.Msg){
                    _this.send({
                        url : '/Feedback',
                        data: {UserId : window.sessionStorage.UserId,Msg:_this.Msg,LawyerId:window.sessionStorage.LawyerId},
                        success: function (res) {
                            _this.$router.go(-1)
                            _this.$toast.success('提交成功');
                        }
                    })
                }else {
                    _this.$toast('请输入您的意见或建议');
                }

            },
        },
    }
</script>

<style scoped>
    .van-button--normal {
        width: 6.7rem;
        height: .7rem;
        border-radius: .6rem;
        font-size: .24rem;
        background-color: #4195ff;
        color: #fff;
        margin-top: .5rem;
        line-height: .7rem;
    }
    .opinion{
        background-color: #fff;
        border: 0;
        width: 6.6rem;
        height: 2rem;
        margin-top: .2rem;
        color: #565656;
        padding: .2rem;
        resize: none;
        overflow: auto;
        font-size: .26rem;
        border-radius: .1rem;
    }
</style>
