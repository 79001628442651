<style scoped>
    .my{background: #F7F6F9;}
    .head{width: 100%;height: 3.48rem;background: linear-gradient(-45deg, #02C5FB, #006DEB);text-align: center}
    .headPortrait{width: 1.08rem;height: 1.08rem;vertical-align: top;margin:0.4rem 0.15rem 0 0.4rem;border-radius: 1.08rem}
    .companyName{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #0C577F;line-height: 0.3rem;margin: 0.58rem 0 0.2rem 0}
    .fullName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #567AAE;line-height: 0.24rem;}
    .vip{width: 0.52rem;height: 0.3rem;vertical-align: top;margin-left: 0.15rem;}
    .businessCard{background: url("/img/vip_bg.png");background-size: 100% 100%;width: 6.9rem;height: 2.7rem;display: inline-block;text-align: left;margin-top: 1.19rem}
    .enterprise{width: 1.08rem;height: 1.08rem;border-radius: 1.08rem;background-color: #FFF;text-align: center;display: inline-block;margin:0.4rem 0.15rem 0 0.4rem;}
    .label{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #0C577F;line-height: 0.30rem;display: inline-block;vertical-align: top;width: calc(20% - 0.02rem); ;text-align: center;border-right: 0.01rem solid #80A6D2;margin-top: 0.4rem;}
    .openingName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #333333;line-height: 0.3rem;margin: 0.76rem 0 0.34rem 0.3rem;}
    .modeChoiceTrue{width: 2.06rem;height: 2.40rem;background: #FBF1D9;border: 0.02rem solid #E8A912;box-shadow: 0px 0.06rem 0.12rem 0px rgba(232, 169, 18, 0.5);border-radius: 0.08rem;position: relative;text-align: center;display: inline-block;margin-left: 0.29rem;}
    .modeChoiceFalse{width: 2.1rem;height: 2.40rem;background: #FFFFFF;box-shadow: 0px 0.06rem 0.12rem 0px rgba(0, 0, 0, 0.08);border-radius: 0.08rem;position: relative;text-align: center;display: inline-block;margin-left: 0.29rem;}
    .recommend{width: 0.95rem;height: 0.4rem;vertical-align: top;position: absolute;top:-0.01rem;left: 0.00rem;}
    .money{font-size: 0.26rem;color: #E8A912;line-height: 0.46rem;margin-top: 0.8rem;}
    .payment{display: inline-block;
        width: 6.9rem;height: 0.86rem;background: #E8A912;border-radius: 0.43rem;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #FEFEFE;line-height: 0.86rem;text-align: center;display: inline-block;margin: 0.3rem 0 0.2rem 0;}
    .agreement{
        font-size: 0.2rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.3rem;margin-bottom: 0.29rem;}
    .explain{
        font-size: 0.3rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #282828;
        line-height: 0.3rem;margin: 0.3rem 0;}
    .explainList{background: #F3F9FE;border: 0.01rem solid #D6DDE2;font-family: PingFang SC;text-align: left;width: 6.3rem;display: inline-block}
    .explainLeft{width: 3.78rem;margin-left: 0.3rem;border-right: 0.01rem solid #D6DDE2;}
    .explainTitle{font-size: 0.28rem;font-weight: bold;color: #282828;line-height: 0.27rem;padding-top: 0.3rem}
    .explainContent{font-size: 0.22rem;font-weight: 500;color: #666666;line-height: 0.3rem;padding: 0.19rem 0.27rem 0.3rem 0;}
    .explainRight{font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #666666;line-height: 0.3rem;margin: 0.62rem 0 0.3rem 0}
    .explainT{font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #E71717;line-height: 0.3rem;}
    .signinFillIn{width: 6.4rem;height: 1.1rem;border-bottom: 0.01rem solid #DEDEE0;position: relative;display: inline-block}
    .title{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 1.1rem;display: inline-block;vertical-align: top;position: absolute;left: 0;}
    .input{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 1.16rem;width:calc(100% - 1.16rem);position: absolute;left: 0}
    .obtainCode{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #0A76CA;line-height: 0.32rem;position: absolute;right: 0;top:0.42rem;}
    .signin{width: 6.40rem;height: 0.90rem;background: #CCEBFE;border-radius: 0.04rem;font-size: 0.30rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.9rem;display: inline-block;margin: 0.4rem 0 0.75rem;}
    .my >>> .van-dialog{width: 6.9rem;text-align: center}
</style>

<template>
    <div class="my" v-show="redirect">
        <div class="head">
            <div class="businessCard">
                <img class="headPortrait" :src="UserImg" v-if="UserImg">
                <img class="enterprise" src="/img/enterprise.png" v-else>
                <div class="inline-block" style="vertical-align: top" v-if="UserId">
                    <div class="companyName">{{CompanyName}}<img class="vip" src="/img/vip.png" v-if="memberDay > 0"></div>
                    <div class="fullName">{{UserName}} <span v-if="UserName && UserTel">| </span>{{UserTel}}</div>
                    <div class="fullName" style="margin-top: 0.1rem;" v-if="memberDay > 0">法管家VIP：{{date}}</div>
                </div>
                <router-link :to="{name:'login'}" v-else><div class="inline-block" style="vertical-align: top;color: #567AAE">
                    <div style="font-size: 0.32rem;margin-top: 0.73rem;">未登录，请登录</div>
                </div></router-link>
                <div>
                    <div class="label" @click="onTool('record')">付款记录</div>
                    <div class="label" @click="show = true">修改密码</div>
                    <div class="label" @click="onTool('userInformation')">用户信息</div>
                    <div class="label" @click="onTool('opinion')">意见反馈</div>
                    <div class="label" @click="signOut()">退出登录</div>
                </div>
                <!---->
            </div>
        </div>
        <div>
            <div class="openingName">开通法管家VIP</div>
            <div v-for="(value,index) in moneyData" :class="[type == value.type?'modeChoiceTrue':'modeChoiceFalse']" @click="onMoney(value.type,value.money)">
                <img class="recommend" src="/img/tj.png" v-if="value.type == 3">
                <div class="money"><span>￥</span><span style="font-size: 0.46rem;margin-right: 0.07rem">{{value.money}}</span><span>{{value.time}}</span></div>
                <div style="font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.22rem;">{{value.tips}}</div>
            </div>
        </div>
        <div style="text-align: center">
            <div class="payment" @click="payment">立即以{{money}}元开通</div>
            <div class="agreement">开通即视为同意<router-link :to="{name:'agreement'}" style="color: #0C83E4">《律蛙法管家会员用户协议》</router-link></div>
        </div>
        <div class="center font-size">
            <div style="width: 6.9rem;background-color: #FFF;margin-top: 0.3rem" class="inline-block">
                <div class="explain">服务说明</div>
                <div class="explainList">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">01人工法律咨询<span style="color: red">（会员）</span></div>
                        <div class="explainContent">劳动纠纷、股权、工商、行政合规、 经营合法、合同纠纷、企业日常运营 合规等问题、会员期内免费</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.31rem" class="inline-block">
                        <div class="explainRight">工作时间：</div>
                        <div class="explainT">9:00-18:00</div>
                    </div>
                </div>
                <div class="explainList" style="background-color: #FFF">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">02常用文件下载<span style="color: red">（会员）</span></div>
                        <div class="explainContent">常用合同、起诉、上诉、申请、仲裁等模板下载</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem;width: 2rem;" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;font-weight: bold;line-height: 1.6rem;">免费</div>
                        <div style="font-size: 0.2rem;"></div>
                    </div>
                </div>
                <div class="explainList">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">03法律工具查询<span style="color: red">（会员）</span></div>
                        <div class="explainContent">失信限高人群查询、法律法规查询</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem;width: 2rem;" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">免费</div>
                        <!--<div style="font-size: 0.2rem;line-height: 0.2rem"></div>-->
                    </div>
                </div>
                <div class="explainList" style="background-color: #FFF">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">04合同审核<span style="color: red">（增值）</span></div>
                        <div class="explainContent">有效性、关键信息的提示、合同履行 风险、合同条款的完善等</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem;width: 2rem;" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">200元起/份</div>
                        <div style="font-size: 0.2rem;">市场价800-1000/份</div>
                    </div>
                </div>
                <div class="explainList">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">05合同编写<span style="color: red">（增值）</span></div>
                        <div class="explainContent">入股合同、员工劳务合同、合作合同、 采购合同模板</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem;width: 2rem;" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">800元起/份</div>
                        <div style="font-size: 0.2rem;">市场价1000-1500/份</div>
                    </div>
                </div>
                <div class="explainList" style="background-color: #FFF">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">06发律师函<span style="color: red">（增值）</span></div>
                        <div class="explainContent">催款律师函、告知律师函帮助企业非 诉解决问题</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem;width: 2rem;" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">800元起/份</div>
                        <div style="font-size: 0.2rem;">市场价1000-1500/份</div>
                    </div>
                </div>
                <div class="explainList" >
                    <div class="inline-block explainLeft" style="height: 1.89rem;">
                        <div class="explainTitle">07案件代理<span style="color: red">（增值）</span></div>
                        <div class="explainContent">精准匹配专业律师,(一案一议)</div>
                    </div>
                    <div style="vertical-align: top;width: 1.59rem;margin: 0.38rem 0.31rem 0.39rem 0.3rem" class="inline-block">
                        <div style="font-size: 0.22rem;color: #E71717;line-height: 0.28rem;font-weight: bold;">收费不超过(案件 涉及金额*10%+ 案件复杂情况附 加费)</div>
                        <!--<div style="font-size: 0.2rem;line-height: 0.2rem"></div>-->
                    </div>
                </div>
                <!--<div class="explainList" style="background-color: #FFF">-->
                    <!--<div class="inline-block explainLeft">-->
                        <!--<div class="explainTitle">08陪同谈判<span style="color: red">（增值）</span></div>-->
                        <!--<div class="explainContent"></div>-->
                    <!--</div>-->
                    <!--<div style="vertical-align: top;margin-left: 0.18rem;width: 2rem;" class="inline-block">-->
                        <!--<div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">1000元/次/人</div>-->
                        <!--<div style="font-size: 0.2rem;">限时2小时，超时按500元/时</div>-->
                    <!--</div>-->
                <!--</div>-->
                <div style="padding: 0.15rem"></div>
            </div>
        </div>
        <van-dialog v-model="show" title="修改密码" show-cancel-button confirmButtonText="" cancelButtonText="关闭" @confirm="modify">
            <div class="signinFillIn">
                <div class="title">手机号</div>
                <input class="input" placeholder="请输入手机号" v-model="UserTel"/>
            </div>
            <div class="signinFillIn">
                <div class="title">密   码</div>
                <input class="input" placeholder="请输入密码" v-model="PassWord" type="password"/>
            </div>
            <div class="signinFillIn">
                <div class="title">验证码</div>
                <input class="input" placeholder="请输入验证码" v-model="Code"/>
                <div class="obtainCode" @click="verificationCode" v-if="messageTimer.fun==null">获取验证码</div>
                <div class="obtainCode" v-else>{{messageTimer.count+"s后重新获取"}}</div>
            </div>
        </van-dialog>
        <div style="position: fixed;width: 100%;height: 100%;top:0;right: 0;background-color: rgba(0,0,0,0.4);" v-if="load">
            <van-loading type="spinner" color="#1989fa" size="50px" style="text-align: center;position: absolute;top: 50%;left: 45%"></van-loading>
        </div>
        <notLoggedIn ref="notLoggedIn" v-if="notLoggedIn"/>
    </div>
</template>

<script>
    import axios from 'axios'
    import notLoggedIn from '../../components/notLoggedIn.vue'
    export default {
        name: "my",
        components: {notLoggedIn},
        data() {
            return {
                show: false,
                messageTimer: {
                    fun: null,
                    count: '',
                },
                PassWord : '',
                Code : '',
                IsUser : '2',

                type : '3',
                money : '720',
                moneyData : [
                    {money:'720',time:'/3年',tips:'每天仅需0.65元',type:'3'},
                    {money:'720',time:'/2年',tips:'每天仅需0.98元',type:'2'},
                    {money:'360',time:'/1年',tips:'每天仅需1.1元',type:'1'},
                ],
                toolData : [
                    {name:'付款记录',img:'/img/grzx_01.png',type:'1',url:'record'},
                    {name:'用户信息',img:'/img/grzx_02.png',type:'2',url:'userInformation',},
                    {name:'修改密码',img:'/img/grzx_03.png',type:'3',url:'',},
                    {name:'意见反馈',img:'/img/grzx_04.png',type:'4',url:'',},
                    {name:'关于我们',img:'/img/grzx_05.png',type:'5',url:'',},
                ],
                memberDay : '',
                date : '',
                UserTel : '',
                UserName : '',
                UserImg : '',
                CompanyName : '',
                notLoggedIn : false,
                UserId : '',
                redirect : true,
                fun : null,
                trade_type : '',
                paymentQuery : null,
                load : false,
            };
        },
        methods :{
            signOut : function(){
                var _this = this
                _this.send({
                    url : '/Logout',
                    data: {UserLogin:_this.UserLogin,IsUser:_this.IsUser},
                    success: function (res) {
                        window.sessionStorage.memberDay = ''
                        window.sessionStorage.date  = ''
                        window.sessionStorage.UserTel  = ''
                        window.sessionStorage.UserName  = ''
                        window.sessionStorage.UserImg  = ''
                        window.sessionStorage.CompanyName  = ''
                        window.sessionStorage.UserId  = ''
                        window.sessionStorage.UserLogin  = ''
                        window.sessionStorage.LawyerName  = ''
                        _this.$router.push({name: 'login',})
                        // _this.$toast.success('');
                    }
                })
            },
            modify : function(){
                var _this = this
                _this.send({
                    url : '/EditPassWord',
                    data: {UserTel : _this.UserTel,PassWord:_this.PassWord,Code:_this.Code},
                    success: function (res) {
                        _this.$toast.success('修改成功');
                    }
                })
            },
            verificationCode : function(){
                var _this = this
                _this.send({
                    url : '/GetCode',
                    data: {Tel : _this.UserTel},
                    success: function (res) {
                        _this.setReciprocal()
                    }
                })
            },
            setReciprocal: function () {
                var _this = this
                var TIME_COUNT = 60
                if (!_this.messageTimer.fun) {
                    _this.messageTimer.count = TIME_COUNT
                    _this.messageTimer.fun = setInterval(function () {
                        if (_this.messageTimer.count > 0 && _this.messageTimer.count <= TIME_COUNT) {
                            _this.messageTimer.count--
                        } else {
                            clearInterval(_this.messageTimer.fun)
                            _this.messageTimer.fun = null;
                        }
                    }, 1000)
                }
            },
            onTool : function (url) {
                var _this = this
                if(window.sessionStorage.UserId == ""){
                    _this.notLoggedIn = true
                    _this.$refs.notLoggedIn.handleParentClick()
                }else {
                    if(url != ''){
                        _this.$router.push({name: url})
                    }
                }

            },
            payment : function(){
                var _this = this
                _this.load = true
                if(window.sessionStorage.UserId == ""){
                    _this.notLoggedIn = true
                    _this.$refs.notLoggedIn.handleParentClick()
                }
                var ua = navigator.userAgent.toLowerCase();
                _this.send({
                    consulting : true,
                    url: '/labour_wx_pay',
                    data: {
                        UserId : window.sessionStorage.UserId,
                        openid: window.sessionStorage.openid||'',
                        trade_type : _this.trade_type,
                        PayMember : _this.type,
                        OrderTypeId : '',
                        orderId  : '',
                    },
                    success: function (res) {
                        _this.load = false
                        if(_this.trade_type == 'MWEB'){
                            window.location.href= res
                        }
                        if(_this.trade_type == 'JSAPI'){
                            _this.order_num = res.order_num
                            WeixinJSBridge.invoke(
                                // 'getBrandWCPayRequest',response.data.result,
                                'getBrandWCPayRequest', {
                                    "appId" : res.result.appId,
                                    "timeStamp": res.result.timeStamp,
                                    "nonceStr" :res.result.nonceStr ,
                                    "package" : res.result.package,
                                    "signType" :res.result.signType,
                                    "paySign" :res.result.paySign,
                                },
                                function(res){
                                    WeixinJSBridge.log(res.err_msg);
                                    console.log(res)
                                    if(res.err_msg == 'get_brand_wcpay_request:ok'){
                                        _this.callBackPre(_this.order_num,'SUCCESS')
                                        _this.$toast.success('支付成功');
                                    }else {
                                        _this.callBackPre(_this.order_num,'FAIL')
                                        _this.$toast.fail('支付失败');
                                    }
                                }
                            );
                        }
                    }
                })
            },
            //支付回调
            callBackPre : function(PayNum,PayStatus){
                console.log(PayNum,PayStatus)
                var _this = this
                _this.load = true
                _this.send({
                    consulting : true,
                    url: '/CallBackPre',
                    data: {
                        UserId : window.sessionStorage.UserId,
                        openid: window.sessionStorage.openid,
                        PayNum : PayNum,
                        PayStatus : PayStatus,
                        OrderTypeId : '',
                        OrderId : '',
                        PayMember : _this.type,
                    },
                    success: function (res) {
                        _this.load = false
                        if(PayStatus == 'SUCCESS'){
                            _this.initUser()
                        }
                    }
                })
            },
            initUser : function(){
                var _this = this
                _this.send({
                    url: '/InitUser',
                    consulting : true,
                    data: {
                        UserId : window.sessionStorage.UserId||'UserId',
                        UserToken : window.sessionStorage.UserToken||'',
                        IsUser : '2',
                        UserLogin : window.sessionStorage.UserLogin||'',
                        LawyerId : '',
                    },
                    success: function (res) {
                        if(res){
                            _this.date = res.date|| ''
                            _this.memberDay = res.memberDay|| ''
                            window.sessionStorage.LawyerLinkman = res.LawyerLinkman|| ''
                            window.sessionStorage.LawyerName = res.LawyerName|| ''
                            window.sessionStorage.streetName = res.streetName|| ''
                            window.sessionStorage.LawyerLinkman = res.LawyerLinkman|| ''
                            window.sessionStorage.LawyerName = res.LawyerName|| ''
                            window.sessionStorage.streetName = res.streetName|| ''
                            window.sessionStorage.date = res.date|| ''
                            window.sessionStorage.memberDay = res.memberDay|| ''
                            window.sessionStorage.User_LawyerId = res.User_LawyerId|| ''
                            // window.sessionStorage.LTel = res.List.LTel|| ''
                            // window.sessionStorage.LName = res.List.LName|| ''
                            // window.sessionStorage.IsStatus = res.IsStatus|| ''
                        }else {
                            window.sessionStorage.UserId = ''
                        }

                    }
                })
            },
            onMoney : function (type,money) {
                var _this = this
                _this.type = type
                _this.money = money
            },
            //    支付
            getCode(){
                var _this = this
                var local = 'https://manager.lvwa18.com/user/my'
                var ua = navigator.userAgent.toLowerCase();
                if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        _this.trade_type = 'JSAPI'
                        if(window.sessionStorage.openid){

                        }else {
                            _this.load = true
                            if(_this.$route.query.code == null || _this.$route.query.code == ''){
                                _this.redirect = false
                                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx61856270fcce1981&redirect_uri='+
                                    encodeURIComponent(local)+ '&response_type=code&scope=snsapi_base&state=statetwechat_redirect'
                            }else{
                                _this.send({
                                    url: '/getOpenID',
                                    data: {
                                        code : _this.$route.query.code,
                                        appid : 'wx61856270fcce1981',
                                        appsecret : 'c1f80515f1139086dd10c90d3dbc0acb',
                                    },
                                    success: function (res) {
                                        console.log(res)
                                        _this.load = false
                                        window.sessionStorage.openid = res
                                    }
                                })
                            }
                        }
                    }else {
                        _this.trade_type = 'MWEB'
                        _this.initUser()
                        // console.log('非微信浏览器')
                    }

                }else{
                    _this.trade_type = 'NATIVE'
                    return false; // PC端
                }
            },
        },
        mounted () {
            var _this = this
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    if(arr2[0] == 'openid') {
                        window.sessionStorage.openid = arr2[1];
                    }
                }
            }
            if(window.sessionStorage.memberDay == undefined){
                var fun = setInterval(function () {
                    if(_this.memberDay == ''){
                        console.log(_this.memberDay)
                        _this.memberDay = window.sessionStorage.memberDay
                        _this.date = window.sessionStorage.date
                        _this.UserTel = window.sessionStorage.UserTel
                        _this.UserName = window.sessionStorage.UserName
                        _this.UserImg = window.sessionStorage.UserImg
                        _this.CompanyName = window.sessionStorage.CompanyName
                        _this.UserId = window.sessionStorage.UserId || ''
                        _this.UserLogin = window.sessionStorage.UserLogin || ''
                    }else {
                        console.log(_this.memberDay)
                        clearInterval(fun)
                    }
                }, 1000)
            }else {
                _this.memberDay = window.sessionStorage.memberDay
                _this.date = window.sessionStorage.date
                _this.UserTel = window.sessionStorage.UserTel
                _this.UserName = window.sessionStorage.UserName
                _this.UserImg = window.sessionStorage.UserImg
                _this.CompanyName = window.sessionStorage.CompanyName
                _this.UserId = window.sessionStorage.UserId || ''
                _this.UserLogin = window.sessionStorage.UserLogin || ''

                console.log(window.sessionStorage.UserName)
            }
            // _this.memberDay = window.sessionStorage.memberDay
            _this.getCode()
        },
    }
</script>

