<style scoped>
    .templatec{background: #F7F6F9;}
    .tabNameTrue{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 1.08rem;display: inline-block;width: calc(25% - 0.01rem)}
    .tabNameFalse{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #B1B8CC;line-height: 1.08rem;display: inline-block;width: 1.78rem}
    .line{width: 0.01rem;height: 0.2rem;background: #D6DDE2;display: inline-block;}
    .tabLine{width: 0.54rem;height: 0.06rem;background: #0C83E4;border-radius: 0.03rem;position: absolute;left: 0.82rem;top:0.78rem;transition: 300ms;-o-transition: 300ms;-moz-transition: 300ms;-webkit-transition: 300ms;}
    .list{width: 6.3rem;border-bottom: 0.01rem solid #D6DDE2;height: 0.99rem;display: inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align: left}
    .ListContent{;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 1rem;text-align: left;display: inline-block}
    .stars{width: 0.32rem;height: 0.3rem;vertical-align: top;float: right;margin: 0.35rem 0.3rem 0 0}
</style>

<template>
    <div class="templatec">
        <div style="height: 1.08rem;position: relative" class="font-size center">
            <div class="tabLine" :style="{left:(type*(7.5/groceryList.length)-(0.54/2))-(7.5/groceryList.length/2)+'rem'}"></div>
            <div v-for="(value,index) in groceryList" class="tabNameTrue">
                <div @click="tabSwitch(index+1,index)" class="inline-block">{{value.text}}</div>
                <!--<div class="line"></div>-->
            </div>

        </div>
        <div class="font-size center">
            <div style="background-color: #FFF;border-radius: 0.04rem;width: 6.9rem" class="inline-block">
                <div class="list" v-for="(value,index) in content" @click="onTemplate(value.id)">
                    <div class="ListContent">{{value.name}}</div>
                    <img class="stars" src="/img/shoucang0.png" v-if="value.isCollect == 1" @click.stop="collectDTemplate(value.id,value.isCollect,index)">
                    <img class="stars" src="/img/shoucang1.png" v-else  @click.stop="collectDTemplate(value.id,value.isCollect,index)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "templatec",
        data() {
            return {
                type : 2,
                kind : '1',
                text : '常用合同',
                groceryList: [//选着类型
                    {id: 4, text: '常用合同', type: 'changyong'},
                    {id: 0, text: '企业管理', type: 'qiyeguanli'},
                    {id: 1, text: '员工招聘', type: 'yuangongzhaopin'},
                    {id: 2, text: '其他', type: 'qita'},
                ],
                content : [],
                _type : '',
                UserId : ''
            };
        },
        methods :{
            tabSwitch : function (e,index) {
                var _this = this
                _this.type = e
                window.sessionStorage.fileTemplate = e-1
                _this.text = _this.groceryList[index].text
                _this._type = _this.groceryList[index].type
                _this.send({
                    url: '/doc_template',
                    data: {kind : _this.kind,type : _this.groceryList[index].text,UserId:_this.UserId,EType:_this.groceryList[index].type},
                    success: function (res) {
                        _this.content = res.list
                    }
                })
            },
            onTemplate : function (id) {
                var _this = this
                _this.$router.push({name: 'templateDetails', query: {id: id,type:_this.text,_type:_this._type}})
            },
            collectDTemplate : function (id,isCollect,index) {
                var _this = this
                _this.send({
                    url: '/CollectDTemplate',
                    consulting : true,
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        id: id,
                        UserId : window.sessionStorage.UserId,
                        type :_this.groceryList[_this.type-1].text,
                        EType  :_this.groceryList[_this.type-1].type,
                    },
                    success: function (res) {
                        if(isCollect == 1){
                            _this.content[index].isCollect = 0
                            _this.$toast('取消收藏')
                        }else{
                            _this.content[index].isCollect = 1
                            _this.$toast('收藏成功')
                        }
                        console.log(_this.content[index].isCollect)
                    }
                })
            }
        },
        mounted () {
            var _this = this
            console.log(window.sessionStorage.fileTemplate)
            if(window.sessionStorage.fileTemplate){
               var id = window.sessionStorage.fileTemplate
            }else {
               var id = 0
            }
            _this.type = parseInt(window.sessionStorage.fileTemplate)+1 || 1

        //
            if(window.sessionStorage.UserId == undefined){
                var fun = setInterval(function () {
                    if(_this.UserId == ''){
                        _this.UserId = window.sessionStorage.UserId || ''
                    }else {
                        console.log(_this.UserId)
                        clearInterval(fun)
                        _this.tabSwitch(_this.type,id)
                    }
                }, 100)
            }else {
                _this.UserId = window.sessionStorage.UserId || ''
                _this.tabSwitch(_this.type,id)
            }

        }
    }
</script>


