<style scoped>
    .record{background: #F7F6F9;}
    .tabLine{width: 0.54rem;height: 0.06rem;background: #0C83E4;border-radius: 0.03rem;position: absolute;left: 0.88rem;top:0.78rem;transition: 300ms;-o-transition: 300ms;-moz-transition: 300ms;-webkit-transition: 300ms;}
    .tabNameTrue{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 1.04rem;display: inline-block;width:50%;}
    .tabNameFalse{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #B1B8CC;line-height: 1.04rem;display: inline-block;width: 50%}
    .paymentList{width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;display: inline-block;font-size: 0;text-align: left;margin-bottom: 0.4rem;}
    .paymentName{font-size: 0.22rem;font-family: PingFang SC;margin: 0.3rem 0 0 0.29rem;font-weight: bold;color: #0C83E4;line-height: 0.22rem;display: inline-block;vertical-align: top;}
    .paymentTime{font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.22rem;float: right;display: inline-block;margin: 0.3rem 0.3rem 0 0;}
    .orderNo{font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;color: #B1B8CC;line-height: 0.22rem;display: inline-block;vertical-align: top;margin: 0.29rem 0 0 0.28rem}
    .mode{font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;color: #B1B8CC;line-height: 0.22rem;float: right;display: inline-block;margin: 0.29rem 0.3rem 0 0;}
    .money{font-size: 0.36rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.36rem;margin: 0.3rem 0 0 0.32rem;display: inline-block;}
    .payment{font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;color: #2DADA5;line-height: 0.28rem;float: right;margin: 0.32rem 0.3rem 0 0}
    .amount{margin-bottom: 0.26rem}
    .company{
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 0.28rem;margin: 0.4rem 0 0.4rem 0.29rem}
    .invoiceCode{
        font-size: 0.22rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #B1B8CC;
        line-height: 0.22rem;width: 33.3%}
    .choice{width:calc(50% - 0.01rem);font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.22rem;text-align: center;display: inline-block;vertical-align: top;height: 0.56rem;}
    .triangle{ width: 0;height: 0;border-left: 0.06rem solid #FFF;border-right: 0.06rem solid #FFF;border-top: 0.11rem solid #666;display: inline-block;margin-left: 0.2rem}
    .screenList{width: 100%;height: 1rem;border-bottom: 0.01rem solid #DEDEE0;font-size: 0.3rem;line-height: 1rem;text-align: center}

</style>

<template>
    <div class="record">
        <div style="height: 1.04rem;position: relative;border-bottom: 0.01rem solid #D6DDE2" class="font-size center">
            <div class="tabLine" :style="{left:(type*(7.5/2)-(0.54/2))-(7.5/2/2)+'rem'}"></div>
            <div :class="type == 1?'tabNameTrue': 'tabNameFalse'" @click="tabSwitch(1)">付款记录</div>
            <div :class="type == 2?'tabNameTrue': 'tabNameFalse'" @click="tabSwitch(2)">开票记录</div>
        </div>
        <div class="center">
            <div class="font-size" v-if="type == 1" style="margin-top: 0.3rem">
                <div class="choice" @click="payment = true">{{stateWord}}<div class="triangle"></div></div>
                <!--<div style="width: 0.02rem;height: 0.3rem;background: #D6DDE2;margin-top: -0.03rem;" class="inline-block"></div>-->
                <!--<div class="choice" @click="screenType = true">{{OrderContent}}<div class="triangle"></div></div>-->
            </div>
            <div class="paymentList" v-if="type == 1" v-for="(value,index) in userPayData">
                <div>
                    <div class="paymentName" v-if="Temp == 1">{{value.BuyType}}年会员充值</div>
                    <div class="paymentName" v-if="Temp == 2">{{value.OrderName}}</div>
                    <div class="paymentTime">{{value.GreatTime}}</div>
                </div>
                <div>
                    <div class="orderNo">订单号 {{value.PayNum}}</div>
                    <div class="mode">微信支付</div>
                </div>
                <div class="amount">
                    <div class="money"><span style="font-size: 0.22rem;">￥</span>{{value.PayMoney}}</div>
                    <div class="payment" v-if="value.PayStatus == null" style="color: #D01616;">未支付</div>
                    <div class="payment" v-if="value.PayStatus == 'SUCCESS'">支付成功</div>
                    <div class="payment" v-if="value.PayStatus == 'FAIL'" style="color: #D01616;">取消支付</div>
                </div>
            </div>
            <div class="paymentList" v-if="type == 2">
                <!--<div>-->
                    <!--<div class="paymentName">年度会员充值</div>-->
                    <!--<div class="paymentTime">已开票</div>-->
                    <!--<div class="paymentTime" style="margin-right: 1.34rem">2021-03-12  11:03:58</div>-->
                <!--</div>-->
                <!--<div class="company">成都律蛙科技有限公司</div>-->
                <!--<div style="margin: 0 0 0 0.28rem">-->
                    <!--<div class="inline-block invoiceCode">-->
                        <!--<div>发票代码</div>-->
                        <!--<div style="color: #333;margin: 0.29rem 0 0.36rem">001548794544</div>-->
                    <!--</div>-->
                    <!--<div class="inline-block invoiceCode center">-->
                        <!--<div>发票号码</div>-->
                        <!--<div style="color: #333;margin: 0.29rem 0 0.36rem">54646454</div>-->
                    <!--</div>-->
                    <!--<div class="money" style="float: right;margin: 0.4rem 0.3rem 0 0;"><span style="font-size: 0.22rem;">￥</span>399.0</div>-->
                <!--</div>-->
            </div>
            <div style="padding: 0.2rem;"></div>
        </div>
        <!--状态-->
        <van-popup v-model="payment" position="bottom" close-icon="close" closeable>
            <div style="padding: 0.3rem"></div>
            <div class="screenList" @click="onState(2,'订单付款记录')">订单付款记录</div>
            <div class="screenList" @click="onState(1,'充值记录')">充值记录</div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "record",
        data() {
            return {
                type : '1',
                Temp : '1',
                userPayData : [],
                payment : false,
                stateWord : '充值记录',
            };
        },
        methods :{
            onState : function(index,name){
                var _this = this
                _this.stateWord = name
                _this.Temp = index
                _this.payment = false
                _this.userPayList()
            },
            userPayList : function(){
                var _this = this
                _this.send({
                    url: '/UserPayList',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        UserId : window.sessionStorage.UserId,
                        Temp : _this.Temp,
                    },
                    success: function (res) {
                        _this.userPayData = res.list
                    }
                })
            },
            tabSwitch : function (e) {
                var _this = this
                _this.type = e
            },
        },
        mounted  () {
            this.userPayList()
        }
    }
</script>
