<template>
    <div style="font-family: PingFang SC;background-color: rgb(246, 245, 248);" :style="{'min-height':availHeight+'px'}">
        <div v-if="admin_id == 1" style="background: url(/img/legalAppointment/banner1.png);background-size: 100% 100%;width: 7.5rem;height: 3.6rem;text-align: center;font-family: PingFang SC;position: relative">

        </div>
      <img v-if="admin_id == 2" style="width: 7.5rem;height: 3.6rem" src="/img/fztj.jpg">
        <div style="margin-left: 0.3rem;font-size: 0;">
            <div style="font-size: 0.36rem;color: #333;font-weight: bold;height: 1.47rem;text-align: center">
                <img src="/img/wz.png" style="width: 0.68rem;height: 0.68rem;margin: 0.4rem 0.12rem 0 0;vertical-align: top;" />
                <div style="display: inline-block;line-height: 1.47rem;">选择企业所属街道</div>
            </div>
            <div style="width: 6.9rem;background: #F6F5F8;border-radius: 0.04rem;">
                <div v-for="(value,i) in columns" style="display: inline-block">
                    <div class="aboutFalse" @click="onChoice(i,value.streetId,value.streetName)">
                        <div style="position: absolute;z-index: 2; left: 50%;top: 50%;transform: translate(-50%, -50%);width: 100%;white-space: nowrap;">{{value.streetName}}</div>
                        <img src="/img/img.png" style="width: 2.4rem;height: 0.86rem;position: absolute;top: 0;right: 0;">
                    </div>
                </div>
            </div>
            <!--<div style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;position: relative" @click="show = true">-->
                <!--<input v-model="streetData.streetName" readonly="readonly" style="height: 0.86rem;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;color: #666;" placeholder="选择街道" />-->
                <!--<img src="img/legalAppointment/down.png" style="width: 0.2rem;height: 0.12rem;position: absolute;right: 0.2rem;top: 0.37rem">-->
            <!--</div>-->
            <!--<div @click="nextStep" style="width: 6.4rem;height: 0.86rem;background: #1186E1;border-radius: 0.04rem;font-size: 0.28rem;font-weight: bold;color: #FFFFFF;text-align: center;-->
            <!--line-height: 0.86rem;margin-top: 0.4rem;margin-bottom: 0.3rem">下一步</div>-->
        </div>
        <!-- <div style="text-align: center">
            <div style="font-size: 0.26rem;color: #999;line-height: 0.26rem;margin-bottom: 0.3rem;">成都市武侯区司法局 监制</div>
            <router-link :to="{name: 'home'}" style="font-size: 0.26rem;color: rgb(32 137 254);line-height: 0.26rem;vertical-align: top;text-decoration: underline">进入>> 律蛙法管家</router-link>
        </div> -->
        <!--<van-popup v-model="show" position="bottom">-->
            <!--<van-picker show-toolbar :columns="columns" @confirm="onConfirm"/>-->
        <!--</van-popup>-->
    </div>
</template>

<script>
    import wx from 'weixin-js-sdk';
    export default {
        name: "legal-inspect",
        data() {
            return {
                availHeight : '',
                show : false,
                columns: [],
                streetData : [],
                index : '-1',
              admin_id : '1',
            }
        },
        methods :{
            getConfig : function(){
                var _this =this
                _this.send({
                    consulting: true,
                    url: '/getConfig',
                    data: {
                        url:window.location.href
                    },
                    success: function (res) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.appid, // 必填，公众号的唯一标识
                            timestamp: res.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.nonceStr, // 必填，生成签名的随机串
                            signature: res.signature, // 必填，签名
                            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage", "onMenuShareQQ"]
                        });
                        wx.ready(function () {
                            //获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
                            wx.onMenuShareAppMessage({
                                title: '千所百处帮万企', // 分享标题
                                desc: '千所百处帮万企-法治体检预约', // 分享描述
                                link:window.location.href, // 分享链接
                                imgUrl:'https://manager.lvwa18.com/img/fx.png', // 分享图标
                                type: '', // 分享类型,music、video或link，不填默认为link
                                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                                success: function () {

                                }
                            })
                            //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
                            wx.onMenuShareTimeline({
                                title: '千所百处帮万企', // 分享标题
                                desc: '千所百处帮万企-法治体检预约', // 分享描述
                                link:window.location.href, // 分享链接
                                imgUrl:'https://manager.lvwa18.com/img/fx.png', // 分享图标
                                type: '', // 分享类型,music、video或link，不填默认为link
                                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                                success: function () {

                                }
                            })
                        })
                    }
                })
            },
            onChoice : function(i,streetId,streetName){
                var _this = this
                _this.index = i
                _this.$router.push({name: 'matchingLawFirm', query: {streetId:streetId,streetName:streetName}})
            },
            onConfirm : function(e){
                this.streetData = e
                this.show = false
            },
            nextStep : function(){
                var _this = this
                if(_this.streetData != ''){
                    _this.$router.push({name: 'matchingLawFirm', query: {streetId:_this.streetData.streetId,streetName:_this.streetData.streetName}})
                }else{
                    _this.$toast('请选择街道')
                }
            },
            streetList : function(){
                var _this = this
                _this.send({
                    url: '/streetList',
                    data: {},
                    success: function (res) {
                        for (var i in res){
                            res[i].text = res[i].streetName
                        }
                        _this.columns = res
                    }
                })
            },
        },
        mounted () {
          this.admin_id = this.$route.query.admin_id || 1
          window.screen.admin_id = this.$route.query.admin_id || 1
            this.streetList()
            this.getConfig()
            this.availHeight = window.screen.availHeight
        }
    }
</script>

<style scoped>
    .aboutFalse{width: 3rem;height: 0.86rem;background: #FFFFFF;border-radius: 0.04rem;font-size: 0.26rem;color: #333;line-height: 0.9rem;text-align: center;font-weight: 500;display: inline-block;margin: 0 0 0.3rem 0.3rem;overflow:hidden;vertical-align: top;position: relative;}
    .aboutTrue{border: 1px solid rgba(235, 248, 255, 0.5);width: 1.9rem;height: 0.9rem;background: rgba(235, 248, 255, 1);border-radius: 0.04rem;font-size: 0.26rem;color: #333;line-height: 0.9rem;text-align: center;font-weight: 500;display: inline-block;margin: 0 0 0.3rem 0.3rem;overflow:hidden;vertical-align: top;position: relative;}
</style>
