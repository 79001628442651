<style scoped>
    .my{background: #F7F6F9;}
    .head{width: 100%;height: 3.48rem;background: linear-gradient(-45deg, #02C5FB, #006DEB);text-align: center}
    .headPortrait{width: 1.08rem;height: 1.08rem;vertical-align: top;margin:0.4rem 0.15rem 0 0.4rem;border-radius: 1.08rem}
    .companyName{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #0C577F;line-height: 0.3rem;margin: 0.58rem 0 0.2rem 0}
    .fullName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #567AAE;width: 4.5rem}
    .vip{width: 0.52rem;height: 0.3rem;vertical-align: top;margin-left: 0.15rem;}
    .businessCard{background: url("/img/vip_bg.png");background-size: 100% 100%;width: 6.9rem;height: 2.7rem;display: inline-block;text-align: left;margin-top: 1.19rem}
    .enterprise{width: 1.08rem;height: 1.08rem;border-radius: 1.08rem;background-color: #FFF;text-align: center;display: inline-block;margin:0.4rem 0.15rem 0 0.4rem;}
    .label{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #0C577F;line-height: 0.30rem;display: inline-block;vertical-align: top;width: calc(25% - 0.02rem); ;text-align: center;border-right: 0.01rem solid #80A6D2;margin-top: 0.4rem;}
    .openingName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #333333;line-height: 0.3rem;margin: 0.76rem 0 0.34rem 0.3rem;}
    .modeChoiceTrue{width: 2.06rem;height: 2.40rem;background: #FBF1D9;border: 0.02rem solid #E8A912;box-shadow: 0px 0.06rem 0.12rem 0px rgba(232, 169, 18, 0.5);border-radius: 0.08rem;position: relative;text-align: center;display: inline-block;margin-left: 0.29rem;}
    .modeChoiceFalse{width: 2.1rem;height: 2.40rem;background: #FFFFFF;box-shadow: 0px 0.06rem 0.12rem 0px rgba(0, 0, 0, 0.08);border-radius: 0.08rem;position: relative;text-align: center;display: inline-block;margin-left: 0.29rem;}
    .recommend{width: 0.95rem;height: 0.4rem;vertical-align: top;position: absolute;top:-0.01rem;left: 0.00rem;}
    .money{font-size: 0.26rem;color: #E8A912;line-height: 0.46rem;margin-top: 0.8rem;}
    .payment{display: inline-block;
        width: 6.9rem;height: 0.86rem;background: #E8A912;border-radius: 0.43rem;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #FEFEFE;line-height: 0.86rem;text-align: center;display: inline-block;margin: 0.3rem 0 0.2rem 0;}
    .agreement{
        font-size: 0.2rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.3rem;margin-bottom: 0.29rem;}
    .explain{
        font-size: 0.3rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #282828;
        line-height: 0.3rem;margin: 0.3rem 0;}
    .explainList{background: #F3F9FE;border: 0.01rem solid #D6DDE2;font-family: PingFang SC;text-align: left;width: 6.3rem;display: inline-block}
    .explainLeft{width: 3.78rem;margin-left: 0.3rem;border-right: 0.01rem solid #D6DDE2;}
    .explainTitle{font-size: 0.28rem;font-weight: bold;color: #282828;line-height: 0.27rem;padding-top: 0.3rem}
    .explainContent{font-size: 0.22rem;font-weight: 500;color: #666666;line-height: 0.3rem;padding: 0.19rem 0.27rem 0.3rem 0;}
    .explainRight{font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #666666;line-height: 0.3rem;margin: 0.62rem 0 0.3rem 0}
    .explainT{font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #E71717;line-height: 0.3rem;}

    .signinFillIn{width: 6.4rem;height: 1.1rem;border-bottom: 0.01rem solid #DEDEE0;position: relative;display: inline-block}
    .title{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 1.1rem;display: inline-block;vertical-align: top;position: absolute;left: 0;}
    .input{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 1.16rem;width:calc(100% - 1.16rem);position: absolute;left: 0}
    .obtainCode{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #0A76CA;line-height: 0.32rem;position: absolute;right: 0;top:0.42rem;}
    .signin{width: 6.40rem;height: 0.90rem;background: #CCEBFE;border-radius: 0.04rem;font-size: 0.30rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.9rem;display: inline-block;margin: 0.4rem 0 0.75rem;}
    .lawyerMy >>> .van-dialog{width: 6.9rem;text-align: center}
</style>
<template>
    <div class="lawyerMy">

        <div class="head">
            <div class="businessCard">
                <img class="headPortrait" :src="UserImg" v-if="UserImg">
                <img class="enterprise" src="/img/mrtx.png" v-else>
                <div class="inline-block" style="vertical-align: top">
                    <div class="companyName">{{userData.CompanyName}}</div>
                    <div class="fullName">{{userData.LawyerName}} | {{userData.LawyerTel}}</div>
                </div>
                <div>
                    <div class="label" @click="show = true">修改密码</div>
                    <!--<div class="label" @click="onTool('userInformation')">用户信息</div>-->
                    <div class="label" @click="onTool('opinion')">意见反馈</div>
                    <div class="label" @click="signOut()">退出登录</div>
                </div>
            </div>
        </div>
        <van-dialog v-model="show" title="修改密码" show-cancel-button confirmButtonText="" cancelButtonText="关闭" @confirm="modify">
            <div class="signinFillIn">
                <div class="title">手机号</div>
                <input class="input" placeholder="请输入手机号" v-model="LawyerTel"/>
            </div>
            <div class="signinFillIn">
                <div class="title">密   码</div>
                <input class="input" placeholder="请输入密码" v-model="PassWord" type="password"/>
            </div>
            <div class="signinFillIn">
                <div class="title">验证码</div>
                <input class="input" placeholder="请输入验证码" v-model="Code"/>
                <div class="obtainCode" @click="verificationCode" v-if="messageTimer.fun==null">获取验证码</div>
                <div class="obtainCode" v-else>{{messageTimer.count+"s后重新获取"}}</div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    export default {
        name: "lawyerMy",
        data() {
            return {
                toolData : [
                    {name:'修改信息',img:'/img/grzx_02.png',type:'2',},
                    {name:'修改密码',img:'/img/grzx_03.png',type:'3',},
                    {name:'意见反馈',img:'/img/grzx_04.png',type:'4',},
                    {name:'关于我们',img:'/img/grzx_05.png',type:'5',},
                ],
                userData : [],
                show: false,
                messageTimer: {
                    fun: null,
                    count: '',
                },
                Code : '',
                PassWord : '',

                memberDay : '',
                date : '',
                LawyerTel : '',
                UserName : '',
                UserImg : '',
                CompanyName : '',
                notLoggedIn : false,
                UserId : '',
                redirect : true,
                fun : null,
            };
        },
        methods :{
            modify : function(){
                var _this = this
                _this.send({
                    url : '/EditPassWord',
                    data: {LawyerTel : _this.LawyerTel,PassWord:_this.PassWord,Code:_this.Code},
                    success: function (res) {
                        _this.$toast.success('修改成功');
                    }
                })
            },
            verificationCode : function(){
                var _this = this
                _this.send({
                    url : '/GetCode',
                    data: {Tel : _this.LawyerTel},
                    success: function (res) {
                        _this.setReciprocal()
                    }
                })
            },
            setReciprocal: function () {
                var _this = this
                var TIME_COUNT = 60
                if (!_this.messageTimer.fun) {
                    _this.messageTimer.count = TIME_COUNT
                    _this.messageTimer.fun = setInterval(function () {
                        if (_this.messageTimer.count > 0 && _this.messageTimer.count <= TIME_COUNT) {
                            _this.messageTimer.count--
                        } else {
                            clearInterval(_this.messageTimer.fun)
                            _this.messageTimer.fun = null;
                        }
                    }, 1000)
                }
            },
            signOut : function(){
                var _this = this
                _this.send({
                    url : '/Logout',
                    data: {LawyerTel:_this.userData.LawyerTel,IsUser:_this.userData.IsUser,UserLogin:_this.userData.LawyerTel},
                    success: function (res) {
                        console.log(res)
                        window.sessionStorage.memberDay = ''
                        window.sessionStorage.date  = ''
                        window.sessionStorage.LawyerTel  = ''
                        window.sessionStorage.CompanyName  = ''
                        window.sessionStorage.LawyerName  = ''
                        window.sessionStorage.LawyerImg  = ''
                        window.sessionStorage.LawyerId  = ''
                        _this.$router.push({name: 'lawyerLogin',})
                        // _this.$toast.success('');
                    }
                })
            },
            onTool : function (url) {
                var _this = this
                if(url != ''){
                    _this.$router.push({name: url})
                }
            }
        },
        mounted () {
            this.userData = window.sessionStorage
            this.LawyerTel = window.sessionStorage.LawyerTel
        }
    }
</script>

