<style scoped>
    .templateDetails{background: #F7F6F9;}
    .return{font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #B1B8CC;line-height: 0.42rem;padding-top: 0.2rem;}
    .returnImg{width: 0.3rem;height: 0.26rem;margin:0.1rem 0.1rem 0 0.33rem;vertical-align: top;}
    .title{font-size: 0.34rem;font-family: PingFang SC;font-weight: bold;color: #444444;line-height: 0.42rem;margin:0.6rem 0 0.4rem}
    .collection{width: 0.84rem;height: 0.86rem;background: #FFFFFF;border: 0.01rem solid #0C83E4;border-radius: 0.04rem;vertical-align: top;font-size: 0.24rem;color: #0C83E4;text-align: center;font-family: PingFang SC;display: inline-block;}
    .collectionImg{width: 0.32rem;height: 0.30rem;margin: 0.12rem 0 0 0 }
    .sendOut{width: 2.8rem;height: 0.86rem;background: #0C83E4;border-radius: 0.04rem;display: inline-block;font-size: 0.30rem;color: #FFFFFF;
        line-height: 0.86rem;text-align: center;vertical-align: top;margin-left: 0.3rem}
    .sendOutImg{width: 0.30rem;height: 0.30rem;margin: 0.28rem 0.2rem 0 0;vertical-align: top;}
    .mailbox{width: 6rem;height: 3.78rem;background-color: white;position: fixed;top: 1.5rem;font-size: 0.3rem;text-align: center;left: 50%; margin-left: -3rem;z-index: 100}
    .sign{border-radius:0.45rem;width: 0.9rem;height: 0.9rem;border: 0.01rem solid rgb(221,221,221);position: absolute;top: -0.45rem;background-color: white;left: 50%;margin-left: -0.45rem;}
    .youxiang{width: 0.45rem;height: 0.36rem;vertical-align: top;margin-top: 0.27rem;}
    .cancel{width: 2.3rem;height: 0.72rem;line-height: 0.72rem;}
    .transparent{background-color: #000000;opacity: 0.5;width: 7.5rem;height: 100%;position: fixed;z-index: 99;top: 0;}
    .inputmailbox{width: 4.8rem;height: 0.8rem;border: 0.01rem solid rgb(221,221,221);margin-top: 0.4rem;margin-bottom: 0.31rem;font-size: 0.3rem;}
    table, th, td {
        border: 1px solid black;
    }
</style>

<template>
    <div class="templateDetails">
        <div class="return"><img class="returnImg" src="/img/fanhui.png" @click="$router.go(-1)">返回合同分类</div>
        <div class="center" >
            <div class="title">{{file.name}}</div>
            <div style="width: 6.12rem;padding: 0.11rem 0.38rem 0.02rem 0.38rem;background-color: #FFF;display: inline-block;overflow: hidden;font-size: 0.26rem;text-align: left">
                <div v-html="file.content"></div>
            </div>
        </div>
        <!--底部按钮-->
        <div style="position: fixed;bottom: 0.3rem;left: 0.2rem;">
            <div class="collection" @click="collectDTemplate()">
                <img src="/img/shoucang0.png" class="collectionImg" v-if="IsCollect == 1">
                <img src="/img/shoucang1.png" class="collectionImg" v-else>
                <div>收藏</div>
            </div>
            <div class="sendOut" @click="download('down')">
                <div class="inline-block">下载</div>
            </div>
            <div class="sendOut" @click="show = !show">
                <img src="/img/fasong.png" class="sendOutImg">
                <div class="inline-block">发送到邮箱</div>
            </div>
        </div>
        <!--发送邮箱弹出-->
        <div class="transparent" v-if="show" @click="show = !show"></div>
        <div class="mailbox" v-if="show">
            <div class="sign"><img src="/img/youxiang.png" class="youxiang"></div>
            <p style="color: rgb(0, 0, 0); margin-top: 0.7rem;">— 填写邮箱地址 —</p>
            <input type="text" id="aa" class="inputmailbox" v-model="inputmailbox">
            <div class="inline-block cancel" style="border: 0.01rem solid rgb(221, 221, 221); color: rgb(0, 0, 0);"
                 @click="show = !show">
                取消
            </div>
            <div class="inline-block cancel"
                 style="background-color: rgb(66, 129, 245); color: rgb(255, 255, 255); margin-left: 0.1rem;"
                 @click="download('send')">
                确定
            </div>
        </div>
        <div style="position: fixed;width: 100%;height: 100%;top:0;right: 0;background-color: rgba(0,0,0,0.4);z-index: 107;" v-if="load">
            <van-loading type="spinner" color="#1989fa" size="50px" style="text-align: center;position: absolute;top: 50%;left: 45%"></van-loading>
        </div>
        <notLoggedIn ref="notLoggedIn" v-if="notLoggedIn"  :loginShow="loginShow"/>
    </div>
</template>

<script>
    import notLoggedIn from '../../components/notLoggedIn.vue'
    export default {
        name: "templateDetails",
        components: {notLoggedIn},
        data() {
            return {
                kind: '2',
                file: '',
                show: false,
                inputmailbox: '',
                IsCollect: '',
                notLoggedIn : false,
                loginShow : '123',
                load : false,
            };
        },
        methods :{
            datahandover: function () {
                var id = this.$route.query.id;
                var _this = this
                _this.send({
                    url: '/doc_template',
                    data: {
                        kind: _this.kind,
                        id: id,
                        type :_this.$route.query.type,
                        EType  :_this.$route.query._type,
                        UserId:window.sessionStorage.UserId,
                    },
                    success: function (res) {
                        _this.file = res.list
                        _this.IsCollect = res.IsCollect
                    }
                })
            },
            download: function (type) {
                var _this = this
                _this.load = true
                if(window.sessionStorage.UserId == ""){
                    _this.notLoggedIn = true
                    _this.$refs.notLoggedIn.handleParentClick()
                }
                _this.send({
                    tool : true,
                    url: '/send_down',
                    data: {
                        kind: type,//下载
                        id: _this.file.id,//第几个数据页
                        emails: _this.inputmailbox,//邮箱内容element
                        UserId : window.sessionStorage.UserId,
                        type :_this.$route.query.type,
                        EType  :_this.$route.query._type,
                    },
                    success: function (res) {
                        _this.load = false
                        if (type == 'down') {
                            console.log(res)
                            window.location.href = 'https://manager.lvwa18.com:8002'+'/'+res.Data
                        }
                        if(res.Code == 305){
                            _this.$toast('发送成功，请注意查阅');
                            _this.show = !_this.show
                        }
                        if(res.Code == 100){
                            _this.$toast.fail('请输入正确的邮箱！');
                        }
                        if(res.Code == 303){
                            _this.$toast.fail('邮箱错误，请重新输入')
                        }
                    }
                })
            },
            collectDTemplate : function () {
                var _this = this
                if(window.sessionStorage.UserId == ""){
                    _this.notLoggedIn = true
                    _this.$refs.notLoggedIn.handleParentClick()
                }
                _this.send({
                    url: '/CollectDTemplate',
                    consulting : true,
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        id: _this.$route.query.id,
                        UserId : window.sessionStorage.UserId,
                        type :_this.$route.query.type,
                        EType  :_this.$route.query._type,
                    },
                    success: function (res) {
                        _this.datahandover()
                    }
                })
            }
        },
        mounted () {
            var _this = this
            this.datahandover()
            _this.$toast('本页仅供预览内容，排版会存在偏差。请下载或发送至邮箱后查看。')
        }
    }
</script>

