<template>
    <div class="notLoggedIn">
        <van-dialog v-model="show" title="" show-cancel-button :message="'该操作需登录后使用('+messageTimer.count+'s)'" confirmButtonText="去登录"
                    cancelButtonText="关闭" @confirm="determine" @cancel="cancel">

        </van-dialog>
    </div>
</template>

<script>
    import { Dialog } from 'vant';
    export default {
        name: "notLoggedIn",
        props: {
            loginShow : ''
        },
        data() {
            return {
                messageTimer: {
                    fun: null,
                    count: 3,
                },
                show: false,
            };
        },
        methods :{
            handleParentClick : function(){
                var _this = this
                _this.show = true
            },
            determine : function () {
                var _this = this
                _this.$router.push({name:'login'});
            },
            cancel : function () {
                var _this = this
                _this.show = false
                // if(window.history.length >= 1){
                //     _this.$router.go(-1)
                // }else {
                //     _this.$router.push({name: 'home'})
                // }
            }
        },
        mounted () {
            var _this = this
            console.log(_this.loginShow)
            if(window.sessionStorage.UserId == ''){
                _this.show = true
                _this.messageTimer.fun = setInterval(function () {
                    if (_this.messageTimer.count > 1) {
                        _this.messageTimer.count--
                    } else {
                        _this.$router.push({name: 'login'})
                        clearInterval(_this.messageTimer.fun)
                        // _this.messageTimer.fun = null;
                    }
                }, 1000)
                // Dialog.confirm({
                //     title: '',
                //     message: '登录失效,请重新登录',
                //     confirmButtonText : '去登录',
                //     cancelButtonText : '返回上一页',
                // }).then(() => {
                //     _this.$router.push({name:'login'});
                // }).catch(() => {
                //     if(window.history.length <= 1){
                //         _this.$router.go(-1)
                //     }else {
                //         _this.$router.push({name: 'home'})
                //     }
                // });
            }

        }
    }
</script>

<style scoped>
   .notLoggedIn >>> .van-dialog__footer{display: none}
</style>
