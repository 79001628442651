
import legalInspect from "../../views/activities/legal-inspect.vue"
import matchingLawFirm from "../../views/activities/matchingLawFirm.vue"
import basicInformation from "../../views/activities/basicInformation.vue"
import legalActivities from '../../views/navigation/legalActivities'

export default {
    legalInspect : legalInspect,
    matchingLawFirm : matchingLawFirm,
    basicInformation : basicInformation,
    legalActivities : legalActivities,
}
