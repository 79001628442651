<template>
    <div class="lawyerBottom">
        <div style="padding-bottom: 1.2rem;"></div>
        <van-tabbar v-model="active" @change="onChange">
            <van-tabbar-item v-for="(value,index) in icon" :key="index" :to="value.url" :badge="value.badge">
                <span>{{value.name}}</span>
                <img
                        slot="icon"
                        slot-scope="props"
                        :src="props.active ? value.active : value.inactive"
                >
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "lawyerBottom",
        props: {
            msg: '',
        },
        data() {
            return {
                active: 0,
                icon: [
                    {
                        active: '/img/bottom_01.png',
                        inactive: '/img/bottom_1.png',
                        name: '首页',
                        url: '/lawyer/lawyerHome',
                    },
                    {
                        active: '/img/bottom_02.png',
                        inactive: '/img/bottom_2.png',
                        name: '咨询记录',
                        url: '/lawyer/lawyerService',
                        badge : ''
                    },
                    {
                        active: '/img/bottom_03.png',
                        inactive: '/img/bottom_3.png',
                        name: '个人中心',
                        url: '/lawyer/lawyerMy'
                    },
                ]
            }
        },
        methods: {
            onChange(index) {
                window.sessionStorage.active = index
                // console.log(index)
            }
        },
        mounted () {
            var _this = this
            if(window.sessionStorage.active){
                _this.active = parseInt(window.sessionStorage.active)
            }
            if(window.sessionStorage.OrderCount){
                _this.icon[1].badge = window.sessionStorage.OrderCount || _this.msg
            }else{
                var fun = setInterval(function () {
                    if(_this.icon[1].badge == ''){
                        _this.icon[1].badge = window.sessionStorage.OrderCount
                    }else {
                        console.log(_this.icon[0].badge)
                        clearInterval(fun)
                    }
                }, 1000)
            }

            console.log(_this.msg)
        }
    }
</script>

<style scoped>
    .lawyerBottom >>> .van-tabbar{height: 1rem;font-size: 0.2rem;}
    .lawyerBottom >>> .van-tabbar-item__icon img{width: 0.48rem;height: 0.46rem}
</style>
