<template>
    <div>
        <div style="background-color: rgb(255, 255, 255); height: 0.88rem; text-align: center;position: fixed;top: 0;width: 100%;right: 0;z-index: 9;" v-if="equipment == 1">
            <img src="/img/return.png" class="return" @click="$router.go(-1)">
            <p class="head-title">律蛙法管家</p>
        </div>
        <div style="padding: 0.44rem" v-if="equipment == 1&&path != '/user/dialogue'"></div>
        <router-view/>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                equipment : 0,
                path : ''
            };
        },
        name: "usereEmpty",
        mounted () {
            var _this = this
            _this.path = _this.$route.path
            console.log(_this.$route.path)
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(isAndroid){
                console.log('是安卓');
                _this.equipment = 1
            }
            if(isiOS){
                _this.equipment = 2
                console.log('是ios');
            }
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'UserId') {
                        window.sessionStorage.UserId = arr2[1];
                    } else if(arr2[0] == 'UserToken') {
                        window.sessionStorage.UserToken = arr2[1];
                    }else if(arr2[0] == 'UserLogin') {
                        window.sessionStorage.UserLogin = arr2[1];
                    }else if(arr2[0] == 'openid') {
                        window.sessionStorage.openid = arr2[1];
                    }
                }
            }
            _this.send({
                url: '/InitUser',
                consulting : true,
                data: {
                    UserId : window.sessionStorage.UserId||'UserId',
                    UserToken : window.sessionStorage.UserToken||'',
                    IsUser : '2',
                    UserLogin : window.sessionStorage.UserLogin||'',
                    LawyerId : '',
                },
                success: function (res) {
                    if(res){
                        window.sessionStorage.IsUser = res.IsUser|| ''
                        window.sessionStorage.memberDay = res.memberDay|| ''
                        window.sessionStorage.LawyerLinkman = res.LawyerLinkman|| ''
                        window.sessionStorage.LawyerName = res.LawyerName|| ''
                        window.sessionStorage.streetName = res.streetName|| ''
                        window.sessionStorage.User_LawyerId = res.User_LawyerId|| ''
                        window.sessionStorage.date = res.date|| ''
                        window.sessionStorage.CompanyName = res.List.CompanyName|| ''
                        window.sessionStorage.Introduce = res.List.Introduce|| ''
                        window.sessionStorage.LastLogin = res.List.LastLogin|| ''
                        window.sessionStorage.Password = res.List.Password|| ''
                        window.sessionStorage.RegisterCode = res.List.RegisterCode|| ''
                        window.sessionStorage.RegisterTime = res.List.RegisterTime|| ''
                        window.sessionStorage.UserId = res.List.UserId|| ''
                        window.sessionStorage.UserImg = res.List.UserImg|| ''
                        window.sessionStorage.UserLogin = res.List.UserLogin|| ''
                        window.sessionStorage.UserName = res.List.UserName|| ''
                        window.sessionStorage.UserTel = res.List.UserTel|| ''
                        window.sessionStorage.status = res.List.status|| ''
                        window.sessionStorage.IsStatus = res.List.IsStatus|| ''
                        window.sessionStorage.LTel = res.List.LTel|| ''
                        window.sessionStorage.LName = res.List.LName|| ''
                        window.sessionStorage.LawyerTel = res.LawyerTel|| ''
                    }else {
                        window.sessionStorage.UserId = ''
                    }
                }
            })
            console.log(window.sessionStorage)
        },
    }
</script>

<style scoped>
.head-title{font-size: .36rem;color: #333;line-height: .88rem;display: inline-block;vertical-align: top;}
.return{width: .16rem;height: .3rem;display: inline-block;vertical-align: top;float: left;margin-top: .29rem;margin-left: .3rem;}
</style>
