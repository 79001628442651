<template>
    <div>
        <img src="/img/caseBanner.png" style="width:100%;height:2rem;">
        <div style="span-align: center;width: 7.5rem;">
            <van-search
                    v-model="value"
                    placeholder="输入关键词搜索"
                    input-align="center"
                    @input="onInput"
                    @clear="eliminate"
            />
        </div>
        <div>
            <div style="color: #009EDB;font-size: 0.26rem;line-height: 0.73rem;margin-left:0.30rem;">
                <span style="color: #000000;padding-right:0.30rem;" @click="onReturn">返回首页</span>
                <span style="color: #000000;">热门：</span>
                <span @click="hot('调解')" style="padding-right:0.30rem;">调解 </span>
                <span @click="hot('援助')" style="padding-right:0.30rem;">援助 </span>
                <span @click="hot('公证')" style="padding-right:0.30rem;">公证 </span>
                <span @click="hot('鉴定')" style="padding-right:0.30rem;">鉴定 </span>
            </div>
        </div>
        <div style="text-align: center;font-size: 0;">
            <div class="classification" @click="onClassification">
                <div style="margin-left: 0.20rem;display:inline-block;" v-if="Type">{{Type}} <span style="color: #F6EE08;">{{Count}}例</span></div>
                <div style="margin-left: 0.20rem;display:inline-block;" v-else>司法鉴定业务案例 <span style="color: #F6EE08;">{{Count}}例</span></div>
                <img class="xia" src="/img/xia.png"/>
            </div>
        </div>
        <div style="text-align: center;margin-top: 0.30rem;font-size: 0;">

            <div class="list" v-for="(value,index) in caseList" @click="caseDetails(value.ExternalArticleId)" >
                <div class="lists">{{index+1}}.
                    <span v-html="value.ExternalArticleTitle"></span>
                </div>
            </div>
        </div>
        <van-popup v-model="currentShow" position="bottom" close-icon="close" closeable>
            <div style="padding: 0.3rem"></div>
            <div @click="onEmpty" style="text-align:center;line-height: 0.70rem;height: 0.70rem;font-size:0.26rem;border-bottom: 0.01rem solid #ddd;">返回</div>
            <div v-for="(value,index) in choiceClassification" style="text-align:center;line-height: 0.70rem;height: 0.70rem;font-size:0.26rem;border-bottom: 0.01rem solid #ccc;"
                 @click="onChoiceClassification(value.ExternalArticleTypeId,value.ExternalArticleTypeTitle)">
                {{value.ExternalArticleTypeTitle}} <span style="color:red;">{{value.count}}例</span></div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "caseBase",
        data() {
            return {
                currentShow : false,
                currentShow1 : false,
                choiceClassification : [],
                choiceClassificationList : [],
                Type : '',
                caseList : [],
                Key : '',
                keySplit : '',
                pageSize : 20,
                page : 1,
                typei : false,
                Count : '',
                value : '',
            }
        },
        methods :{
            eliminate : function(){
                var _this = this
                _this.Key = ''
                _this.page = 1
                _this.caseList = []
                _this.caseArticleList()
            },
            onInput : function(e){
                var _this = this
                _this.Key = e
                _this.page = 1
                _this.caseList = []
                _this.caseArticleList()
            },
            caseArticleList : function(){
                var _this = this
                _this.send({
                    url: '/CaseArticleList',
                    data: {page: _this.page, pageSize: _this.pageSize,Key:_this.Key,Type:_this.Type},
                    success: function (res) {
                        // _this.caseList = res.List
                        for(var i in res.List){

                            let str = res.List[i].ExternalArticleTitle
                            let srtEndIndex = str.indexOf(_this.Key) + _this.Key .length;
                            let srtStartIndex = str.indexOf(_this.Key);
                            let newStr = str.substring(0, srtEndIndex) + "</text>" + str.substring(srtEndIndex);
                            newStr = newStr.substring(0, srtStartIndex) + '<text style="color:red">' + newStr.substring(srtStartIndex);
                            res.List[i].ExternalArticleTitle = newStr
                            _this.caseList.push(res.List[i])
                        }


                        _this.Count = res.Count
                    }
                })
            },
            onReturn : function () {
                this.$router.go(-1)
            },
            hot : function (name) {
                var _this = this
                _this.Key = name,
                _this.value = name,
                _this.caseList = []
                this.caseArticleList()
            },
            onClassification : function () {
                var _this = this
                _this.currentShow = !_this.currentShow
                if(_this.choiceClassificationList != []){
                    _this.caseArticleType()
                }
            },
            caseArticleType : function(){
                var _this = this
                _this.send({
                    url: '/CaseArticleType',
                    data: {},
                    success: function (res) {
                        _this.choiceClassification =res
                        _this.choiceClassificationList = res
                    }
                })
            },
            onChoiceClassification : function(index,name){
                var _this = this
                console.log(index)
                if(_this.typei == false){
                    _this.choiceClassification=[],
                        _this.currentShow=true
                    _this.choiceClassification = _this.choiceClassificationList[index].list,
                        _this.typei = true
                }else{
                    _this.typei=false
                    _this.currentShow=false
                    _this.Type=name
                    _this.caseList = []
                    _this.choiceClassification=[]
                    _this.choiceClassificationList=[]
                    _this.caseArticleList()
                }
            },
            caseDetails : function (type) {
                var _this = this
                _this.$router.push({name: 'caseBaseDetails', query: {type: type}})
            },
            onEmpty : function () {
                var _this = this
                _this.Type = '',
                _this.caseList = [],
                _this.page = 1,
                _this.currentShow = false
                _this.caseArticleList()
            },
        },
        mounted () {
            var _this = this
            _this.caseArticleList()
        },
        created() {
            let that = this;
            window.onscroll = function () {
                // scrollTop 滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                // windowHeight 可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // scrollHeight 滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                // 滚动条到底部的条件
                if (scrollTop + windowHeight+5 >= scrollHeight) {
                    // 加载数据
                    that.page++
                    that.caseArticleList();
                }
            }
        },
    }
</script>

<style scoped>
    page{background-color: #f7f8fa;}
    .search{background-color: #FFF;padding-left: var(--padding-xs,20rem);margin-left: -0.20rem;}
    .inputSearch{padding-left: 0.20rem;}
    .classification{width: 6.90rem;height: 0.70rem;display: inline-block;
        background: #009EDB;color: #FFFFFF;line-height: 0.70rem;font-size: 0.26rem;text-align: left;margin-top: 0.10rem;}
    .xia{float: right;margin: 0.29rem 0.20rem 0 0;width: 0.22rem;height: 0.12rem;}
    .list{width: 6.90rem;border-bottom:0.01rem solid #DDDDDD;height: 0.92rem;line-height: 0.92rem;display: inline-block;text-align: left;background-color: #FFF;font-size: 0.26rem;}
    .lists{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin: 0 0.23rem;color: #333;font-size: 0.26rem;}


    .black{

    }
    .active{
        color:red
    }
</style>
