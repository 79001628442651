import axios from 'axios'
import merge from 'deepmerge'

var config = SYSCONFIG
var key = 0
export default {
    install(Vue) {
        //请求发送封装
        Vue.prototype.send = function (_option) {
            var _this = this
            var version=""
            var _url = ""
            var _default = {}
            if(_option.server==undefined){
                _option.server = 'default'
            }
            if(_option.version!=undefined){
                version = _option.version
            }else{
                version = config.server[_option.server].version
            }
            if(config.server[_option.server].port=='443'){
                _url += 'https://'
            }else{
                _url += 'https://'
            }

            _url += config.server[_option.server].host + ":" + config.server[_option.server].port
            if(_option.server==''){
                // default
                _option.headers = _option.headers.split('/')
                var headers = {
                    Con:_option.headers[0],
                    Fun:_option.headers[1],
                }
                _option.headers = headers
                _url += config.server[_option.server].src
                _default = {
                    url: _url,
                    method: "post",
                    confirm:false,
                    loading:false,
                    warningTips:true,
                    warning:function(response){
                        if(option.warningTips==true&&response.data.Code!="504"){
                            _this.$Notice.warning({
                                title:'系统提示',
                                desc:response.data.Message,
                            })
                        }
                    },
                    headers:config.server[_option.server].data,
                    params:{},
                    data:{
                        pageSize:999999,
                        UserId:window.sessionStorage.userId||'',
                        MenuId: window.sessionStorage.menuId||1,
                        admin:1,
                    },
                    success:function (res) {
                        return true
                    },
                    before:function () {
                        if(option.confirm!=false){
                            _this.$Modal.confirm({
                                title:'系统消息',
                                content:'是否确认当前操作？',
                                onOk:function () {
                                    send()
                                },
                                onCancel:function () {

                                }
                            })
                        }else{
                            send()
                        }
                    },
                    error:function (error) {
                        _this.$Notice.error({
                            title:'系统提示',
                            desc:'网络繁忙，请稍后再试！',
                        })
                        console.log(error)
                    }
                }
            }else{
                _url += "" + version
                _default = {
                    url: _url,
                    method: "post",
                    confirm:false,
                    loading:true,
                    headers:{},
                    params:config.defaultData,
                    data:config.defaultData,
                    success:function (res) {
                        return true
                    },
                    before:function () {
                        if(option.confirm!=false){
                            _this.$Modal.confirm({
                                title:'系统消息',
                                cancelText : '取消',
                                content:'是否确认当前操作？',
                                onOk:function () {
                                    send()
                                },
                                onCancel : function () {

                                }
                            })
                        }else{
                            send()
                        }
                    },
                    error:function (error) {


                        console.log(error)
                    }
                }
            }
            if(_option.url!=undefined){
                _option.url = _default.url + _option.url
            }else{
                _option.url = _default.url
            }
            var option = merge( _default, _option)
            if(option.method.toUpperCase()=='GET'){
                option.params = option.data
            }
            option.before()
            function send() {
                var loading = setTimeout(function () {

                },500)
                axios({
                    method: option.method,
                    headers:option.headers,
                    url: option.url,
                    data: option.data,
                    params: option.params,
                }).then(function (response) {
                    clearTimeout(loading)
                    if(_option.consulting == true){
                        option.success(response.data.Data)
                        return
                    }
                    if(_option.tool == true){
                        option.success(response.data)
                        return
                    }
                    if(response.data.Success==1){
                        option.success(response.data.Data)
                    }else{
                        _this.$toast(response.data.Message);
                    }
                }).catch(function (error) {
                    clearTimeout(loading)

                    option.error(error)
                    if(config.errorLog.state){//记录错误日志
                        var errorTemp = error
                        axios({
                            method:"post",
                            url:config.errorLog.server.url+":"+config.errorLog.server.port+"/"+config.errorLog.server.src,
                            data:errorTemp,
                        })
                    }
                });
            }
        }
        //获取服务器地址
        Vue.prototype.getServerURL = function(name,src){
            var _url = ''
            if(config.server[name].port=='443'){
                _url+='https://'
            }else{
                _url+='https://'
            }
            _url += config.server[name].host
            if(config.server[name].port!=''){
                _url += ':'+config.server[name].port
            }
            if(src){
                _url += config.server[name].src
            }
            if(config.server[name].version!=''){
                _url += '/'+config.server[name].version
            }
            return _url
        },
            //数字大写转换
            Vue.prototype.ATS = function (Num) {
                //阿拉伯数字转换为简写汉字
                for (var i = Num.length - 1; i >= 0; i--) {
                    Num = Num.replace(",", "")//替换Num中的“,”
                    Num = Num.replace(" ", "")//替换Num中的空格
                }
                if (isNaN(Num)) { //验证输入的字符是否为数字
                    //alert("请检查小写金额是否正确");
                    return Num;
                }
                //字符处理完毕后开始转换，采用前后两部分分别转换
                var part = String(Num).split(".");
                var newchar = "";
                //小数点前进行转化
                for (var i = part[0].length - 1; i >= 0; i--) {
                    if (part[0].length > 10) {
                        //alert("位数过大，无法计算");
                        return "";
                    }//若数量超过拾亿单位，提示
                    var tmpnewchar = ""
                    var perchar = part[0].charAt(i);
                    switch (perchar) {
                        case "0":  tmpnewchar = "零" + tmpnewchar;break;
                        case "1": tmpnewchar = "一" + tmpnewchar; break;
                        case "2": tmpnewchar = "二" + tmpnewchar; break;
                        case "3": tmpnewchar = "三" + tmpnewchar; break;
                        case "4": tmpnewchar = "四" + tmpnewchar; break;
                        case "5": tmpnewchar = "五" + tmpnewchar; break;
                        case "6": tmpnewchar = "六" + tmpnewchar; break;
                        case "7": tmpnewchar = "七" + tmpnewchar; break;
                        case "8": tmpnewchar = "八" + tmpnewchar; break;
                        case "9": tmpnewchar = "九" + tmpnewchar; break;
                    }
                    switch (part[0].length - i - 1) {
                        case 0: tmpnewchar = tmpnewchar; break;
                        case 1: if (perchar != 0) tmpnewchar = tmpnewchar + "十"; break;
                        case 2: if (perchar != 0) tmpnewchar = tmpnewchar + "百"; break;
                        case 3: if (perchar != 0) tmpnewchar = tmpnewchar + "千"; break;
                        case 4: tmpnewchar = tmpnewchar + "万"; break;
                        case 5: if (perchar != 0) tmpnewchar = tmpnewchar + "十"; break;
                        case 6: if (perchar != 0) tmpnewchar = tmpnewchar + "百"; break;
                        case 7: if (perchar != 0) tmpnewchar = tmpnewchar + "千"; break;
                        case 8: tmpnewchar = tmpnewchar + "亿"; break;
                        case 9: tmpnewchar = tmpnewchar + "十"; break;
                    }
                    newchar = tmpnewchar + newchar;
                }
                //替换所有无用汉字，直到没有此类无用的数字为止
                while (newchar.search("零零") != -1 || newchar.search("零亿") != -1 || newchar.search("亿万") != -1 || newchar.search("零万") != -1) {
                    newchar = newchar.replace("零亿", "亿");
                    newchar = newchar.replace("亿万", "亿");
                    newchar = newchar.replace("零万", "万");
                    newchar = newchar.replace("零零", "零");
                }
                //替换以“一十”开头的，为“十”
                if (newchar.indexOf("一十") == 0) {
                    newchar = newchar.substr(1);
                }
                //替换以“零”结尾的，为“”
                if (newchar.lastIndexOf("零") == newchar.length - 1) {
                    newchar = newchar.substr(0, newchar.length - 1);
                }
                return newchar;
            }
        //日期大小写转换
        Vue.prototype.numToCN = function (s) {
            var sCN = '〇一二三四五六七八九';
            function getCN(s) {
                var c = s.charAt(0), cCN = sCN.charAt(c);
                return s.length == 1 ? cCN : (c == '1' ? '' : cCN) + '十' + sCN.charAt(s.charAt(1))
            }
            return s.replace(/(\d+)([月日])/g, function ($0, $1, $2) {
                return getCN($1) + $2;
            }).replace(/\d/g, function ($0) { return sCN.charAt($0) });
        }
    }
}
