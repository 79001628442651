import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import home from '../views/home.vue'
import lawyerLogin from '../views/lawyerLogin.vue'
import register from '../views/register.vue'
import caseDetails from '../views/caseDetails.vue'

import enterprise from '../router/childRoute/user'
import lawyer from '../router/childRoute/lawyer'
import activities from '../router/childRoute/activities'

//法律线上预约
Vue.use(VueRouter)

const routes = [
  {
    path: '/user',
    component: enterprise.userNavigation,
    children:[
      {path: 'home', name: 'home', component: enterprise.home,meta:{title:'企业法治体检'}},
      {path: 'serviceRecord', name: 'serviceRecord', component: enterprise.serviceRecord,meta:{title:'企业法治体检'}},
      {path: 'my', name: 'my', component: enterprise.my,meta:{title:'企业法治体检'}},
      {path: 'service', name: 'service', component: enterprise.service,meta:{title:'企业法治体检'}},
    ],
  },
  {
    path: '/user',
    component: enterprise.usereEmpty,
    children:[
      {path: 'dialogueList', name: 'dialogueList', component: enterprise.dialogueList,meta:{title:'企业法治体检'}},
      {path: 'dialogue', name: 'dialogue', component: enterprise.dialogue,meta:{title:'企业法治体检'}},
      {path: 'template', name: 'template', component: enterprise.template,meta:{title:'企业法治体检'}},
      {path: 'templateDetails', name: 'templateDetails', component: enterprise.templateDetails,meta:{title:'企业法治体检'}},
      {path: 'newOrder', name: 'newOrder', component: enterprise.newOrder,meta:{title:'企业法治体检'}},
      {path: 'record', name: 'record', component: enterprise.record,meta:{title:'企业法治体检'}},
      {path: 'recharge', name: 'recharge', component: enterprise.recharge,meta:{title:'企业法治体检'}},
      {path: 'userInformation', name: 'userInformation', component: enterprise.userInformation,meta:{title:'企业法治体检'}},
      {path: 'caseBase', name: 'caseBase', component: enterprise.caseBase,meta:{title:'企业法治体检'}},
      {path: 'caseBaseDetails', name: 'caseBaseDetails', component: enterprise.caseBaseDetails,meta:{title:'企业法治体检'}},
      {path: 'opinion', name: 'opinion', component: enterprise.opinion,meta:{title:'企业法治体检'}},
      {path: 'agreement', name: 'agreement', component: enterprise.agreement,meta:{title:'企业法治体检'}},
      {path: 'voice', name: 'voice', component: enterprise.voice,meta:{title:'企业法治体检'}},
    ],
  },
  {
    path: '/lawyer',
    component: lawyer.lawyerNavigation,
    children:[
      {path: 'lawyerHome', name: 'lawyerHome', component: lawyer.lawyerHome,meta:{title:'企业法治体检'}},
      {path: 'orderRecords', name: 'orderRecords', component: lawyer.orderRecords,meta:{title:'企业法治体检'}},
      {path: 'lawyerMy', name: 'lawyerMy', component: lawyer.lawyerMy,meta:{title:'企业法治体检'}},
      {path: 'lawyerService', name: 'lawyerService', component: lawyer.lawyerService,meta:{title:'企业法治体检'}},
    ],
  },
  {
    path: '/lawyer',
    component: lawyer.lawyerEmpty,
    children:[
      // {path: 'caseDetails', name: 'caseDetails', component: lawyer.caseDetails,meta:{title:'企业法治体检'}},
      {path: 'lawyerDialogue', name: 'lawyerDialogue', component: lawyer.lawyerDialogue,meta:{title:'企业法治体检'}},
      {path: 'serviceUser', name: 'serviceUser', component: lawyer.serviceUser,meta:{title:'企业法治体检'}},
    ],
  },
  {path: '/caseDetails', name: 'caseDetails', component: caseDetails,meta:{title:'企业法治体检'}},
  {path: '/login', name: 'login', component: login,meta:{title:'企业法治体检'}},
  {path: '/', name: 'home', component: home,meta:{title:'企业法治体检'}},
  {path: '/lawyerLogin', name: 'lawyerLogin', component: lawyerLogin,meta:{title:'企业法治体检'}},
  {path: '/register', name: 'register', component: register,meta:{title:'企业法治体检'}},
  //

  {
    path: '/activities/cdwh',
    component: activities.legalActivities,
    children:[
      {path: 'legal-inspect', name: 'legal-inspect', component: activities.legalInspect,meta:{title:'企业法治体检'}},
      {path: 'matchingLawFirm', name: 'matchingLawFirm', component: activities.matchingLawFirm,meta:{title:'企业法治体检'}},
      {path: 'basicInformation', name: 'basicInformation', component: activities.basicInformation,meta:{title:'企业法治体检'}},
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
