<style scoped>
    .lawyerHome{background-color: #F7F6F9}
    .dialogueNumber{width: 7.5rem;height: 1.12rem;background: #FEFEFE;line-height: 1.12rem}
    .newOrder{width: 1.68rem;height: 0.52rem;background-color: #0C83E4;border-radius: 0.26rem;text-align: left;font-size: 0.22rem;line-height: 0.52rem;color: #FFF;display: inline-block;float: right;vertical-align: top;margin: 0.3rem 0.3rem 0 0;}
    /*.addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;}*/
    .addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;font-size:0.3rem;}

    .headPortrait{position: relative;height:100%;display: inline-block;}
    .informationList{width: 6.9rem;height: 1.5rem;background-color: #FFF;margin-top: 0.3rem;display: inline-block;text-align: left;position: relative}
    .tips{width: 0.30rem;height: 0.30rem;background: #EB1818;border-radius: 50%;position: absolute;font-size: 0.24rem;color: #FFF;text-align: center;top: 0.32rem;right: 0}
    .lawName{font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.32rem;vertical-align: top;margin: 0.37rem 0 0.2rem 0rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .content{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .time{font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;float: right;margin: 0.43rem 0.3rem 0 0}
    .newUnread{width: 0.15rem;height: 0.15rem;background-color: red;border-radius: 0.2rem;position: absolute;top: 0.32rem;left: 1.08rem;}

</style>

<template>
    <div class="lawyerHome">
        <div class="dialogueNumber">
            <div style="color: #666666;font-size: 0.24rem;margin-left: 0.3rem;display: inline-block;vertical-align: top;">
                您目前有<span style="color: #0C83E4;font-size: 0.24rem">{{Count}}</span>条咨询
            </div>
        </div>
        <div style="text-align: center;font-size: 0">
            <img style="width: 7.2rem;height: 2.4rem" src="/img/banner03.png">
        </div>
        <div class="center font-size">
            <div class="informationList" v-for="(value,index) in dialogueList" @click="onDialogue(value.ContactId,value.UserId)">
                <div class="headPortrait">
                    <img v-if="value.UserImg" :src="value.UserImg" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem;border-radius: 0.9rem">
                    <img v-else="" src="/img/enterprise.png" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem">
                    <!--<div class="tips">3</div>-->
                </div>
                <div class="inline-block" style="max-width: 2.28rem;margin-left: 0.3rem;vertical-align: top">
                    <div class="lawName">{{value.UserName}}</div>
                    <div class="content" v-if="value.lastContent.content">
                        <span v-if="value.lastContent.type == 'word'">{{value.lastContent.content}}</span>
                        <span v-if="value.lastContent.type == 'jpg'">[图片]</span>
                        <span v-if="value.lastContent.type == 'file'">[文件]</span>
                    </div>
                    <div class="newUnread" v-if="value.LawyerStatus == 1"></div>

                </div>
                <div class="inline-block time">{{value.lastContent.sendTime}}</div>
            </div>
            <img src="/img/nothing.png" v-if="noOrder" style="width: 7.26rem;height: 3.98rem">
        </div>
        <img src="/img/enterpriseList.png" style="width: 2.64rem;height: 1.48rem;position: fixed;bottom: 1.2rem;right: 0.4rem" @click="onTool">
    </div>
</template>

<script>
    export default {
        name: "lawyerHome",
        data() {
            return {
                page : '1',
                pageSize : '10',
                Count : 0,
                dialogueList : [],
                temp : '1',
                IsUser : '',
                UserLogin : '',
                UserToken : '',
                UserId : '',
                LawyerId : '',
                noOrder : false
            };
        },
        methods :{
            onTool : function () {
                var _this = this
                _this.$router.push({name: 'serviceUser'})
            },
            onDialogue : function(ContactId,UserId){
                var _this = this
                _this.$router.push({name: 'lawyerDialogue', query: {ContactId:ContactId,UserId:UserId}})
            },
            lawyerOrderList  : function () {
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/LawyerOrderList',
                    data: {
                        UserLogin : _this.UserLogin,
                        UserToken : _this.UserToken,
                        IsUser  : _this.IsUser,
                        UserId : _this.UserId,
                        LawyerId : _this.LawyerId,
                        page : _this.page,
                        pageSize : _this.pageSize,
                        temp : _this.temp
                    },
                    success: function (res) {
                        if(res){
                            _this.dialogueList = res.data
                            _this.Count = res.count
                        }else{
                            if(_this.dialogueList == ''){
                                _this.noOrder = true
                            }
                        }
                    }
                })
            }
        },
        mounted () {
            var _this = this
            if(window.sessionStorage.IsUser == undefined){
                var fun = setInterval(function () {
                    if(_this.IsUser == ''){
                        _this.IsUser = window.sessionStorage.IsUser
                        _this.UserLogin = window.sessionStorage.UserLogin
                        _this.UserToken = window.sessionStorage.UserToken
                        _this.UserId = window.sessionStorage.UserId
                        _this.LawyerId = window.sessionStorage.LawyerId
                    }else {
                        console.log(_this.IsUser)
                        _this.lawyerOrderList()
                        clearInterval(fun)
                    }
                }, 1000)
            }else{
                _this.IsUser = window.sessionStorage.IsUser
                _this.UserLogin = window.sessionStorage.UserLogin
                _this.UserToken = window.sessionStorage.UserToken
                _this.UserId = window.sessionStorage.UserId
                _this.LawyerId = window.sessionStorage.LawyerId
                _this.lawyerOrderList()
            }
        }
    }
</script>

