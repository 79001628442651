import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import global from '../public/js/global'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(global)
Vue.use(Vant);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'printReportShowQuery') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
