import lawyerHome from '../../views/lawyer/lawyerHome.vue'
import orderRecords from '../../views/lawyer/orderRecords.vue'
import lawyerMy from '../../views/lawyer/lawyerMy.vue'
import lawyerService from '../../views/lawyer/lawyerService.vue'
import lawyerDialogue from '../../views/lawyer/lawyerDialogue.vue'
import serviceUser from '../../views/lawyer/serviceUser.vue'
import lawyerNavigation from '../../views/navigation/lawyerNavigation'
import lawyerEmpty from '../../views/navigation/lawyerEmpty'

export default {
    lawyerHome:lawyerHome,
    orderRecords:orderRecords,
    lawyerMy:lawyerMy,
    lawyerService:lawyerService,
    lawyerDialogue:lawyerDialogue,
    serviceUser:serviceUser,
    lawyerNavigation:lawyerNavigation,
    lawyerEmpty:lawyerEmpty,
}
