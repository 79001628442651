<style scoped>
    .newOrder{background: #F7F6F9;}
    .title{width: 7.5rem;font-size: 0.3rem;text-align: left;height: 1.49rem;font-family: PingFang SC;font-weight: bold;}
    .category{height: 0.84rem;line-height: 0.84rem;font-weight: bold;color: #666666;font-size: 0.26rem;text-align: left}
    .choiceFalse{width: 6.9rem;min-height: 1rem;border: 0.01rem solid #D6DDE2;display: inline-block;text-align: left;margin-bottom: 0.3rem;
        }
    .choiceTrue{width: 6.89rem;min-height: 0.99rem;border: 0.02rem solid #0C83E4;display: inline-block;text-align: left;margin-bottom: 0.3rem;
        }

    .circularFalse{border: 0.05rem solid #D6DDE2;border-radius: 0.3rem;width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.35rem 0.33rem 0 0.21rem;display: inline-block;text-align: center}
    .smallCircleFalse{width: 0.2rem;height: 0.2rem;background-color: #D6DDE2;border-radius: 0.2rem;text-align: center;display: inline-block;vertical-align:top;margin-top: 0.047rem;}
    .circularTrue{border: 0.05rem solid #0C83E4;border-radius: 0.3rem;width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.35rem 0.33rem 0 0.21rem;display: inline-block;text-align: center}
    .smallCircleTrue{width: 0.2rem;height: 0.2rem;background-color: #0C83E4;border-radius: 0.2rem;text-align: center;display: inline-block;vertical-align:top;margin-top: 0.047rem;}
    .categoryImg{width: 0.59rem;height: 0.66rem;margin: 0.22rem 0.19rem 0 0.22rem;vertical-align: top;}
    .categoryName{font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 1.08rem;display: inline-block;vertical-align: top;}
    .xsmf{width: 1.46rem;height: 0.52rem;float: right;margin: 0.24rem 0.2rem 0 0}
    .cost{font-size: 0.28rem;font-weight: bold;color: #CD3112;display: inline-block;vertical-align: top;line-height: 1rem;font-family: PingFang SC;font-weight: bold;margin-left: 0.22rem}
    .addfile{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.26rem 0.19rem 0 0;}
    .addfileName{font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;color: #BFC4D6;line-height: 0.85rem;display: inline-block;vertical-align: top;margin-right: 0.3rem;}
    .upload{font-weight: bold;color: #666666;font-size: 0.26rem;text-align: left;background-color: #FFF;margin-top: 0.38rem}
    .fileList{width: 6.9rem;height: 0.86rem;border: 0.01rem solid #D6DDE2;display: inline-block;text-align: left;margin-bottom: 0.3rem;}
    .fileName{white-space: nowrap;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #0C83E4;line-height: 0.86rem;margin: 0 0.3rem 0 0.21rem;display: inline-block;max-width: 4rem;overflow: hidden;}
    .preview{width: 0.86rem;height: 0.46rem;background: #FFFFFF;border: 0.01rem solid #D6DDE2;border-radius: 0.23rem;font-size: 0.22rem;color: #BFC4D6;line-height: 0.46rem;text-align: center;display: inline-block;vertical-align: top;margin-top: 0.2rem}
    .close{width: 0.3rem;height: 0.3rem;float: right;margin: 0.28rem 0.31rem 0 0}
    .describe{width: 6.48rem;height: 1.58rem;background: #FFFFFF;border: 0.01rem solid #D6DDE2;font-size: 0.26rem;color: #373C40;padding: 0.21rem;font-family: PingFang SC;font-weight: 500;}
    .payment{width: 7.5rem;text-align: center;height: 1rem;background: #0C83E4;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #F7F6F9;line-height: 1rem;position: fixed;bottom: 0;}
    .subjectMatter{font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #0C83E4;line-height: 0.48rem;margin: 0 .19rem 0 0.29rem;display: inline-block}
    .subjectMatterInput{width: 5.64rem;height: 0.76rem;background: #FFFFFF;border: 0.01rem solid #D6DDE2;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #373C40;line-height: 0.3rem;padding-left: 0.12rem}
    .hint{font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;color: #E71717;margin-left: 1.44rem;
    line-height: 0.73rem}
    .tips{font-size: 0.26rem;text-indent: 1.6em;margin: 0 0.3rem 0.2rem;}
    .stepImg{width: 0.8rem;height: 0.8rem;margin: 0.28rem 0.2rem 0 0.2rem;}
    .paymentBottom{width: 1.46rem;
        height: 0.54rem;
        background: #EDCE00;
        border-radius: 0.04rem;
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 0.54rem;text-align: center;float: right;margin: 0.28rem 0.22rem 0 0;}
</style>

<template>
    <div class="newOrder">
        <div style="background-color: #FFF">
            <div class="title">
                <img class="stepImg" src="/img/bz_01.png">
                <div style="display: inline-block;font-size: 0.24rem;vertical-align: top;">
                    <div style="line-height: 0.24rem;margin: 0.38rem 0 0.13rem 0;color: #333">第1步</div>
                    <div style="line-height: 0.24rem;color: #999">支付费用</div>
                </div>
                <img src="/img/bz_00.png" style="width:2.57rem;height: 0.21rem;vertical-align: top;margin: 0.6rem 0.2rem 0;">
                <img class="stepImg" src="/img/bz_01.png">
                <div style="display: inline-block;font-size: 0.24rem;vertical-align: top;color: #333">
                    <div style="line-height: 0.24rem;margin: 0.38rem 0 0.13rem 0">第2步</div>
                    <div style="line-height: 0.24rem;color: #999">上传文件</div>
                </div>
            </div>
            <div style="padding: 0.15rem;background-color: #F7F6F9;"></div>
            <div class="center">
                <div class="category"><div style="margin-left: 0.3rem">支付费用</div></div>
                <div :class="[orderData.OrderTypeId == value.OrderTypeId?'choiceTrue':'choiceFalse']" v-for="(value,index) in categoryList" @click="onChoice(value.OrderTypeId)" v-if="type&&value.OrderTypeId == orderData.OrderTypeId">
                    <!--<div :class="[orderData.OrderTypeId == value.OrderTypeId?'circularTrue':'circularFalse']">-->
                        <!--<div :class="[orderData.OrderTypeId == value.OrderTypeId?'smallCircleTrue':'smallCircleFalse']"></div>-->
                    <!--</div>-->
                    <img class="categoryImg" :src="'/img/icon_0'+index+'.png'">
                    <div class="categoryName">{{value.OrderName}}
                        <div style="color: #CD3112;display: inline-block" v-if="value.OrderTypePrice">{{value.OrderTypePrice}}元/1次</div>
                    </div>
                    <!--<div class="paymentBottom" v-if="orderData.OrderTypeId == 6" @click="submit()">提交订单</div>-->
                    <div class="paymentBottom" v-if="orderData.OrderTypeId != 6"  @click="submit()">支付</div>
                    <div class="tips" v-if="value.OrderTypeId == orderData.OrderTypeId">{{value.OrderContent}}</div>
                </div>
                <div :class="[orderData.OrderTypeId == value.OrderTypeId?'choiceTrue':'choiceFalse']" v-for="(value,index) in categoryList" @click="onChoice(value.OrderTypeId)" v-if="!type">
                    <div :class="[orderData.OrderTypeId == value.OrderTypeId?'circularTrue':'circularFalse']">
                        <div :class="[orderData.OrderTypeId == value.OrderTypeId?'smallCircleTrue':'smallCircleFalse']"></div>
                    </div>
                    <img class="categoryImg" :src="'/img/icon_0'+index+'.png'">
                    <div class="categoryName">{{value.OrderName}}
                        <div style="color: #CD3112;display: inline-block" v-if="value.OrderTypePrice">{{value.OrderTypePrice}}元/1次</div>
                    </div>
                    <div class="tips" v-if="value.OrderTypeId == orderData.OrderTypeId">{{value.OrderContent}}</div>
                </div>
            </div>
            <div style="display: inline-block;font-size: 0;vertical-align: top;margin-top: 0.1rem"  v-if="orderData.OrderTypeId == 6">
                <div class="subjectMatter">标的金额</div>
                <input class="subjectMatterInput" placeholder="请输入标的金额" v-model="orderData.OrderCost"/>
                <!--<div class="hint">收费不超过(案件涉及金额*10%+案件复杂情况附加费)</div>-->
            </div>
            <div style="font-size: 0.22rem;font-family: PingFang SC;font-weight: bold;margin: 0 0.2rem;padding-bottom: 0.2rem;color: #A3A8B8">
                <div v-if="orderData.OrderTypeId == 6" style="color: red">说明：案件收费为标的金额的10%，如有特殊情况，将加收最低不低于2000元</div>
            </div>
        </div>
        <div class="center upload"  v-if="orderData.OrderTypeId != 1">
            <div class="inline-block" style="width: 50%"><div style="margin-left: 0.3rem;line-height: 0.85rem;">上传文件</div></div>
            <van-uploader :after-read="afterRead" accept="" delete="deletefile" style="float: right;" class="inline-block" multiple="true">
                <img src="/img/addfile.png" class="addfile">
                <div class="addfileName">添加文件</div>
            </van-uploader>
            <div class="center">
                <div class="fileList" v-for="(value,index) in orderData.fileData">
                    <div class="fileName">{{value.name}}</div>
                    <div class="preview" @click="previewImg(value.img)">预览</div>
                    <img src="/img/close.png" class="close" @click="deleteArray(index)">
                </div>
            </div>
        </div>
        <div class="center upload" v-if="orderData.OrderTypeId != 1">
            <!--<div class="category"><div style="margin-left: 0.3rem">语音描述</div></div>-->
            <div class="inline-block" style="width: 50%"><div style="margin-left: 0.3rem;line-height: 0.85rem;">语音描述</div></div>
            <!--<Button  style="float: right;margin: 0.2rem 0.2rem 0 0;padding: 0.1rem;background-color: #FFF;border:0.01rem solid #ccc;font-size: 0.2rem"-->
                     <!--type="info" @touchstart="getTouchStart($event)" @touchend="getTouchEnd">长按录音</Button>-->
            <div style="float: right;">
                <MRecorder @handleStop="handelEndRecord" />
            </div>

            <div class="center">
                <div class="fileList" v-for="(value,index) in orderData.voiceData">
                    <audio controls style="margin: 0.14rem 0 0 0.2rem;width: 4.5rem;height: 0.6rem">
                        <source :src="value.mp3" type="audio/mpeg">
                        您的浏览器不支持 audio 元素。
                    </audio>
                    <img src="/img/close.png" class="close" @click="voicedeleteArray(index)">
                </div>
            </div>
        </div>
        <div class="center" style="background-color: #FFF;margin-top: 0.3rem" v-if="orderData.OrderTypeId != 1">
            <div class="category"><div style="margin-left: 0.3rem">情况描述</div></div>
            <textarea class="describe" placeholder="请输入内容" v-model="orderData.OrderDetails"></textarea>
        </div>
        <div style="padding: 0.5rem"></div>
        <!--<div class="payment" v-if="orderData.OrderTypeId == 1 && memberDay != ''" @click="submit()">去咨询</div>-->
        <!--<div class="payment" v-else-if="orderData.OrderTypeId == 6" @click="submit()">提交订单</div>-->
        <!--<div class="payment" v-else  @click="submit()">支付</div>-->
        <div class="payment" v-if="orderData.OrderTypeId == 6" @click="submit()">提交订单</div>
        <div class="payment" v-else @click="submitFile()">提交</div>
        <div style="position: fixed;width: 100%;height: 100%;top:0;right: 0;background-color: rgba(0,0,0,0.4);" v-if="load">
            <van-loading type="spinner" color="#1989fa" size="50px" style="text-align: center;position: absolute;top: 50%;left: 45%"></van-loading>
        </div>
        <div style="position: fixed;top: 5rem;margin-left: -0.75rem;left: 50%;background-color: rgba(0,0,0,0.4);width: 1.5rem;height: 1.5rem;border-radius: 0.2rem;text-align: center" v-if="duration">
            <van-icon name="volume-o" color="#FFF" style="margin-top: 0.25rem;"/>
        </div>
        <notLoggedIn ref="notLoggedIn" v-if="notLoggedIn"/>
    </div>
</template>

    <script>
        import axios from 'axios'
        import wx from 'weixin-js-sdk'
    import { ImagePreview } from 'vant';
    import notLoggedIn from '../../components/notLoggedIn.vue'
        import MRecorder from '../../components/mRecorder.vue'
    export default {
        name: "newOrder",
        components: {notLoggedIn,MRecorder},
        data() {
            return {
                categoryList  : [],
                orderData :{
                    OrderTypeId : '',
                    voiceData : [],
                    fileData : [
                        // {img: "https://manager.lvwa18.com:8002/AllImg/1616724285.jpg", name: "93b6740661268f36e1c612b1f5b41ef5.jpg"},
                    ],
                    OrderDetails : '',
                    OrderCost : '',
                },
                load : false,
                //回调
                OrderId :'',
                OrderTypeId :'',
                memberDay : '',
                notLoggedIn : false,

                type : '',
                trade_type : '',
                paymentQuery : null,
                paymentSuccess : false,

                invalid : true,
                duration : false
            };
        },
        methods : {

            dataURLtoFile :function (dataurl, filename = 'file') {
                let arr = dataurl.split(',')
                let mime = arr[0].match(/:(.*?);/)[1]
                let suffix = mime.split('/')[1]
                let bstr = atob(arr[1])
                let n = bstr.length
                let u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], `${filename}.${suffix}`, {
                    type: mime
                })
            },
            voice : function(url){
                var _this = this
                fetch(url).then(data=>{
                    const blob = data.blob();
                    return blob;
                }).then(blob=>{
                    let reader = new window.FileReader();
                    reader.onloadend = function() {
                        const data = reader.result;
//                      console.log(_this.dataURLtoFile(data))
                        var param = new FormData(); //创建form对象
                        param.append('file', _this.dataURLtoFile(data));//通过append向form对象添加数据
                        // param.append('MenuId', _this.menuId);
                        var config = {}
                        axios.post('https://manager.lvwa18.com:8002/upload_file_H5', param, config)
                            .then(response => {
                                _this.orderData.voiceData.push({mp3:response.data.Data.path,name:'语音'})
                                console.log(_this.orderData.voiceData)
                            })
                    };
                    reader.readAsDataURL(blob);
                })
            },
        // 录音处理结束事件
            handelEndRecord(param) {
                this.msource = param;
                if(param.url){
                    this.voice(this.msource.url)
                }
            },
            getTouchStart(event){
                event.preventDefault();
                var _this = this
                clearTimeout(this.sufu);
                this.sufu=setTimeout(function(){
                    _this.invalid = false
                    _this.duration = true
                    wx.startRecord({
                        success: function(e){
                            console.log(e)
                            // 开始录音的代码处理
                        },
                        cancel: function (e) {
                            console.log(e)
                        }
                    })
                },500);
            },
            getTouchEnd(){
                let that = this
                if(that.invalid){
                    console.log('无效录音')
                    that.$toast.fail('说话时间太短');
                    clearTimeout(this.sufu);
                }else {
                    console.log('有效录音')
                    that.duration = false
                    clearTimeout(this.sufu);
                    // clearInterval(this.timer)
                    wx.stopRecord({
                        success: function (res) {
                            // 结束后的代码处理
                            that.localId = res.localId
                            that.uploadVoice(res.localId);
                        }
                    })
                }
            },
            uploadVoice(_localId) {
                let that = this
                // 上传到微信为临时素材
                wx.uploadVoice({
                    localId: _localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: function (res) {
                        // 获得微信服务端音频ID
                        var serverId = res.serverId; // 返回音频的服务器端ID
                    }
                });
            },
            submitFile : function(){
                var _this = this
                if(_this.paymentSuccess == true){
                    if(_this.orderData.OrderDetails || _this.orderData.fileData != ''){
                        _this.send({
                            loading: false,
                            url: '/UserOrderUpdate',
                            data: {
                                UserId: window.sessionStorage.UserId,
                                UserLogin: window.sessionStorage.UserLogin,
                                UserToken: window.sessionStorage.UserToken,
                                IsUser : window.sessionStorage.IsUser,
                                OrderTypeId: _this.OrderTypeId,
                                OrderId: _this.OrderId,
                                OrderFile : _this.orderData.fileData,
                                OrderMsg : _this.orderData.OrderDetails,
                            },
                            success: function (res) {
                                _this.orderData.OrderDetails = ''
                                _this.orderData.fileData = []
                                _this.$router.push({name: 'caseDetails',query: {OrderId: _this.OrderId}})
                                _this.$toast.success('提交成功');
                            }
                        })
                    }else {
                        _this.$toast('请输入留言内容')
                    }
                }else {
                    _this.$toast('支付后提交')
                }
            },
            is_labour_pay : function(OrderId,OrderTypeId){
                var _this = this
                axios({
                    // 默认请求方式为get
                    method: 'post',
                    url: 'https://manager.lvwa18.com:8002/is_labour_pay',
                    params: {OrderId:OrderId,OrderTypeId:OrderTypeId},
                    responseType: 'json'
                }).then(response => {
                    if(response.data == 1){
                        // _this.$router.push({name: 'caseDetails',query: {OrderId: OrderId}})
                        _this.$toast.success('支付成功');
                        _this.paymentSuccess = true
                        clearInterval(_this.paymentQuery)
                        _this.paymentQuery = null;
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            addOrder : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/AddOrder',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        UserId : window.sessionStorage.UserId,
                        OrderTypeId : _this.orderData.OrderTypeId,
                        OrderDetails : _this.orderData.OrderDetails,
                        OrderText : _this.orderData.fileData,
                        Type : window.sessionStorage.IsUser,
                        LawyerId : window.sessionStorage.User_LawyerId,
                        OrderCost : _this.orderData.OrderCost,
                        remarkVoice :  _this.orderData.voiceData,
                    },
                    success: function (res) {
                        _this.OrderId = res.OrderId
                        _this.OrderTypeId = res.OrderTypeId
                        if(window.sessionStorage.UserId == ""){
                            // _this.notLoggedIn = true
                        }else {
                            if(_this.orderData.OrderTypeId == 1){
                                if(_this.memberDay != ''){
                                    _this.$router.push({name: 'dialogue', query: {ContactId:res.ContactId}})
                                    return
                                }else {
                                    _this.$router.push({name: 'my'})
                                    return
                                }
                            }if(_this.orderData.OrderTypeId == 6){
                                _this.$toast.success('提交成功');
                                _this.send({
                                    loading: false,
                                    url: '/UserOrderUpdate',
                                    data: {
                                        UserId: window.sessionStorage.UserId,
                                        UserLogin: window.sessionStorage.UserLogin,
                                        UserToken: window.sessionStorage.UserToken,
                                        IsUser : window.sessionStorage.IsUser,
                                        OrderTypeId: _this.OrderTypeId,
                                        OrderId: _this.OrderId,
                                        OrderFile : _this.orderData.fileData,
                                        OrderMsg : _this.orderData.OrderDetails,
                                    },
                                    success: function (res) {
                                        _this.orderData.OrderDetails = ''
                                        _this.orderData.fileData = []
                                        _this.$router.push({name: 'caseDetails',query: {OrderId: _this.OrderId}})
                                        _this.$toast.success('提交成功');
                                    }
                                })
                                return
                            }else {
                                _this.load = true
                                _this.send({
                                    loading : false,
                                    url: '/labour_wx_pay',
                                    data: {
                                        UserId : window.sessionStorage.UserId,
                                        openid: window.sessionStorage.openid||'',
                                        trade_type : _this.trade_type,
                                        PayMember : '',
                                        OrderTypeId : res.OrderTypeId,
                                        OrderId  : res.OrderId,
                                    },
                                    success: function (res) {
                                        _this.load = false
                                        if(_this.trade_type == 'MWEB'){
                                            _this.paymentQuery = setInterval(function () {
                                                _this.is_labour_pay(_this.OrderId,_this.orderData.OrderTypeId)
                                            }, 1000)
                                            window.location.href= res
                                        }
                                        if(_this.trade_type == 'JSAPI'){
                                            _this.order_num = res.order_num
                                            WeixinJSBridge.invoke(
                                                // 'getBrandWCPayRequest',response.data.result,
                                                'getBrandWCPayRequest', {
                                                    "appId" : res.result.appId,
                                                    "timeStamp": res.result.timeStamp,
                                                    "nonceStr" :res.result.nonceStr ,
                                                    "package" : res.result.package,
                                                    "signType" :res.result.signType,
                                                    "paySign" :res.result.paySign,
                                                },
                                                function(res){
                                                    WeixinJSBridge.log(res.err_msg);
                                                    console.log(res)
                                                    if(res.err_msg == 'get_brand_wcpay_request:ok'){
                                                        _this.callBackPre(_this.order_num,'SUCCESS')
                                                        _this.$toast.success('支付成功');
                                                        _this.modal1 = true
                                                    }else {
                                                        _this.callBackPre(_this.order_num,'FAIL')
                                                        _this.$toast.fail('支付失败');
                                                    }
                                                }
                                            );
                                        }
                                    }
                                })
                            }
                        }
                    }
                })
            },
            //提交
            submit : function(){
                var _this = this
                if(window.sessionStorage.UserId == ""){
                    _this.notLoggedIn = true
                }
                if(_this.orderData.OrderTypeId == 6){
                    if(_this.orderData.OrderDetails != ''&& _this.orderData.fileData != ''){
                        if(_this.orderData.OrderCost != ''){
                            _this.addOrder()
                        }else{
                            _this.$toast('请输入标的金额')
                        }
                    }else {
                        _this.$toast('请输入完成后提交')
                    }

                }else {
                    _this.addOrder()
                }
                // if(_this.orderData.OrderTypeId == 1){
                //     _this.addOrder()
                // }else {
                //     if(_this.orderData.OrderDetails != ''&& _this.orderData.fileData != ''){
                //         if(_this.orderData.OrderTypeId == 6){
                //             if(_this.orderData.OrderCost != ''){
                //                 _this.addOrder()
                //             }else{
                //                 _this.$toast('请输入标的金额')
                //             }
                //         }else {
                //             _this.addOrder()
                //         }
                //     }else{
                //         _this.$toast('输入完成后支付')
                //     }
                // }

            },
            //支付回调
            callBackPre : function(PayNum,PayStatus){
                console.log(PayNum,PayStatus)
                var _this = this
                _this.load = true
                _this.send({
                    url: '/CallBackPre',
                    data: {
                        UserId : window.sessionStorage.UserId,
                        openid: window.sessionStorage.openid,
                        PayNum : PayNum,
                        PayStatus : PayStatus,
                        OrderTypeId : _this.OrderTypeId,
                        OrderId : _this.OrderId,
                        PayMember : '',
                    },
                    success: function (res) {
                        _this.load = false
                        if(PayStatus == 'SUCCESS'){
                            _this.paymentSuccess = true
                            // _this.$router.push({name: 'caseDetails',query: {OrderId: _this.OrderId}})
                        }
                    }
                })
            },
            //预览图片
            previewImg : function(img){
                console.log(img)
                var Ttype="";
                let suffix = '';
                let result = '';
                const flieArr = img.split('.');
                suffix = flieArr[flieArr.length - 1];
                if(suffix!=""){
                    suffix = suffix.toLocaleLowerCase();
                    const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
                    result = imglist.find(item => item === suffix);
                    if (result) {
                        ImagePreview({
                            images: [img],
                            startPosition: 0,
                            closeable: true,
                        });
                    }else {
                        window.location.href = img
                    }
                }
            },
            //删除图片
            deleteArray : function(index){
                var _this = this
                delete _this.orderData.fileData.splice(index, 1)
                console.log(_this.orderData.fileData)
            },
            //删除图片
            voicedeleteArray : function(index){
                var _this = this
                delete _this.orderData.voiceData.splice(index, 1)
                console.log(_this.orderData.voiceData)
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                var _this = this
                _this.load = true
                console.log(file)
                if(file.file){
                    var param = new FormData(); //创建form对象
                    param.append('file', file.file);//通过append向form对象添加数据
                    // param.append('MenuId', _this.menuId);
                    var config = {}
                    axios.post('https://manager.lvwa18.com:8002/upload_file_H5', param, config)
                        .then(response => {
                            _this.load = false
                            console.log(response)
                            _this.orderData.fileData.push({img:response.data.Data.path,name:response.data.Data.name})
                            console.log(_this.orderData.fileData)
                        })
                }else {
                    for(var i in file){
                        var param = new FormData(); //创建form对象
                        param.append('file', file[i].file);//通过append向form对象添加数据
                        // param.append('MenuId', _this.menuId);
                        var config = {}
                        axios.post('https://manager.lvwa18.com:8002/upload_file_H5', param, config)
                            .then(response => {
                                _this.load = false
                                console.log(response)
                                _this.orderData.fileData.push({img:response.data.Data.path,name:response.data.Data.name})
                                console.log(_this.orderData.fileData)
                            })
                    }
                }


            },
            onChoice : function(OrderTypeId){
                var _this = this
                _this.orderData.OrderTypeId = OrderTypeId
                console.log(_this.orderData)
            },
            orderType : function () {
                var _this = this
                _this.send({
                    url: '/OrderType',
                    data: {},
                    success: function (res) {
                        _this.categoryList = res
                        console.log(res)
                    }
                })
            },
            //    支付
            getCode(){
                var _this = this
                var local = 'https://manager.lvwa18.com/user/newOrder?type='+_this.$route.query.type+''
                var ua = navigator.userAgent.toLowerCase();
                console.log(this.$route.query.code)
                if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        _this.trade_type = 'JSAPI'
                        if(window.sessionStorage.openid){

                        }else {
                            _this.load = true
                            if(_this.$route.query.code == null || _this.$route.query.code == ''){
                                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx61856270fcce1981&redirect_uri='+
                                    encodeURIComponent(local)+ '&response_type=code&scope=snsapi_base&state=statetwechat_redirect'
                            }else{
                                _this.send({
                                    url: '/getOpenID',
                                    data: {
                                        code : _this.$route.query.code,
                                        appid : 'wx61856270fcce1981',
                                        appsecret : 'c1f80515f1139086dd10c90d3dbc0acb',
                                    },
                                    success: function (res) {
                                        console.log(res)
                                        _this.load = false
                                        window.sessionStorage.openid = res
                                    }
                                })
                            }
                        }
                    }else {
                        _this.trade_type = 'MWEB'
                        console.log('非微信浏览器')
                    }

                }else{
                    _this.trade_type = 'NATIVE'
                    return false; // PC端
                }
            },

        },
        mounted () {
            var _this = this
            this.orderData.OrderTypeId = this.$route.query.type
            this.type = this.$route.query.type
            _this.CompanyName = window.sessionStorage.CompanyName
            _this.memberDay = window.sessionStorage.memberDay
            this.orderType()
            this.getCode()
            _this.send({
                consulting: true,
                url: '/getConfig',
                data: {
                    url:window.location.href
                },
                success: function (res) {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: res.appid, // 必填，公众号的唯一标识
                        timestamp: res.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.nonceStr, // 必填，生成签名的随机串
                        signature: res.signature, // 必填，签名
                        jsApiList: ['startRecord', 'stopRecord', 'uploadVoice', 'onVoiceRecordEnd','downloadVoice','playVoice'] // 必填，需要使用的JS接口列表
                    });
                }
            })
        }
    }
</script>


