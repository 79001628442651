<style scoped>
    .tips{font-size: 0.36rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.36rem;margin: 0.87rem 0 0 0.55rem;display: inline-block;}
    .password{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 0.32rem;display: inline-block;float: right;margin: 0.84rem 0.43rem 0 0;}
    .mobileName{width: 0.80rem;height: 0.46rem;background: #E9EFF9;border-radius: 4px;font-size: 0.30rem;text-align: center;line-height: 0.46rem;display: inline-block;vertical-align: top;margin-top: 0.32rem}
    .mobileinput{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 0.36rem;width:calc(100% - 1.17rem);}
    .signinFillIn{width: 6.4rem;height: 1.1rem;border-bottom: 0.01rem solid #DEDEE0;position: relative;}
    .title{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 1.1rem;display: inline-block;vertical-align: top;position: absolute;left: 0;}
    .input{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 1.16rem;width:calc(100% - 1.16rem);position: absolute;left: 0}
    .obtainCode{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #0A76CA;line-height: 0.32rem;position: absolute;right: 0;top:0.42rem;}
    .signin{width: 6.40rem;height: 0.90rem;background: #CCEBFE;border-radius: 0.04rem;font-size: 0.30rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.9rem;display: inline-block;margin: 0.4rem 0 0.75rem;}
</style>

<template>
    <div>
        <div>
            <div class="tips">您好，欢迎登录！</div>
            <!--<router-link :to="{name:'login'}">-->
                <!--<div class="password">密码登录</div>-->
            <!--</router-link>-->
        </div>
        <div style="margin: 0.6rem 0.55rem 0">
            <div class="signinFillIn">
                <div class="mobileName">+86</div>
                <input class="mobileinput" placeholder="请输入手机号码" v-model="UserTel"/>
            </div>
            <div class="signinFillIn">
                <div class="title">密   码</div>
                <input class="input" placeholder="请输入密码" v-model="PassWord" type="password"/>
            </div>
            <!--<div class="signinFillIn">-->
                <!--<div class="title">验证码</div>-->
                <!--<input class="input" placeholder="请输入验证码"/>-->
                <!--<div class="obtainCode">获取验证码</div>-->
            <!--</div>-->
        </div>
        <div class="center font-size">
            <!--:style="{'background-color':(UserTel != ''&&PassWord != ''?'#0A76CA':'')}"-->
            <div class="signin" v-if="UserTel != ''&&PassWord != ''" style="background-color: #0A76CA;" @click="login">登录</div>
            <div class="signin" v-else>登录</div>
        </div>
        <div  class="center font-size">
            <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #A2A4A6;line-height: 0.32rem;">
                —— 还没有注册？ <router-link :to="{name:'register'}"><span style="color: #0A76CA;">立即注册 </span></router-link>——
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "login",
        data() {
            return {
                // UserTel : '18780146589',
                // PassWord : '123456',
                UserTel : '',
                PassWord : '',
                IsUser : '2',
            };
        },
        methods :{
            login : function () {
                var _this = this
                _this.send({
                    url : '/Login',
                    data: {UserTel : _this.UserTel,PassWord:_this.PassWord,IsUser:_this.IsUser},
                    success: function (res) {
                        // _this.login()
                        window.sessionStorage.UserId = res.UserId
                        window.sessionStorage.UserToken = res.UserToken
                        SYSCONFIG.server.default.data.UserId   = res.UserId
                        SYSCONFIG.server.default.data.UserToken   = res.UserToken
                        _this.setCookie(res.UserId,res.UserToken,_this.UserTel,_this.PassWord,res.UserLogin)
                        _this.$router.push({name:'home'});
                    }
                })
            },
            setCookie(UserId,UserToken,UserTel,PassWord,UserLogin) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 30); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "UserId" + "=" + UserId + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserToken" + "=" + UserToken + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserTel" + "=" + UserTel + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "PassWord" + "=" + PassWord + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserLogin" + "=" + UserLogin + ";path=/;expires=" + exdate.toGMTString();
            },
        },
        mounted  (){
            var _this = this
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'UserTel') {
                        _this.UserTel = arr2[1];
                    } else if(arr2[0] == 'PassWord') {
                        _this.PassWord = arr2[1];
                    }
                }
            }
        }
    }
</script>


