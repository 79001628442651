<style scoped>
    .home{background-color: #F7F6F9}
    .bg{width: 6.90rem;height: 2.20rem;margin-top: 0.3rem;background-image: url("/img/bg.png");text-align: center; background-repeat: no-repeat, repeat;display: inline-block;background-size: 100% 100%;}
    .tool{width: 33.3%;text-align: center;display: inline-block;vertical-align: top;margin-top: 0.4rem;position: relative}
    .toolName{font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.28rem;margin-top: 0.2rem}
    .toolIcon{width: 0.72rem;height: 0.66rem;}
    .label{width: 0.1rem;height: 0.26rem;background: #0C83E4;border-radius: 0 0.04rem 0.04rem 0;display: inline-block;margin: 0.3rem 0.2rem 0 0;vertical-align: top}
    .labelName{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;display: inline-block;vertical-align: top}
    .fileImg{width: 0.92rem;height: 0.92rem;}
    .fileName{font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.48rem;margin-top: 0.21rem;}
    .lawToolImg{width: 0.46rem;height: 0.4rem;vertical-align: top;margin: 0.3rem 0.14rem 0 0.2rem;}
    .lawToolName{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #333333;display: inline-block;}
    .lawTool{width: 6.3rem;height: 1rem;display: inline-block;border-bottom: 0.01rem solid #D6DDE2;line-height: 1rem;text-align: left}
    .arrow{width: 0.14rem;height: 0.24rem;float: right;margin-top: 0.39rem}
    .banner{width: 7.1rem;height: 2.4rem;}
    .my-swipe >>> .van-swipe__indicators{bottom: 0.3rem;}
    .newUnread{width: 0.15rem;height: 0.15rem;background-color: red;border-radius: 0.2rem;position: absolute;top: 0;right: 0.82rem;}
    .vip{width: 0.52rem;height: 0.3rem;vertical-align: top;margin-left: 0.15rem;}
    .fullName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #567AAE;line-height: 0.24rem;}

</style>

<template>
    <div class="home" v-show="redirect">
        <div style="padding: 0.1rem"></div>
        <div class="center font-size" style="background-color: #FFF;border-radius: 0px 0px 0.2rem 0.2rem;">
            <img src="/img/banner01.png" class="banner"/>
            <!--<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">-->
                <!--<van-swipe-item><img src="/img/banner01.png" class="banner"/></van-swipe-item>-->
                <!--<van-swipe-item><img src="/img/banner02.png" class="banner"/></van-swipe-item>-->
            <!--</van-swipe>-->
            <div v-if="LawyerName">
                <div style="font-size: 0.3rem;text-align: left;margin: 0.2rem 0.35rem 0;line-height: 0.3rem">
                    <div style="font-size: 0.48rem;font-weight: bold;color: #1186E1;line-height: 0.48rem;margin-bottom: 0.31rem">千所百处帮万企</div>
                    <div style="text-align: left;display: inline-block;color: #333">你好，{{CompanyName}}
                        <!--<img class="vip" src="/img/vip.png" v-if="memberDay > 0">-->
                        <!--<div style="margin-top: 0.2rem" v-if="memberDay > 0">法管家VIP：{{date}}</div>-->
                    </div>

                    <!--<div style="font-size: 0.26rem;float: right;display: inline-block"></div>-->
                </div>
                <div style="width: 100%;display: inline-block;background: url(/img/wuhou.png);background-size: 100% 100%;height: 4.2rem" >
                    <div style="font-size: 0.28rem;text-align: left;margin: 0.29rem 0 0rem 0.35rem;color: #666;line-height: 0.28rem" >
                        <div>
                            <span style="color:#666666;margin-right: 0.1rem" v-if="IsStatus == 1">由</span>
                            <span style="font-weight: bold;color: #333">{{streetName}}</span>
                            <img src="/img/arrow.png" style="width:0.18rem;height: 0.18rem;margin: 0 0.1rem;color: #333"/>
                            {{LawyerName}}
                        </div>
                        <div style="color: #666666;line-height: 0.28rem;margin-top: 0.32rem" v-if="IsStatus == 1">
                            <span style="color:#1186E1;" @click="legalAdvice(1)">[{{LName}}]</span>为你提供法治体检服务
                        </div>
                        <div style="height: 0.58rem" v-if="IsStatus == 1" >
                            <img style="width: 0.18rem;height: 0.28rem;margin: 0.3rem 0.1rem 0 0;vertical-align: top;" src="/img/shouji.png">
                            <div style="display: inline-block;margin-top: 0.3rem">Tel:{{LTel}}</div>
                            <div style="background: #F7F7F7;border: 0.01rem solid #1186E1;border-radius: 0.28rem;vertical-align: top;margin-top: 0.3rem;
font-size: 0.18rem;font-weight: 500;color: #1186E1;text-align: center;display: inline-block;margin-left: 0.08rem" @click="callPhone(LTel)">
                                <div style="font-size: 0.12rem;padding: 0 0.1rem">拨打电话</div>
                            </div>
                        </div>
                        <div v-else="" style="height: 0.58rem">
                            <div style="margin-top: 0.3rem">预约成功后可拨打电话</div>
                        </div>
                    </div>
                    <div style="width: 2.2rem;height: 2.2rem;background: url(/img/ccc.png);background-size: 100% 100%;border-radius: 50%;display: inline-block;margin-top: 0.21rem">
                        <img v-if="IsStatus == 1" src="/img/yuyuechenggong.png" style="width: 0.62rem;height: 0.68rem;vertical-align: top;margin-top: 0.53rem">
                        <img v-else="" src="/img/jinhangzhong.png" style="width: 0.62rem;height: 0.68rem;vertical-align: top;margin-top: 0.53rem">
                        <div v-if="IsStatus == 1" style="color: #FFF;font-size: 0.28rem;margin-top: 0.05rem">预约成功</div>
                        <div v-else="" style="color: #FFF;font-size: 0.28rem;margin-top: 0.05rem">预约中</div>
                    </div>
                </div>
                <div style="height: 1.77rem;background-color: #FFF;border-radius: 0px 0px 0.2rem 0.2rem;">
                    <div style="width: 6.4rem;height: 0.92rem;background: #FBFCFE;border: 0.02rem solid rgba(17, 134, 225, 0.95);box-shadow: 0px 3px 4px 0px rgba(14, 47, 246, 0.24);border-radius: 0.46rem;
font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #1186E1;display: inline-block;margin-top: 0.4rem" @click="legalAdvice(1)">
                        <img src="/img/zixun.png" style="width: 0.36rem;height: 0.36rem;margin: 0.28rem 0.1rem 0 0;vertical-align: top"/>
                        <div style="line-height: 0.92rem;display: inline-block">咨询</div>
                    </div>
                </div>
            </div>


        </div>
        <div class="center font-size">
            <div style="background-color: #FFF;margin-top: 0.3rem;width: 6.9rem;display: inline-block;border-radius: 0.04rem;">
                <div style="vertical-align: top;height: 0.85rem;line-height: 0.85rem;text-align: left">
                    <div class="label"></div>
                    <div class="labelName">常用文件</div>
                </div>
                <div style="width: 25%;margin: 0.15rem 0 0.3rem;display: inline-block;" v-for="(value,index) in groceryList" @click="tabSwitch(index)">
                    <img class="fileImg" :src="'/img/cywj_0'+index+'.png'">
                    <div class="fileName">{{value.text}}</div>
                </div>
            </div>
        </div>
        <!--<div class="center font-size">-->
            <!--<div style="background-color: #FFF;margin-top: 0.3rem;width: 6.9rem;display: inline-block;border-radius: 0.04rem;">-->
                <!--<div style="vertical-align: top;height: 0.85rem;line-height: 0.85rem;text-align: left">-->
                    <!--<div class="label"></div>-->
                    <!--<div class="labelName">法管家增值服务</div>-->
                <!--</div>-->
                <!--<div style="text-align: left">-->
                    <!--<div class="tool" @click="legalAdvice(value.OrderTypeId)" v-for="(value,index) in categoryList">-->
                        <!--<img :src="'/img/icon_0'+index+'.png'" class="toolIcon">-->
                        <!--<div class="toolName">{{value.OrderName}}</div>-->
                        <!--<div class="newUnread" :style="{display:flag%2==0?'block':'none'}" v-if="value.OrderTypeId == '1' && UserStatus == 1"></div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div style="padding: 0.2rem;"></div>-->
            <!--</div>-->
        <!--</div>-->
        <div class="center font-size">
            <div style="background-color: #FFF;margin-top: 0.3rem;width: 6.9rem;display: inline-block;border-radius: 0.04rem;">
                <div style="vertical-align: top;height: 0.85rem;line-height: 0.85rem;text-align: left">
                    <div class="label"></div>
                    <div class="labelName">法律工具</div>
                </div>
                <div class="lawTool" v-for="(value,index) in legalTools" @click="lawTool(value.type,value.url)">
                    <img :src="value.img" class="lawToolImg">
                    <div class="lawToolName">{{value.name}}</div>
                    <img src="/img/icon-arrow-right2.png" class="arrow">
                </div>
            </div>
        </div>
        <div style="padding: 0.12rem"></div>
        <notLoggedIn ref="notLoggedIn" v-if="notLoggedIn"/>
    </div>
</template>

<script>
    import notLoggedIn from '../../components/notLoggedIn.vue'
    export default {
        name: "home",
        components: {notLoggedIn},
        data() {
            return {
                legalTools : [
                    {img:'/img/flgj_01.png',name:'企业信息查询',type:'1',url:'http://www.gsxt.gov.cn/index.html'},
                    {img:'/img/flgj_02.png',name:'司法案例库',type:'2',url:'caseBase'},
                    {img:'/img/flgj_03.png',name:'裁判文书案例',type:'3',url:'http://www.lawsdata.com'},
                    {img:'/img/flgj_04.png',name:'查执行、失信、限高',type:'4',url:'http://zxgk.court.gov.cn/zhzxgk'},
                    {img:'/img/flgj_05.png',name:'查公告',type:'5',url:'https://rmfygg.court.gov.cn'},
                    {img:'/img/flgj_06.png',name:'查法律法规',type:'6',url:'http://www.pkulaw.cn'},
                    {img:'/img/flgj_07.png',name:'查庭审视频',type:'7',url:'http://tingshen.court.gov.cn'},
                    {img:'/img/flgj_08.png',name:'模拟判决',type:'8',url:'https://panda.lvwa18.com/documentField'},
                ],
                categoryList  : [
                    // {OrderName: "法律咨询",OrderTypeId: 1,OrderTypePrice: null},
                    {OrderContent: " 有效性、关键信息的提示、合同履行风险、合同条款的完善等（注：合同审核限5000字以内 ）",OrderName: "合同审核",OrderTypeId: 2},
                    {OrderContent: "入股合同、员工劳务合同、合作合同、采购合同模板（注：合同编写限2000字以内 ）",OrderName: "合同编写",OrderTypeId: 3,OrderTypePrice: 0.02,},
                    {OrderContent: "催款律师函、告知律师函帮助企业非诉解决问题",OrderName: "发律师函",OrderTypeId: 4,OrderTypePrice: 0.03},
                    {OrderContent: "（注：1000元/次/人 时间在2小时之内 超时按500元/小时加价）", OrderName: "陪同谈判", OrderTypeId: 5, OrderTypePrice: 0.04,},
                    {OrderContent: "精准匹配专业律师,(一案一议)", OrderName: "案件代理", OrderTypeId: 6, OrderTypePrice: null}
                ],
                groceryList: [
                    {id: 4, text: '常用合同', type: 'changyong'},
                    {id: 0, text: '企业管理', type: 'qiyeguanli'},
                    {id: 1, text: '员工招聘', type: 'yuangongzhaopin'},
                    {id: 2, text: '其他', type: 'qita'},
                ],
                CompanyName : '',
                notLoggedIn : false,
                flag : 0,
                UserStatus : '',
                redirect : true,
                streetName : '',
                LawyerName : '',
                LawyerLinkman : '',
                LawyerTel : '',
                IsStatus : '',
                UserName : '',
                memberDay : '',
                date : '',
                LName : '',
                LTel : '',
            };
        },
        methods : {
            callPhone (phoneNumber) {
                window.location.href = 'tel:' + phoneNumber
            },
            start:function(){
                var _this = this
                var i = setInterval(function() {
                    _this.flag++
                    _this.flag = _this.flag++
                    if (_this.flag >= 999999) {clearInterval(i)} else {}
                }, 800)
            },
            tabSwitch : function(id){
                var _this = this
                window.sessionStorage.fileTemplate = id
                _this.$router.push({name: 'template'})
            },
            legalAdvice : function (type) {
                var _this = this
                if(type == '1'){
                    if(window.sessionStorage.UserId == ""){
                        _this.notLoggedIn = true
                        // _this.$refs.notLoggedIn.handleParentClick()
                    }else {
                        if(window.sessionStorage.memberDay == ""){
                            _this.$toast('该功能需充值会员后使用')
                            _this.$router.push({name: 'my', query: {type:type}})
                        }else {
                            _this.$router.push({name: 'dialogue', query: {}})
                        }
                    }
                }else {
                    _this.$router.push({name: 'newOrder', query: {type:type}})
                }
            },
            orderType : function () {
                var _this = this
                _this.send({
                    url: '/OrderType',
                    data: {},
                    success: function (res) {
                        _this.categoryList = res
                        console.log(res)
                    }
                })
            },
        //    法律工具
            lawTool : function (type,url) {
                var _this =this
                if(type == 2){
                    _this.$router.push({name: url})
                }else {
                    window.location.href = url
                }
            },
            remindContact : function(){
                var _this = this
                _this.send({
                    url: '/RemindContact',
                    consulting : true,
                    data: {
                        UserId : window.sessionStorage.UserId||'UserId',
                    },
                    success: function (res) {
                        _this.UserStatus = res.List[0].UserStatus
                        if(_this.UserStatus == 1){
                            _this.start()
                        }
                    }
                })
            },
            getCode(){
                var _this = this
                var local = 'https://manager.lvwa18.com/user/home'
                var ua = navigator.userAgent.toLowerCase();
                console.log(this.$route.query.code)
                if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        if(window.sessionStorage.openid){

                        }else {
                            _this.load = true
                            if(_this.$route.query.code == null || _this.$route.query.code == ''){
                                _this.redirect = false
                                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx61856270fcce1981&redirect_uri='+
                                    encodeURIComponent(local)+ '&response_type=code&scope=snsapi_base&state=statetwechat_redirect'
                            }else{
                                _this.send({
                                    url: '/getOpenID',
                                    data: {
                                        code : _this.$route.query.code,
                                        appid : 'wx61856270fcce1981',
                                        appsecret : 'c1f80515f1139086dd10c90d3dbc0acb',
                                    },
                                    success: function (res) {
                                        console.log(res)
                                        _this.load = false
                                        window.sessionStorage.openid = res
                                        _this.setCookie(res)
                                    }
                                })
                            }
                        }
                    }else {
                        console.log('非微信浏览器')
                    }

                }else{
                    return false; // PC端
                }
            },
            setCookie(openid) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 30); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "openid" + "=" + openid + ";path=/;expires=" + exdate.toGMTString();
            },
        },

        mounted () {
            var _this = this
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    if(arr2[0] == 'openid') {
                        window.sessionStorage.openid = arr2[1];
                    }
                }
            }
            _this.remindContact()
            _this.getCode()
            // _this.orderType()
            setTimeout(function () {
                _this.CompanyName = window.sessionStorage.CompanyName
                _this.streetName = window.sessionStorage.streetName
                _this.memberDay = window.sessionStorage.memberDay
                _this.date = window.sessionStorage.date
                _this.LName = window.sessionStorage.LName
                _this.LTel = window.sessionStorage.LTel
                if(window.sessionStorage.LawyerName){
                    _this.LawyerName = window.sessionStorage.LawyerName
                }
                console.log(_this.LawyerName)
                _this.LawyerLinkman = window.sessionStorage.LawyerLinkman
                _this.IsStatus = window.sessionStorage.IsStatus
                _this.UserName = window.sessionStorage.UserName
                _this.LawyerTel = window.sessionStorage.LawyerTel
            }, 1000)
        }
    }
</script>
