<template>
    <div class="wrap-recorder">
        <Button style="border:0.01rem solid #ccc;background-color: #FFF;font-size: 0.2rem;margin: 0.2rem 0.2rem 0 0;padding: 0.1rem;"
                type="info" @touchstart="getTouchStart($event)" @touchend="getTouchEnd">长按录音</Button>
        <div style="position: fixed;top: 5rem;margin-left: -0.75rem;left: 50%;background-color: rgba(0,0,0,0.4);width: 1.5rem;height: 1.5rem;border-radius: 0.2rem;text-align: center" v-if="duration">
            <van-icon name="volume-o" color="#FFF" style="margin-top: 0.25rem;" size="60"/>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                tiptext: "点击录音",
                recording: false, // 标记是否在录音
                intervaltimerid: "", // 间隔时间定时器编号
                recorder: null,
                time: 0,
                duration : false
            };
        },
        mounted() {
            this.init();
            this.recorder.start(this.successFun(), this.errorFun());
        },
        methods: {
            init() {
                this.recorder = new MP3Recorder({
                    //numChannels: 1,     //声道数,默认为1
                    //sampleRate: 8000,   //采样率,一般由设备提供,比如 48000
                    bitRate: 128, //比特率,不要低于64,否则可能录制无声音（人声）
                    //useMediaRecorder: true, //是否使用MediaRecorder录音（不支持转码为mp3文件）
                    //recorderType: "video/webm;codes=vp9",  //默认编码，仅 useMediaRecorder 为true且浏览器支持时生效
                    //录音结束事件
                    complete: (blob, ext) => {
                        var url = URL.createObjectURL(blob);
                        this.$emit("handleStop", {
                            url: url,
                            mblob: blob
                        });
                    }
                });
            },
            ccc : function(){
                console.log(this.recorder.start(this.errorFun()))
                // this.recorder.start(this.errorFun());
            },
            getTouchStart (event){
                event.preventDefault();
                this.time = 0;
                this.duration = true;
                this.tiptext = "录音中 " + this.time + "s";
                this.recorder.start(this.successFun(), this.errorFun());
            },
            getTouchEnd (){
                clearInterval(this.intervaltimerid);
                this.duration = false;
                this.recorder.stop();
                this.tiptext = "点击录音";
            },
            // 点击处理
            handleClick() {
                //录音支持检测
                if (!this.recorder.support) return;
                this.recording = !this.recording;
                this.$emit("handleStop", {
                    url: "",
                    mblob: null,
                });
                if (this.recording) {
                    this.time = 0;
                    this.tiptext = "录音中 " + this.time + "s";
                    this.recorder.start(this.successFun(), this.errorFun());
                } else {
                    clearInterval(this.intervaltimerid);
                    this.recorder.stop();
                    this.tiptext = "点击录音";
                }
            },
            writeError() {},
            successFun() {
                this.intervaltimerid = setInterval(() => {
                    // 开始累积
                    // if (this.time == 120) {
                    //     this.recorder.stop();
                    //     this.recording = false;
                    //     this.tiptext = "点击录音";
                    //     this.$message.warning("对不起，录音只支持120秒以内的语音！");
                    //     clearInterval(this.intervaltimerid);
                    //     return false;
                    // }
                    this.time = this.time + 1;
                    this.tiptext = "录音中 " + this.time + "s";
                }, 1000);
            },
            errorFun(e) {
                console.log(e)
                // clearInterval(this.intervaltimerid);
                // switch (e.code || e.name) {
                //   case "PERMISSION_DENIED":
                //   case "PermissionDeniedError":
                //     // this.writeError("用户拒绝提供设备。");
                //     break;
                //   case "NOT_SUPPORTED_ERROR":
                //   case "NotSupportedError":
                //     // this.writeError("浏览器不支持硬件设备。");
                //     break;
                //   case "MANDATORY_UNSATISFIED_ERROR":
                //   case "MandatoryUnsatisfiedError":
                //     // this.writeError("无法发现指定的硬件设备。");
                //     break;
                //   default:
                //     // this.writeError(
                //     //   "无法打开麦克风。异常信息:" + (e.code || e.name)
                //     // );
                //     break;
                // }
            }
        }
    };
</script>
<style scoped lang="scss">
    .wrap-recorder {
        text-align: center;
        .icon {
            cursor: pointer;
        }
        .tip {
            padding-top: 20px;
        }
    }
    .anirecorder {
        position: relative;
        animation: mymove 5s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out;
    }

    @keyframes mymove {
        0% {
            transform: scale(1); /*开始为原始大小*/
        }
        25% {
            transform: scale(1.1); /*放大1.1倍*/
        }
        50% {
            transform: scale(0.9);
        }
        75% {
            transform: scale(1.1);
        }
    }
</style>
