<template>

</template>

<script>
    export default {
        name: "home",
        mounted () {
            var _this = this
            _this.$router.push({name:'home'});
            return
            _this.send({
                url: '/InitUser',
                consulting : true,
                data: {
                    UserId : window.sessionStorage.UserId||'UserId',
                    UserToken : window.sessionStorage.UserToken||'',
                    IsUser : '2',
                    UserLogin : window.sessionStorage.UserLogin||'',
                    LawyerId : '',
                },
                success: function (res) {
                    if(res){
                        window.sessionStorage.IsUser = res.IsUser|| ''
                        window.sessionStorage.memberDay = res.memberDay|| ''
                        window.sessionStorage.LawyerLinkman = res.LawyerLinkman|| ''
                        window.sessionStorage.LawyerName = res.LawyerName|| ''
                        window.sessionStorage.streetName = res.streetName|| ''
                        window.sessionStorage.User_LawyerId = res.User_LawyerId|| ''
                        window.sessionStorage.date = res.date|| ''
                        window.sessionStorage.CompanyName = res.List.CompanyName|| ''
                        window.sessionStorage.Introduce = res.List.Introduce|| ''
                        window.sessionStorage.LastLogin = res.List.LastLogin|| ''
                        window.sessionStorage.Password = res.List.Password|| ''
                        window.sessionStorage.RegisterCode = res.List.RegisterCode|| ''
                        window.sessionStorage.RegisterTime = res.List.RegisterTime|| ''
                        window.sessionStorage.UserId = res.List.UserId|| ''
                        window.sessionStorage.UserImg = res.List.UserImg|| ''
                        window.sessionStorage.UserLogin = res.List.UserLogin|| ''
                        window.sessionStorage.UserName = res.List.UserName|| ''
                        window.sessionStorage.UserTel = res.List.UserTel|| ''
                        window.sessionStorage.status = res.List.status|| ''
                        window.sessionStorage.LTel = res.List.LTel|| ''
                        window.sessionStorage.LName = res.List.LName|| ''
                        window.sessionStorage.IsStatus = res.List.IsStatus|| ''
                        window.sessionStorage.LawyerTel = res.LawyerTel|| ''
                        _this.$router.push({name:'home'});
                    }else {
                        window.sessionStorage.UserId = ''
                        _this.$router.push({name:'login'});
                    }
                }
            })
            console.log(window.sessionStorage)
        },
    }
</script>

<style scoped>

</style>
