<template>
    <div style="font-family: PingFang SC;background-color: #FFF" :style="{'min-height':availHeight+'px'}">
        <div v-if="admin_id == 1" style="background: url(/img/legalAppointment/banner1.png);background-size: 100% 100%;width: 7.5rem;height: 3.6rem;text-align: center;font-family: PingFang SC;position: relative">

        </div>

      <img v-if="admin_id == 2" style="width: 7.5rem;height: 3.6rem" src="/img/fztj.jpg">
        <div style="margin-left: 0.57rem;font-size: 0;padding-bottom: 0.3rem">
            <div style="font-size: 0.28rem;color: #333;margin: 0.59rem 0 0.49rem 0;font-weight: bold;line-height: 0.28rem">为您匹配到以下律所：</div>
            <div style="font-size: 0.28rem;font-weight: bold;color: #333;line-height: 0.28rem;">
                {{streetName}}
                <img src="/img/arrow.png" style="width: 0.18rem;height: 0.18rem;">
            </div>
            <div v-for="(value,index) in columns">
                <input v-model="value.LawyerName" style="width: 6.4rem;height: 0.85rem;border-bottom: #DDDDDD 0.01rem solid;font-size: 0.28rem;color: #666" readonly="readonly" placeholder="" />
            </div>
            <div @click="nextStep" style="width: 6.4rem;height: 0.86rem;background: #1186E1;border-radius: 0.04rem;font-size: 0.28rem;font-weight: bold;color: #FFFFFF;text-align: center;
            line-height: 0.86rem;margin-top: 0.4rem;margin-bottom: 0.3rem">下一步</div>
        </div>
        <!-- <div style="text-align: center">
            <div style="font-size: 0.26rem;color: #999;line-height: 0.26rem;margin-bottom: 0.3rem;">成都市武侯区司法局 监制</div>
            <router-link :to="{name: 'home'}" style="font-size: 0.26rem;color: rgb(32 137 254);line-height: 0.26rem;vertical-align: top;text-decoration: underline">进入>> 律蛙法管家</router-link>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: "matchingLawFirm",
        data() {
            return {
                availHeight : '',
                streetName : '',
                columns: [],
                streetId : '',
                admin_id : '',
            }
        },
        methods :{
            nextStep : function(){
                var _this = this
                _this.$router.push({name: 'basicInformation', query: {streetId:_this.streetId,streetName:_this.streetName,LawyerId:_this.columns[0].LawyerId}})
            },
            lawListByStreet : function(){
                var _this = this
                _this.send({
                    url: '/lawListByStreet',
                    data: {
                        streetId: _this.$route.query.streetId
                    },
                    success: function (res) {
                        _this.columns = res
                    }
                })
            },
        },
        mounted () {
            var _this  = this
            _this.lawListByStreet()
            _this.streetName = _this.$route.query.streetName
            _this.streetId = _this.$route.query.streetId
            _this.admin_id = window.screen.admin_id
            this.availHeight = window.screen.availHeight
        }
    }
</script>

<style scoped>

</style>
