<style scoped>
    .recharge{background: #F7F6F9;}
    .bnner{width: 7.5rem;height: 4.22rem;background: url("/img/bnner.png");background-size: 100% 100%}
    .mode{font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #B4BACD;line-height: 0.24rem;margin: 0.3rem 0 0.19rem 0.3rem;text-align: left;}
    .pay{font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #B4BACD;line-height: 1.16rem;text-align: left;border-bottom: 0.01rem solid #D6DDE2}
    .modeChoiceFalse{width: 6.3rem;height: 1.12rem;background: #FFFFFF;border: 0.01rem solid #D6DDE2;border-radius: 0.04rem;display: inline-block;position: relative;text-align: left;margin-top: 0.3rem}
    .modeChoiceTrue{width: 6.3rem;height: 1.12rem;background: #FFFFFF;border: 0.01rem solid #0C83E4;border-radius: 0.04rem;display: inline-block;position: relative;text-align: left;margin-top: 0.3rem}
    .money{font-size: 0.46rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 1.1rem;margin-left: 0.25rem;display: inline-block;}
    .hook{float: right;margin: 0.41rem 0.32rem 0 0;font-size: 0.3rem;width: 0.3rem;height: 0.3rem;border: 0.03rem solid #D6DDE2;border-radius: 0.32rem;}
    .hookIcon{float: right;margin: 0.41rem 0.32rem 0 0;font-size: 0.37rem;color: #0C83E4;}
    .recommend{width: 0.95rem;height: 0.4rem;position: absolute;top: -0.2rem;left: 0.06rem;}
    .payment{width: 5.2rem;height: 0.86rem;background: #EDCE00;border-radius: 0.43rem;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #FEFEFE;line-height: 0.86rem;text-align: center;display: inline-block;margin: 0.3rem 0 0.2rem 0;}
    .agreement{
        font-size: 0.2rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.3rem;margin-bottom: 0.29rem;}
    .explain{
        font-size: 0.3rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #282828;
        line-height: 0.3rem;margin: 0.3rem 0;}
    .explainList{background: #F3F9FE;border: 0.01rem solid #D6DDE2;font-family: PingFang SC;text-align: left;width: 6.3rem;display: inline-block}
    .explainLeft{width: 3.78rem;margin-left: 0.3rem;border-right: 0.01rem solid #D6DDE2;}
    .explainTitle{font-size: 0.28rem;font-weight: bold;color: #282828;line-height: 0.27rem;padding-top: 0.3rem}
    .explainContent{font-size: 0.22rem;font-weight: 500;color: #666666;line-height: 0.3rem;padding: 0.19rem 0.27rem 0.3rem 0;}
    .explainRight{font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #666666;line-height: 0.3rem;margin: 0.62rem 0 0.3rem 0}
    .explainT{font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #E71717;line-height: 0.3rem;}
</style>

<template>
    <div class="recharge">
        <div class="bnner">

        </div>
        <div class="center font-size" style="margin-top: -1.92rem;">
            <div style="width: 6.9rem;background-color: #FFF" class="inline-block">
                <div class="mode">选择开通方式</div>
                <div class="modeChoiceFalse" :class="[type == value.type?'modeChoiceTrue':'modeChoiceFalse']" @click="onMoney(value.type,value.money)" v-for="(value,index) in moneyData">
                    <img class="recommend" src="/img/tj.png" v-if="value.type == '3'">
                    <div class="money">
                        {{value.money}}
                        <span style="font-size: 0.26rem;margin-right: 0.2rem">{{value.time}}</span>
                        <span style="color: #666;font-size: 0.22rem;">{{value.tips}}</span>
                    </div>
                    <van-icon name="passed" class="hookIcon" v-if="type == value.type" />
                    <div class="hook" v-else ></div>
                </div>
                <div class="pay">
                    <div style="margin-left: 0.3rem" class="inline-block">应付金额</div>
                    <div style="color: #E71717;font-size: 0.46rem;float: right;margin-right: 0.3rem">{{money}}</div>
                </div>
                <div class="payment" @click="payment">立即支付</div>
                <div class="agreement">开通即视为同意<span style="color: #0C83E4">《律蛙法管家会员用户协议》</span></div>
            </div>
        </div>
        <div class="center font-size">
            <div style="width: 6.9rem;background-color: #FFF;margin-top: 0.3rem" class="inline-block">
                <div class="explain">服务说明</div>
                <div class="explainList">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">人工法律咨询</div>
                        <div class="explainContent">劳动纠纷、股权、工商、行政合规、 经营合法、合同纠纷、企业日常运营 合规等问题</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.31rem" class="inline-block">
                        <div class="explainRight">工作时间：</div>
                        <div class="explainT">9:00-18:00</div>
                    </div>
                </div>
                <div class="explainList" style="background-color: #FFF">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">合同审核</div>
                        <div class="explainContent">有效性、关键信息的提示、合同履行 风险、合同条款的完善等</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">200元/份</div>
                        <div style="font-size: 0.2rem;line-height: 0.2rem">市场价800-1000/份</div>
                    </div>
                </div>
                <div class="explainList">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">合同编写</div>
                        <div class="explainContent">入股合同、员工劳务合同、合作合同、 采购合同模板</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">800元/份</div>
                        <div style="font-size: 0.2rem;line-height: 0.2rem">市场价1000-1500/份</div>
                    </div>
                </div>
                <div class="explainList" style="background-color: #FFF">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">发律师函</div>
                        <div class="explainContent">催款律师函、告知律师函帮助企业非 诉解决问题</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">800元/份</div>
                        <div style="font-size: 0.2rem;line-height: 0.2rem">市场价1000-1500/份</div>
                    </div>
                </div>
                <div class="explainList">
                    <div class="inline-block explainLeft">
                        <div class="explainTitle">案件分析</div>
                        <div class="explainContent">案件情况梳理、案件所需证据列明 和提醒补充、案件起诉地和所涉及 法律法规、案件处理建议</div>
                    </div>
                    <div style="vertical-align: top;margin-left: 0.18rem" class="inline-block">
                        <div style="font-size: 0.28rem;color: #E71717;margin: 0.56rem 0 0.18rem;line-height: 0.28rem;font-weight: bold;">1000元/份</div>
                        <!--<div style="font-size: 0.2rem;line-height: 0.2rem"></div>-->
                    </div>
                </div>
                <div class="explainList" style="background-color: #FFF">
                    <div class="inline-block explainLeft" style="height: 1.89rem;">
                        <div class="explainTitle">案件代理</div>
                        <div class="explainContent">精准匹配专业律师,(一案一议)</div>
                    </div>
                    <div style="vertical-align: top;width: 1.59rem;margin: 0.38rem 0.31rem 0.39rem 0.3rem" class="inline-block">
                        <div style="font-size: 0.22rem;color: #E71717;line-height: 0.28rem;font-weight: bold;">收费不超过(案件 涉及金额*10%+ 案件复杂情况附 加费)</div>
                        <!--<div style="font-size: 0.2rem;line-height: 0.2rem"></div>-->
                    </div>
                </div>
                <div style="padding: 0.15rem"></div>
            </div>
        </div>
        <div style="position: fixed;width: 100%;height: 100%;top:0;right: 0;background-color: rgba(0,0,0,0.4);" v-if="load">
            <van-loading type="spinner" color="#1989fa" size="50px" style="text-align: center;position: absolute;top: 50%;left: 45%"></van-loading>
        </div>
        <notLoggedIn ref="notLoggedIn" v-if="notLoggedIn"/>
    </div>
</template>

<script>
    import wx from 'weixin-js-sdk';
    import notLoggedIn from '../../components/notLoggedIn.vue'
    export default {
        name: "recharge",
        components: {notLoggedIn},
        data() {
            return {
                type : '3',
                money : '￥720',
                moneyData : [
                    {money:'￥720',time:'/3年',tips:'每天仅需0.65元',type:'3'},
                    {money:'￥360',time:'/2年',tips:'每天仅需0.89元',type:'2'},
                    {money:'￥240',time:'/1年',tips:'每天仅需1.1元',type:'1'},
                ],
                load : false,
                order_num : '',
                trade_type : 'JSAPI',//默认微信浏览器支付
                notLoggedIn : false
            };
        },
        methods : {
            payment : function(){
                var _this = this
                if(window.sessionStorage.UserId == ""){
                    _this.notLoggedIn = true
                    _this.$refs.notLoggedIn.handleParentClick()
                }
                var ua = navigator.userAgent.toLowerCase();
                _this.send({
                    consulting : true,
                    url: '/labour_wx_pay',
                    data: {
                        UserId : window.sessionStorage.UserId,
                        openid: window.sessionStorage.openid||'',
                        trade_type : _this.trade_type,
                        PayMember : _this.type,
                        OrderTypeId : '',
                        orderId  : '',
                    },
                    success: function (res) {
                        if(_this.trade_type == 'MWEB'){
                            console.log(res)
                            // window.location.href= res
                        }
                        if(_this.trade_type == 'JSAPI'){
                            _this.order_num = res.order_num
                            WeixinJSBridge.invoke(
                                // 'getBrandWCPayRequest',response.data.result,
                                'getBrandWCPayRequest', {
                                    "appId" : res.result.appId,
                                    "timeStamp": res.result.timeStamp,
                                    "nonceStr" :res.result.nonceStr ,
                                    "package" : res.result.package,
                                    "signType" :res.result.signType,
                                    "paySign" :res.result.paySign,
                                },
                                function(res){
                                    WeixinJSBridge.log(res.err_msg);
                                    console.log(res)
                                    if(res.err_msg == 'get_brand_wcpay_request:ok'){
                                        _this.callBackPre(_this.order_num,'SUCCESS')
                                        _this.$toast.success('支付成功');
                                        _this.modal1 = true
                                    }else {
                                        _this.callBackPre(_this.order_num,'FAIL')
                                        _this.$toast.fail('支付失败');
                                    }
                                }
                            );
                        }
                    }
                })
            },
            //支付回调
            callBackPre : function(PayNum,PayStatus){
                console.log(PayNum,PayStatus)
                var _this = this
                _this.send({
                    url: '/CallBackPre',
                    data: {
                        UserId : window.sessionStorage.UserId,
                        openid: window.sessionStorage.openid,
                        PayNum : PayNum,
                        PayStatus : PayStatus,
                        OrderTypeId : '',
                        OrderId : '',
                        PayMember : _this.type,
                    },
                    success: function (res) {

                    }
                })
            },
            onMoney : function (type,money) {
                var _this = this
                _this.type = type
                _this.money = money
            },
        //    支付
            getCode(){
                var _this = this
                var local = 'https://manager.lvwa18.com/user/recharge'
                var ua = navigator.userAgent.toLowerCase();
                console.log(this.$route.query.code)
                if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                         _this.trade_type = 'JSAPI'
                        if(window.sessionStorage.openid){

                        }else {
                            _this.load = true
                            if(_this.$route.query.code == null || _this.$route.query.code == ''){
                                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx61856270fcce1981&redirect_uri='+
                                    encodeURIComponent(local)+ '&response_type=code&scope=snsapi_base&state=statetwechat_redirect'
                            }else{
                                _this.send({
                                    url: '/getOpenID',
                                    data: {
                                        code : _this.$route.query.code,
                                        appid : 'wx61856270fcce1981',
                                        appsecret : 'c1f80515f1139086dd10c90d3dbc0acb',
                                    },
                                    success: function (res) {
                                        console.log(res)
                                        _this.load = false
                                        window.sessionStorage.openid = res
                                    }
                                })
                            }
                        }
                    }else {
                        _this.trade_type = 'MWEB'
                        // console.log('非微信浏览器')
                    }

                }else{
                    return false; // PC端
                }

            },
        },
        mounted () {
            var _this = this
            this.getCode()
        }
    }
</script>


