<style scoped>
    .serviceRecord{background: #F7F6F9;}
    .tabNameTrue{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 1.04rem;display: inline-block;width:33%;}
    .tabNameFalse{font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #B1B8CC;line-height: 1.04rem;display: inline-block;width: 33%;}
    .tabLine{width: 0.54rem;height: 0.06rem;background: #0C83E4;border-radius: 0.03rem;position: absolute;left: 0.88rem;top:0.78rem;transition: 300ms;-o-transition: 300ms;-moz-transition: 300ms;-webkit-transition: 300ms;}
    .screeningTime{height: 1.07rem;}
    .screeningTimeName{font-size: 0.22rem;color: #333333;line-height: 0.22rem;margin: 0 0.15rem 0 0.35rem;display: inline-block;vertical-align: top;margin-top: 0.45rem}
    .startTime{width: 1.57rem;height: 0.34rem;line-height: 0.34rem;border: 0.01rem solid #D6DDE2;font-size: 0.22rem;color: #333333;text-align: left;padding-left: 0.11rem;display: inline-block;vertical-align: top;margin: 0.38rem 0 0 0}
    .bar{width: 0.12rem;height: 0.02rem;background-color: #333333;display: inline-block;vertical-align: top;margin: 0.55rem 0.1rem 0}
    .newOrder{width: 1.68rem;height: 0.52rem;background: #0C83E4;border-radius: 0.26rem;font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.52rem;display: inline-block;vertical-align: top;margin: 0.3rem 0 0 0.35rem;}
    .addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;font-size:0.3rem;}
    .choice{width:calc(50% - 0.01rem);font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.22rem;text-align: center;display: inline-block;vertical-align: top;height: 0.56rem;}
    .triangle{ width: 0;height: 0;border-left: 0.06rem solid #FFF;border-right: 0.06rem solid #FFF;border-top: 0.11rem solid #666;display: inline-block;margin-left: 0.2rem}
    .list{width: 6.9rem;height: 2.04rem;background-color: #FFF;display: inline-block;text-align: left;margin-top: 0.3rem;border-radius: 0.04rem;}
    .listType{height: 1.08rem;border-bottom: 0.01rem solid #D6DDE2;line-height: 1.09rem;}
    .headPortrait{width: 0.54rem;height: 0.54rem;border-radius: 0.54rem;margin: 0.2rem 0.2rem 0 0.3rem}
    .lawName{font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #6B757D;line-height: 0.94rem;display: inline-block;vertical-align: top;}
    .state{font-size: 0.224rem;font-family: PingFang SC;font-weight: 400;line-height: 0.94rem;display: inline-block;float: right;margin-right: 0.3rem}
    .typeListTrue{width: 1.14rem;height: 0.6rem;background: #0C83E4;border-radius: 0.04rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.6rem;vertical-align: top;margin: 0.3rem 0 0 0.3rem;}
    .typeListFalse{width: 1.14rem;height: 0.6rem;background: #B6BCD1;border-radius: 0.04rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.6rem;vertical-align: top;margin: 0.3rem 0 0 0.3rem;}
    .downloadName{color:#6B757D;font-size: 0.24rem;vertical-align: top;font-weight: bold;}
    .fileImg{width: 0.24rem;height: 0.30rem;vertical-align: top;margin: 0.4rem 0.1rem 0 0.3rem}
    .labelWord{height: 0.42rem;background: #FFFFFF;border: 0.01rem solid #0C83E4;border-radius: 0.04rem;font-size: 0.22rem;color: #0C83E4;line-height: 0.42rem;text-align: center;vertical-align: top;margin: 0.26rem 0 0 0.3rem;display: inline-block;padding: 0 0.1rem}
    .downloadTime{font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #6B757D;float: right;line-height: 0.94rem;margin-right: 0.3rem;}
    .collection{font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #E99538;line-height: 0.94rem;display: inline-block;}
    .stars{width: 0.32rem;height: 0.30rem;vertical-align: top;margin: 0.32rem 0.1rem 0 0.2rem;}
    .dropDown{font-size: 0.1rem;float: right;margin: 0.08rem 0.1rem}
    .screenList{width: 100%;height: 1rem;border-bottom: 0.01rem solid #DEDEE0;font-size: 0.3rem;line-height: 1rem;text-align: center}

    .lawyerHome{background-color: #F7F6F9}
    .dialogueNumber{width: 7.5rem;height: 1.12rem;background: #FEFEFE;line-height: 1.12rem}
    .newOrder{width: 1.68rem;height: 0.52rem;background-color: #0C83E4;border-radius: 0.26rem;text-align: left;font-size: 0.22rem;line-height: 0.52rem;color: #FFF;display: inline-block;float: right;vertical-align: top;margin: 0.3rem 0.3rem 0 0;}
    /*.addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;}*/
    .addImg{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.11rem 0.1rem 0 0.21rem;font-size:0.3rem;}
    .informationList{width: 6.9rem;height: 1.5rem;background-color: #FFF;margin-top: 0.3rem;display: inline-block;text-align: left}
    .tips{width: 0.30rem;height: 0.30rem;background: #EB1818;border-radius: 50%;position: absolute;font-size: 0.24rem;color: #FFF;text-align: center;top: 0.32rem;right: 0}
    .lawName{font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.32rem;vertical-align: top;margin: 0.37rem 0 0.2rem 0rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .content{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .time{font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;float: right;margin: 0.43rem 0.3rem 0 0}

</style>

<template>
    <div class="serviceRecord">
        <div style="background-color: #FFF;border-radius: 0px 0px 0.2rem 0.2rem;" :style="{height:(type==4?'':'2.68rem')}">
            <div style="height: 1.04rem;position: relative;border-bottom: 0.01rem solid #D6DDE2" class="font-size center">
                <div class="tabLine" :style="{left:(type*(7.5/3)-(0.54/2))-(7.5/3/2)+'rem'}"></div>
                <div :class="type == 1?'tabNameTrue': 'tabNameFalse'" @click="tabSwitch(1)">我的订单</div>
                <div :class="type == 2?'tabNameTrue': 'tabNameFalse'" @click="tabSwitch(2)">文件下载记录</div>
                <div :class="type == 3?'tabNameTrue': 'tabNameFalse'" @click="tabSwitch(3)">收藏的文件</div>
                <!--<div :class="type == 4?'tabNameTrue': 'tabNameFalse'" @click="tabSwitch(4)">法律咨询</div>-->
            </div>
            <!--我的订单-->
            <div v-if="type == 1">
                <div class="screeningTime">
                    <div class="screeningTimeName">筛选时间</div>
                    <div class="startTime" @click="onTimeShow(1)">{{StartTime||'请选择'}}<van-icon name="arrow-down" class="dropDown"/></div>
                    <div class="bar"></div>
                    <div class="startTime" @click="onTimeShow(2)">{{EndTime||'请选择'}}<van-icon name="arrow-down" class="dropDown"/></div>
                    <!--<router-link :to="{name:'newOrder'}"><div class="newOrder">-->
                        <!--<van-icon name="add-o" class="addImg"/>-->
                        <!--<div class="inline-block">新建订单</div>-->
                    <!--</div></router-link>-->
                </div>
                <div class="font-size">
                    <div class="choice" @click="payment = true">{{stateWord}}<div class="triangle"></div></div>
                    <div style="width: 0.02rem;height: 0.3rem;background: #D6DDE2;margin-top: -0.03rem;" class="inline-block"></div>
                    <div class="choice" @click="screenType = true">{{OrderContent}}<div class="triangle"></div></div>
                </div>
            </div>
            <div v-if="type == 2 || type == 3">
                <div :class="[groceryType == value.text?'typeListTrue':'typeListFalse']"
                     v-for="(value,index) in groceryList" @click="screenData(index)">{{value.text}}</div>
            </div>
        </div>
        <div class="center font-size" v-if="type == 1">
            <div class="list" v-for="(value,index) in orderList" @click="onRecord(value.OrderId)">
                <div class="listType">
                    <div style="color:#6B757D;font-size: 0.24rem;vertical-align: top;margin-left: 0.3rem" class="inline-block">{{value.OrderName}}<span v-if="value.IsUrgentName" style="color: red">({{value.IsUrgentName}})</span></div>
                    <div style="color:#373C40;font-size: 0.3rem;float: right;margin-right: 0.3rem" class="inline-block">{{value.OrderCreatedTime}}</div>
                </div>
                <div style="height: 0.94rem;vertical-align: top;">
                    <img :src="value.LawyerImg || '/img/enterprise.png'" class="headPortrait">
                    <div class="lawName">{{value.LawyerName}}</div>
                    <div class="state" style="color: #019C93;" v-if="value.PayStatus == 'SUCCESS' && value.OrderEndTime != null">已完成</div>
                    <div class="state" style="color: #D01616;" v-if="value.PayStatus == 'SUCCESS' && value.OrderEndTime == null">处理中</div>
                    <div class="state" style="color: #0C83E4;" v-if="value.PayStatus == 'FAIL'">取消支付</div>
                    <div class="state" style="color: #0C83E4;" >{{value.PayStatus_}}</div>
                </div>
            </div>
        </div>
        <div class="center font-size"  v-if="type == 2 || type == 3">
            <!--<div class="downloadList">-->

            <!--</div>-->
            <div class="list" v-for="(value,index) in fileList" @click="onTemplate(value.id,value.type_,value.type)">
                <div class="listType">
                    <img class="fileImg" src="/img/wenjian1.png">
                    <div class="inline-block downloadName">{{value.name}}</div>
                </div>
                <div style="height: 0.94rem;vertical-align: top;">
                    <div class="labelWord">{{value.type_}}</div>
                    <div class="collection" v-if="type == 3" @click.stop="collectDTemplate(value.id,value.type_,value.type,index)">
                        <img class="stars" src="/img/shoucang0.png">
                        <div class="inline-block">取消收藏</div>
                    </div>
                    <div class="downloadTime">{{value.DloadTime}}</div>
                </div>
            </div>
        </div>

        <!--<div class="center font-size"  v-if="type == 4">-->
            <!--<div class="informationList" v-for="(value,index) in dialogueList" @click="onDialogue(value.ContactId,value.UserId)">-->
                <!--<img v-if="value.LawyerImg" :src="value.LawyerImg" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem;border-radius: 0.9rem">-->
                <!--<img v-else="" src="/img/enterprise.png" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem">-->
                <!--&lt;!&ndash;<div class="tips">3</div>&ndash;&gt;-->
                <!--<div class="inline-block" style="max-width: 3.08rem;margin-left: 0.3rem;vertical-align: top">-->
                    <!--<div class="lawName">{{value.LawyerName}}</div>-->
                    <!--<div class="content" v-if="value.Content">{{value.lastContent.content}}</div>-->
                <!--</div>-->
                <!--<div class="inline-block time">{{value.OrderCreatedTime}}</div>-->
            <!--</div>-->
        <!--</div>-->
        <!--筛选时间-->
        <van-popup v-model="timeShow" position="bottom">
            <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="determineTime"
                    cancel-button-text="取消"
                    @cancel="empty"
            />
        </van-popup>
        <!--付款-->
        <van-popup v-model="payment" position="bottom" close-icon="close" closeable>
            <div style="padding: 0.3rem"></div>
            <div class="screenList" @click="onState(2,'已完成')">已完成</div>
            <div class="screenList" @click="onState(1,'处理中')">处理中</div>
        </van-popup>
        <!--类型-->
        <van-popup v-model="screenType" position="bottom" close-icon="close" closeable>
            <div style="padding: 0.3rem"></div>
            <div class="screenList" v-for="(value,index) in categoryList" @click="onScreenType(value.OrderTypeId,value.OrderName)" v-if="value.OrderTypeId != '1'">{{value.OrderName}}</div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "serviceRecord",
        data() {
            return {
                type : '1',
                timeShow : false,
                payment : false,
                screenType : false,
                minDate: new Date(2021, 0, 1),
                maxDate: new Date(2099, 10, 1),
                currentDate: new Date(),
                sign : '',
                fileList : [],
                groceryList: [//选着类型
                    {id: 4, text: '常用合同', type: 'changyong'},
                    {id: 0, text: '起诉', type: 'qisu'},
                    {id: 1, text: '上诉', type: 'shangsu'},
                    {id: 2, text: '申请', type: 'shenqin'},
                    {id: 3, text: '仲裁', type: 'zhongcai'},
                ],
                groceryType : '',
                page : 1,
                pageSize : 999,
                temp : '1',
                orderList : [],
                OrderTypeId : '',
                OrderStatus : '',
                StartTime : '',
                EndTime : '',
                stateWord : '状态',
                categoryList : [],
                dialogueList : [],
                OrderContent : '服务类型',
            };
        },
        methods :{
            onDialogue : function(ContactId,UserId){
                var _this = this
                _this.$router.push({name: 'dialogue', query: {ContactId:ContactId}})
            },
            onTemplate : function (id,type_,type) {
                var _this = this
                _this.$router.push({name: 'templateDetails', query: {id: id,type:type_,_type:type}})
            },
            collectDTemplate : function (id,type_,type,index) {
                var _this = this
                _this.send({
                    url: '/CollectDTemplate',
                    consulting : true,
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        id: id,
                        UserId : window.sessionStorage.UserId,
                        type : type_,
                        EType  :type,
                    },
                    success: function (res) {
                        _this.$toast.success('取消收藏');
                        delete _this.fileList.splice(index, 1)
                    }
                })
            },
            onRecord : function(OrderId){
                var _this = this
                _this.$router.push({name: 'caseDetails', query: {OrderId:OrderId}})
            },
            onScreenType : function(OrderTypeId,OrderContent){
                this.OrderTypeId = OrderTypeId
                this.OrderContent = OrderContent
                this.screenType = false
                this.lawyerOrderList()
            },
            onState : function(OrderStatus,stateWord){
                this.OrderStatus = OrderStatus
                this.stateWord = stateWord
                this.payment = false
                this.lawyerOrderList()
            },
            lawyerOrderList : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/UserOrderRow',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin||'',
                        UserToken : window.sessionStorage.UserToken||'',
                        IsUser  : window.sessionStorage.IsUser||'',
                        UserId : window.sessionStorage.UserId||'',
                        OrderTypeId : _this.OrderTypeId,
                        OrderStatus : _this.OrderStatus,
                        StartTime : _this.StartTime,
                        EndTime : _this.EndTime,
                        page : _this.page,
                        pageSize : _this.pageSize,
                    },
                    success: function (res) {
                        if(res){
                            _this.orderList = res.row
                        }else {

                        }

                    }
                })
            },
            //筛选
            screenData : function(index){
                var _this = this
                _this.groceryType = _this.groceryList[index].text
                console.log(_this.groceryType)
                _this.tabSwitch(_this.type)
            },
            tabSwitch : function (type) {
                var _this = this
                _this.type = type
                _this.fileList = []
                if(type == '1'){
                    _this.OrderTypeId = ''
                    _this.OrderStatus = ''
                    _this.page = 1
                    _this.lawyerOrderList()
                }
                if(type == '3' || type == '2'){
                    _this.send({
                        url: '/TemplateRecord',
                        data: {
                            UserLogin : window.sessionStorage.UserLogin,
                            UserToken : window.sessionStorage.UserToken,
                            IsUser  : window.sessionStorage.IsUser,
                            UserId : window.sessionStorage.UserId,
                            RecordType : type-1,
                            Type : _this.groceryType
                        },
                        success: function (res) {
                            _this.fileList = res.data
                        }
                    })
                }if(type == '4'){
                    _this.send({
                        url: '/HistoryContactList',
                        data: {
                            UserLogin : window.sessionStorage.UserLogin,
                            UserToken : window.sessionStorage.UserToken,
                            IsUser  : window.sessionStorage.IsUser,
                            UserId : window.sessionStorage.UserId,
                            LawyerId : window.sessionStorage.User_LawyerId,
                            page : _this.page,
                            pageSize : _this.pageSize,
                        },
                        success: function (res) {
                            console.log(res)
                            _this.dialogueList = res.List
                        }
                    })
                }
            },
            empty : function () {
                var _this =this
                if(_this.sign == 1){
                    _this.StartTime = ''
                }else {
                    _this.EndTime = ''
                }
                _this.timeShow = false
            },
            determineTime : function (value) {
                var _this =this
                var year = value.getFullYear();
                var month = value.getMonth() + 1;
                var day = value.getDate();
                //开始1 结束2
                if(_this.sign == 1){
                    _this.StartTime = year+'-'+month+'-'+day
                }else {
                    _this.EndTime = year+'-'+month+'-'+day
                }
                if(_this.StartTime != '' && _this.EndTime != ''){
                    _this.lawyerOrderList()
                }
                _this.timeShow = false
               console.log(year,month,day)
            },
            onTimeShow : function (sign) {
                var _this = this
                _this.sign = sign
                _this.timeShow = !_this.timeShow
            },
            orderType : function () {
                var _this = this
                _this.send({
                    url: '/OrderType',
                    data: {},
                    success: function (res) {
                        _this.categoryList = res
                        console.log(res)
                    }
                })
            },
        },
        mounted  () {
            this.lawyerOrderList()
            this.orderType()
        }
    }
</script>

