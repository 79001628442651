import home from '../../views/enterprise/home.vue'
import dialogueList from '../../views/enterprise/dialogueList.vue'
import template from '../../views/enterprise/template.vue'
import templateDetails from '../../views/enterprise/templateDetails.vue'
import serviceRecord from '../../views/enterprise/serviceRecord.vue'
import newOrder from '../../views/enterprise/newOrder.vue'
import my from '../../views/enterprise/my.vue'
import record from '../../views/enterprise/record.vue'
import recharge from '../../views/enterprise/recharge.vue'
import dialogue from '../../views/enterprise/dialogue.vue'
import userInformation from '../../views/enterprise/userInformation.vue'
import caseBase from '../../views/enterprise/caseBase.vue'
import caseBaseDetails from '../../views/enterprise/caseBaseDetails.vue'
import opinion from '../../views/enterprise/opinion.vue'
import agreement from '../../views/enterprise/agreement.vue'
import userNavigation from '../../views/navigation/userNavigation.vue'
import usereEmpty from '../../views/navigation/usereEmpty.vue'
import service from '../../views/enterprise/service.vue'
import voice from '../../views/enterprise/voice.vue'

export default {
    home:home,
    dialogueList:dialogueList,
    template:template,
    templateDetails:templateDetails,
    serviceRecord:serviceRecord,
    newOrder:newOrder,
    my:my,
    record:record,
    recharge:recharge,
    dialogue:dialogue,
    userInformation:userInformation,
    caseBase:caseBase,
    caseBaseDetails:caseBaseDetails,
    opinion:opinion,
    agreement:agreement,
    userNavigation:userNavigation,
    usereEmpty:usereEmpty,
    service:service,
    voice:voice,
}
