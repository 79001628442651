<template>
  <div class="service" style="text-align: center">
    <div style="font-size: 0.26rem;background-color: #FFF;position: fixed;height: 100%;width: 100%"  v-if="notYet">
      <img src="/img/defaultgraph.png">
      <div style="margin-top: 0.3rem;font-size: 0.36rem;color: #cccccc">暂无咨询</div>
    </div>
    <div class="informationList" v-for="(value,index) in dialogueList" @click="onDialogue(value.ContactId,value.UserId)">
      <img v-if="value.LawyerImg" :src="value.LawyerImg" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem;border-radius: 0.9rem">
      <img v-else="" src="/img/enterprise.png" style="width: 0.9rem;height: 0.9rem;margin: 0.3rem 0 0 0.3rem">
      <!--<div class="tips">3</div>-->
      <div class="inline-block" style="max-width: 2.38rem;margin-left: 0.3rem;vertical-align: top">
        <div class="lawName">{{value.LawyerName}}</div>
        <div class="content" v-if="value.lastContent">
          <span v-if="value.lastContent.type == 'word'">{{value.lastContent.content}}</span>
          <span v-if="value.lastContent.type == 'jpg'">[图片]</span>
          <span v-if="value.lastContent.type == 'file'">[文件]</span>
        </div>
      </div>
      <div class="inline-block time" v-if="value.lastContent">{{value.lastContent.sendTime}}</div>
    </div>
    <img src="/img/nothing.png" v-if="noOrder" style="width: 7.26rem;height: 3.98rem">
  </div>
</template>

<script>
export default {
  name: "service",
  data() {
    return {
      dialogueList : [],
      page : 1,
      pageSize : 999,
      notYet : false,
      temp : '2',
      LawyerId : '',
      UserId : '',
      UserToken : '',
      IsUser : '',
      UserLogin : '',
      noOrder : false
    }
  },
  methods : {
    onDialogue: function (ContactId, UserId) {
      var _this = this
      _this.$router.push({name: 'dialogue', query: {ContactId: ContactId}})
    },
    historyContactList : function () {
      var _this = this
      _this.send({
        consulting : true,
        url: '/LawyerOrderList',
        data: {
          UserLogin : _this.UserLogin,
          UserToken : _this.UserToken,
          IsUser  : _this.IsUser,
          UserId : _this.UserId,
          LawyerId : _this.LawyerId,
          page : _this.page,
          pageSize : _this.pageSize,
          temp : _this.temp
        },
        success: function (res) {
          if(res){
            _this.dialogueList = res.data
            _this.Count = res.count
          }else{
            if(_this.dialogueList == ''){
              _this.noOrder = true
            }
          }
        }
      })
    }
  },
  mounted  () {
    var _this = this
    _this.userData = window.sessionStorage
    _this.LawyerId = window.sessionStorage.LawyerId
    _this.IsUser = window.sessionStorage.IsUser
    _this.UserToken = window.sessionStorage.UserToken
    _this.UserLogin = window.sessionStorage.UserLogin
    _this.historyContactList()
  }
}
</script>

<style scoped>
.informationList{width: 6.9rem;height: 1.5rem;background-color: #FFF;margin-top: 0.3rem;display: inline-block;text-align: left}
.lawName{font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.32rem;vertical-align: top;margin: 0.37rem 0 0.2rem 0rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.content{font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.time{font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.32rem;vertical-align: top;float: right;margin: 0.43rem 0.3rem 0 0}

</style>
