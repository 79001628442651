<template>
    <div style="font-family: PingFang SC;background-color: #FFF" >
        <div v-if="admin_id == 1" style="background: url(/img/legalAppointment/banner1.png);background-size: 100% 100%;width: 7.5rem;height: 3.6rem;text-align: center;font-family: PingFang SC;position: relative">

        </div>
      <img v-if="admin_id == 2" style="width: 7.5rem;height: 3.6rem" src="/img/fztj.jpg">
        <div style="font-size: 0.22rem;padding: 0.2rem 0.3rem 0">
            <div style="font-weight: bold">温馨提示：</div>
            <div>1、企业预约信息提交后，自动匹配律所为你提供法治体检服务。</div>
            2、企业可登录 <router-link :to="{name: 'home'}" style="color: red">>>企业管理后台>></router-link>查看预约进度。用户名为预约:<span style="color: red">手机号</span>，密码默认:<span style="color: red">123456</span>
        </div>
        <div style="margin-left: 0.57rem;font-size: 0;padding-bottom: 0.3rem">
            <div style="font-size: 0.28rem;color: #333;margin: 0.29rem 0 0.21rem 0;font-weight: bold;line-height: 0.28rem">填写企业信息</div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;">
                <div style="width: 1.4rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;"><span style="color: red">*</span>企业名称</div>
                <input class="inputInformation" placeholder="请输入企业名称" v-model="CompanyName"/>
            </div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;">
                <div style="width: 1.4rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;"><span style="color: red">*</span>企业地址</div>
                <input class="inputInformation" placeholder="请输入企业地址" v-model="Address"/>
            </div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;">
                <div style="width: 1.4rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;"><span style="color: red">*</span>联系人</div>
                <input class="inputInformation" placeholder="请输入联系人" v-model="UserName"/>
            </div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;">
                <div style="width: 1.4rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;"><span style="color: red">*</span>手机号</div>
                <input class="inputInformation" placeholder="请输入手机号" v-model="UserTel"/>
            </div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;">
                <div style="width: 1.4rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;"><span style="color: red">*</span>验证码</div>
                <input class="inputInformation" placeholder="请输入验证码" v-model="Code"/>
                <div class="obtainCode" @click="getCode" v-if="messageTimer.fun==null">获取验证码</div>
                <div class="obtainCode" v-else>{{messageTimer.count+"s后重新获取"}}</div>
            </div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;" @click="show = true">
                <div style="width: 2rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;"><span style="color: red">*</span>体检类别</div>
                <input readonly="readonly"  class="inputInformation" placeholder="请选择体检类别" v-model="typeName" />
            </div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;">
                <div style="width: 2.4rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;" >情况说明（选填）</div>
                <textarea style="font-size: 0.28rem;color: #666;margin-top: 0.42rem;vertical-align: top;height: 2.5rem;width: 3.8rem;margin-bottom:0.1rem;" placeholder="请输入情况说明（选填）" v-model="context"></textarea>
            </div>
            <div style="position: relative;width: 6.4rem;border-bottom: #DDDDDD 0.01rem solid;">
                <div style="width: 2rem;line-height: 1.19rem;font-size: 0.28rem;color: #373C40;display: inline-block;"><span style="color: red">*</span><span style="">默认登录密码</span></div>
                <input class="inputInformation" placeholder="请输入密码" type="" v-model="PassWord"/>
            </div>
            <!--<div style="font-size: 0.26rem;color: #1186e1;margin-top: 0.1rem">默认登录密码：123456</div>-->
            <div @click="registerUser" style="width: 6.4rem;height: 0.86rem;background: #1186E1;border-radius: 0.04rem;font-size: 0.28rem;font-weight: bold;color: #FFFFFF;text-align: center;
            line-height: 0.86rem;margin-top: 0.4rem;margin-bottom: 0.3rem">提交预约</div>
        </div>
        <!-- <div style="text-align: center">
            <div style="font-size: 0.26rem;color: #999;line-height: 0.26rem;margin-bottom: 0.3rem;">成都市武侯区司法局 监制</div>
            <router-link :to="{name: 'home'}" style="font-size: 0.26rem;color: rgb(32 137 254);line-height: 0.26rem;vertical-align: top;text-decoration: underline">进入>> 律蛙法管家</router-link>
        </div> -->

        <van-popup v-model="show" position="bottom">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm"/>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "basicInformation",
        data() {
            return {
                availHeight : '',
                messageTimer: {
                    fun: null,
                    count: '',
                },
                UserTel : '',
                PassWord : '123456',
                CompanyName : '',
                Address : '',
                context : '',
                UserName : '',
                Code : '',
                IsUser : '2',
                columns : [],
                show : false,
                typeName : '',
                typeId  : '',
              admin_id  : '',
            }
        },
        methods :{
            onConfirm : function(e){
                console.log(e)
                this.typeId  = e.typeId
                this.typeName  = e.typeName
                this.show = false
            },
            registerUser : function(){
                var _this = this
                // if(_this.CompanyName != ''){
                //
                // }else{
                //
                // }
                _this.send({
                    url : '/RegisterUser',
                    data: {UserTel : _this.UserTel,PassWord:_this.PassWord,Code:_this.Code,type:1,CompanyName:_this.CompanyName,
                        Address:_this.Address,UserName:_this.UserName,context:_this.context,typeId:_this.typeId,LawyerId:_this.$route.query.LawyerId},
                    success: function (res) {
                        _this.login()
                        _this.$toast.success('提交成功');
                    }
                })
            },
            login : function () {
                var _this = this
                _this.send({
                    url : '/Login',
                    data: {UserTel : _this.UserTel,PassWord:_this.PassWord,IsUser:_this.IsUser},
                    success: function (res) {
                        // _this.login()
                        window.sessionStorage.UserId = res.UserId
                        window.sessionStorage.UserToken = res.UserToken
                        SYSCONFIG.server.default.data.UserId   = res.UserId
                        SYSCONFIG.server.default.data.UserToken   = res.UserToken
                        _this.setCookie(res.UserId,res.UserToken,_this.UserTel,_this.PassWord,res.UserLogin)
                        _this.send({
                            url : '/bindingUser',
                            data: {streetId :_this.$route.query.streetId,UserId:res.UserId},
                            success: function (res) {
                                _this.$router.push({name:'home'});
                            }
                        })
                    }
                })
            },
            setCookie(UserId,UserToken,UserTel,PassWord,UserLogin) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 7); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "UserId" + "=" + UserId + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserToken" + "=" + UserToken + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserTel" + "=" + UserTel + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "PassWord" + "=" + PassWord + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "UserLogin" + "=" + UserLogin + ";path=/;expires=" + exdate.toGMTString();
            },
            getCode : function(){
                var _this = this
                _this.send({
                    url : '/GetCode',
                    data: {Tel : _this.UserTel},
                    success: function (res) {
                        _this.setReciprocal()
                    }
                })
            },
            setReciprocal: function () {
                var _this = this
                var TIME_COUNT = 60
                if (!_this.messageTimer.fun) {
                    _this.messageTimer.count = TIME_COUNT
                    _this.messageTimer.fun = setInterval(function () {
                        if (_this.messageTimer.count > 0 && _this.messageTimer.count <= TIME_COUNT) {
                            _this.messageTimer.count--
                        } else {
                            clearInterval(_this.messageTimer.fun)
                            _this.messageTimer.fun = null;
                        }
                    }, 1000)
                }
            },
            typeList  : function(){
                var _this = this
                _this.send({
                    url: '/typeList',
                    data: {},
                    success: function (res) {
                        for (var i in res){
                            res[i].text = res[i].typeName
                        }
                        _this.columns = res
                    }
                })
            },
        },
        mounted () {
            this.availHeight = window.screen.availHeight
          this.admin_id = window.screen.admin_id
            this.typeList()
        }
    }
</script>

<style scoped>
.inputInformation{height: 1.09rem;font-size: 0.28rem;color: #666}
.obtainCode{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #0A76CA;line-height: 0.32rem;position: absolute;right: 0;top:0.42rem;}

</style>
