<style scoped>
    .tips{font-size: 0.36rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.36rem;margin: 0.87rem 0 0 0.55rem;display: inline-block;}
    .signinFillIn{width: 6.4rem;height: 1.1rem;border-bottom: 0.01rem solid #DEDEE0;position: relative;}
    .title{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 1.1rem;display: inline-block;vertical-align: top;position: absolute;left: 0;}
    .input{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 1.36rem;width:calc(100% - 1.16rem);position: absolute;left: 0}
    .obtainCode{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #0A76CA;line-height: 0.32rem;position: absolute;right: 0;top:0.42rem;}
    .signin{width: 6.40rem;height: 0.90rem;background: #0A76CA;border-radius: 0.04rem;font-size: 0.30rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.9rem;display: inline-block;margin: 0.4rem 0 0.75rem;}
    .headPortrait{width: 1.08rem;height: 1.08rem;vertical-align: top;margin: 0.556rem 0.15rem 0 0.48rem;border-radius: 1.08rem}
    .enterprise{width: 1.08rem;height: 1.08rem;border-radius: 1.08rem;background-color: #FFF;text-align: center;display: inline-block;margin:0.4rem 0.15rem 0 0.4rem;}

</style>

<template>
    <div class="userInformation">
        <div>
            <!--<div class="tips">用户信息</div>-->
        </div>
        <div style="margin: 0.2rem 0.55rem 0">
            <div style="text-align: center">
                <van-uploader :after-read="afterRead" accept="" delete="deletefile" style="text-align: center" class="inline-block">
                    <img class="headPortrait" :src="PhotoPath" v-if="PhotoPath">
                    <img class="enterprise" src="/img/enterprise.png" v-else>
                </van-uploader>
            </div>
            <div class="signinFillIn">
            <div class="title">姓名</div>
            <input class="input" placeholder="姓名" v-model="UserName"/>
            </div>
            <div class="signinFillIn">
            <div class="title">公司名称</div>
            <input class="input" placeholder="公司名称" v-model="CompanyName"/>
            </div>
            <div class="signinFillIn">
                <div class="title">公司简介</div>
                <input class="input" placeholder="公司简介" v-model="Introduce"/>
            </div>
        </div>
        <div class="center font-size">
            <div class="signin" @click="userUpdate">保存</div>

        </div>
        <notLoggedIn ref="notLoggedIn" />
    </div>
</template>
<script>
    import notLoggedIn from '../../components/notLoggedIn.vue'
    import axios from 'axios'
    export default {
        name: "userInformation",
        components: {notLoggedIn},
        data() {
            return {
                UserName : '',
                PhotoPath : '',
                CompanyName : '',
                Introduce : '',
            };
        },
        methods :{
            userUpdate : function(){
                var _this = this
                _this.send({
                    url: '/UserUpdate',
                    data: {
                        UserLogin : window.sessionStorage.UserLogin,
                        UserToken : window.sessionStorage.UserToken,
                        IsUser  : window.sessionStorage.IsUser,
                        UserId : window.sessionStorage.UserId,
                        UserName : _this.UserName,
                        PhotoPath : _this.PhotoPath,
                        CompanyName : _this.CompanyName,
                        Introduce : _this.Introduce,
                    },
                    success: function (res) {
                        window.sessionStorage.UserName =  _this.UserName
                        window.sessionStorage.UserImg =  _this.PhotoPath
                        window.sessionStorage.UserImg =  _this.PhotoPath
                        window.sessionStorage.CompanyName =  _this.CompanyName
                        window.sessionStorage.Introduce =  _this.Introduce
                        _this.$toast.success('修改成功');
                        _this.$router.go(-1)
                    }
                })
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                var _this = this
                var param = new FormData(); //创建form对象
                param.append('file', file.file);//通过append向form对象添加数据
                // param.append('MenuId', _this.menuId);
                var config = {}
                axios.post('https://manager.lvwa18.com:8002s://manager.lvwa18.com:8002/upload_file_H5', param, config)
                    .then(response => {
                        _this.PhotoPath = response.data.Data.path
                        console.log(_this.PhotoPath)
                    })
            },
        },
        mounted () {
            var _this = this
            _this.UserName  = window.sessionStorage.UserName
            _this.PhotoPath = window.sessionStorage.UserImg
            _this.CompanyName = window.sessionStorage.CompanyName
            _this.Introduce = window.sessionStorage.Introduce
            console.log(_this.PhotoPath)
        },
    }
</script>

